import styled from "styled-components";
import { media } from "styles/variables";

export const ImageButtonsWrapper = styled.div`
  position: absolute;
  left: -15vw;
  right: -15vw;
  top: 50%;
  height: 50px;
  transform: translateY(-50%);
  z-index: 3;
  justify-content: space-between;
  align-items: center;
  display: flex;

  .right,
  .left {
    position: relative;
    display: flex;
  }

  .right-line,
  .left-line {
    position: relative;
  }

  .right-line::after,
  .left-line::after {
    display: none;
    position: absolute;
    content: "";
    width: 100vw;
    height: 1px;
    background: #ffffff1a;
    top: 26px;
  }

  .right-line::after {
    left: 60px;
  }

  .left-line::after {
    right: 120px;
  }

  ${media.greaterThan("xmd")`
    .right-line::after, .left-line::after {
      display: block;
    }
  `}
`;
