import styled from "styled-components";
import { colors } from "styles/variables";
import * as Constants from "./ArtisticInnovationsDesktop.constants";

export const initialVidWidth = 10.9;

export const vidBorderRadius = 55;

const headingFontHeightVw = 8;
const headingGapVw = 1;

export const baseHeadingWrapperTranslateX = -0.5;

export const ArtisticInnovationsDesktop = styled.div<{
  $headingOffsetTop: number;
}>`
  overflow: clip;
  position: relative;
  height: ${Constants.TOTAL_HEIGHT}vh;

  // The section before controls the spacing between the sections.
  margin-top: ${(props) => -props.$headingOffsetTop}px;

  .video {
    width: ${initialVidWidth}vw;
    z-index: 2;
    border-radius: ${vidBorderRadius}px;
    will-change: transform;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0%;
  left: 0%;
  position: sticky;
  height: ${Constants.WRAPPER_HEIGHT}vh;
`;

export const HeadingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  white-space: nowrap;
  gap: ${headingGapVw}vw;
  z-index: 10;
  will-change: transform, opacity;
  transform: translateX(${baseHeadingWrapperTranslateX}vw);

  span {
    line-height: 1;
    font-size: ${headingFontHeightVw}vw;
  }
`;

export const HeadingTop = styled.div`
  display: flex;
  gap: 1.618vw;
  align-items: center;
  transform: translateX(5.5vw);
`;

export const HeadingBottom = styled.div`
  display: flex;
  gap: 1.618vw;
  align-items: center;
  transform: translateX(-5vw);
`;

export const HeadingBottomInner = styled.div`
  display: flex;
  gap: 1.618vw;
`;

export const FullScreenWrapper = styled.div`
  position: absolute;
  top: ${Constants.WRAPPER_HEIGHT + Constants.FULL_SCREEN_HEIGHT_TOTAL}vh;
  height: ${Constants.FULL_SCREEN_HEIGHT}vh;
  left: 0;
  right: 0;
  bottom: 0;
`;

export const SmallText = styled.div`
  font-size: 18px;
  font-weight: 400;
  color: ${colors.alto2};
  line-height: 25px;
  max-width: 560px;
  margin-top: 2vw;
  white-space: pre-line;
  will-change: transform;
`;
