import { motion } from "framer-motion";
import styled, { css } from "styled-components";
import { border, colors, media } from "styles/variables";

export const Wrapper = styled(motion.div)`
  width: 100%;
  height: 100%;
  position: relative;
  pointer-events: all;
`;

export const CaseStudyBody = styled(motion.div)`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;

  ${media.greaterThan("md")`
    flex-direction: row-reverse;
  `}
`;

export const InfoWrapper = styled(motion.div)`
  border-top: ${border};
  border-bottom: ${border};

  ${media.greaterThan("md")`
    border: none;
    flex: 1;
  `}
`;

export const InfoContent = styled(motion.div)`
  margin: 0 25px;
  border-left: ${border};
  border-right: ${border};

  min-height: 264px;

  height: 100%;
  position: relative;

  ${media.greaterThan("md")`
    margin: 0;
  `}
`;

export const InfoBody = styled(motion.div)`
  display: flex;
  flex-direction: column;
  padding: 36px 23px 62px;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  ${media.greaterThan("md")`
    justify-content: flex-start;
    align-items: flex-start;
    padding: 100px 40px 0 100px;
  `}

  ${media.greaterThan("lg")`
    padding: 100px 0 0 153px;
  `}
`;

export const InfoTitle = styled(motion(motion.div))`
  color: ${colors.white};
  font-weight: 600;
  font-size: 20px;
  line-height: 1.2;
  text-align: center;
  max-width: 235px;
  margin: 22px 0 14px;
  min-height: 48px;

  ${media.greaterThan("md")`
    margin: 40px 0 25px;
    font-weight: 500;
    font-size: 35px;
    text-align: left;
    max-width: 400px;
  `}
`;

export const InfoDescription = styled(motion.div)`
  color: ${colors.silver};
  font-weight: 300;
  font-size: 14px;
  line-height: 20px;
  text-align: center;

  ${media.greaterThan("md")`
    font-size: 15px;
    text-align: left;
    max-width: 450px;
    font-weight: 400;
  `}
`;

export const ImageWrapper = styled(motion.div)<{ $css?: string }>`
  display: flex;
  height: 494px;
  width: 100%;
  overflow: hidden;

  ${media.greaterThan("md")`
    height: 800px;
    flex: 1;
  `}

  ${({ $css }) =>
    css`
      ${$css}
    `}
`;

export const Image = styled.img<{ $css?: string }>`
  width: 100%;
  height: 100%;
  object-fit: cover;
  ${({ $css }) =>
    css`
      ${$css}
    `}
`;

export const IconWrapper = styled(motion.div)`
  ${media.greaterThan("md")`
    transform: scale(1.5);
    height: 62px;
  `}
`;

export const DesktopButtonWrapper = styled(motion.div)`
  display: none;

  ${media.greaterThan("md")`
    display: flex;
    margin-top: 63px;
  `}
`;

export const SwiperMenu = styled(motion.div)`
  display: none;

  ${media.greaterThan("md")`
    height: 222px;
    width: 100%;
    border-top: ${border};
    position: absolute;
    bottom: 0;
    left: 0;
    display: flex;
    justify: center;
    align-items: center;
  `}
`;

export const SwiperMenuBody = styled(motion.div)`
  width: 100%;
  margin: 0 50px 0 100px;
  display: flex;
  justify-content: space-between;

  ${media.greaterThan("lg")`
    margin: 0 147px 0 153px;
  `}
`;

export const SwiperMenuCounterWrapper = styled(motion.div)`
  display: flex;
  align-items: center;
`;

export const Separator = styled(motion.div)`
  margin: 0 26px;
`;

export const Counter = styled(motion.div)`
  width: 20px;
  max-width: 20px;
`;

export const SwiperMenuButtonsWrapper = styled(motion.div)`
  display: flex;
  gap: 12px;
`;
