import { forwardRef } from "react";

import * as Styled from "./Video.styles";
import { HTMLMotionProps } from "framer-motion";
import { useScreenContext } from "context/ScreenContext";
import { useLoadingContext } from "context/LoadingContext";
import { handleVideoRef } from "./Video.utils";

type VideoProps = {
  src: string;
  mobileSrc: string;
  className?: string;
  hasContainer?: boolean;
} & HTMLMotionProps<"video">;

const baseVideoProps = {
  autoPlay: true,
  loop: true,
  muted: true,
  playsInline: true,
  crossOrigin: "" as const,
  preload: "auto",
};

const Video = forwardRef<HTMLVideoElement, VideoProps>(
  ({ src, mobileSrc, className, hasContainer, ...props }, ref) => {
    const { isMobile } = useScreenContext();

    const { registerVideo } = useLoadingContext();


    return hasContainer ? (
      <Styled.Container className={className}>
        <Styled.Video
          {...baseVideoProps}
          {...props}
          ref={(_ref) => handleVideoRef(ref, _ref, registerVideo)}
        >
          <source src={isMobile ? mobileSrc : src} type="video/mp4" />
        </Styled.Video>
      </Styled.Container>
    ) : (
      <Styled.Video
        {...baseVideoProps}
        ref={(_ref) => handleVideoRef(ref, _ref, registerVideo)}
        className={className}
        {...props}
      >
        <source src={isMobile ? mobileSrc : src} type="video/mp4" />
      </Styled.Video>
    );
  }
);

export default Video;
