import styled from "styled-components";
import { media, colors } from "styles/variables";
import { rgba } from "polished";

export const Container = styled.ul`
  position: absolute;
  top: 50vh;
  transform: translateY(-50%);
  left: 50px;
  z-index: 10;
  pointer-events: all;

  ${media.lessThan("sm")`
    display: none;
  `}

  ${media.greaterThan("md")`
    // Show social icons on top of index+ overlay
    z-index: 21;
  `}
`;

export const LinkIcon = styled.a`
  border-radius: 50%;
  background-color: ${rgba(colors.mineShaft, 0.55)};
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  width: 50px;
  transition: background-color 0.2s ease-in-out;

  &:hover {
    background-color: ${colors.hitPink};

    svg {
      path {
        fill: ${colors.black};
      }
    }
  }
`;

export const Item = styled.li`
  position: relative;
  height: 50px;
  width: 50px;
  margin-bottom: -10px;
  z-index: 1;

  &:hover {
    z-index: 2;
  }
`;
