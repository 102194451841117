import { useLenis } from "lenis/dist/lenis-react";
import { useLocation, useNavigate } from "react-router-dom";

export type Hash = {
  href: string;
  hash: string;
  isSmooth?: boolean;
  block?: ScrollLogicalPosition;
};

export const useHashLink = (sideEffect?: () => void) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const lenis = useLenis();

  const onClickHashLink = ({ href, hash, block, isSmooth = true }: Hash) => {
    sideEffect?.();

    if (href === pathname) {
      const element = document.querySelector("#" + hash);
      if (element) {
        lenis?.stop();
        element.scrollIntoView({
          block: block || "center",
          behavior: isSmooth ? "smooth" : "auto",
        });
        lenis?.start();
        window.history.replaceState(null, "", href + "#" + hash);
      } else {
        navigate(href + "#" + hash);
      }
    } else {
      navigate(href);
    }
  };

  return { onClickHashLink, pathname };
};
