import { media } from "styles/variables";
import styled from "styled-components";
import UnstyledVideo from "components/video/Video";

export const Wrapper = styled.div`
  position: relative;
  overflow: hidden;

  padding-block: 141px 119px;

  ${media.greaterThan("md")`
    padding-block: 159px;
    margin-block: 116px 131px;
  `}

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const VideoTextRow = styled.div`
  display: flex;
  justify-content: flex-start;
  gap: 8px;

  ${media.greaterThan("sm")`
    justify-content: center;
    gap: 12px;
  `}
`;

export const VideoAndText = styled.div`
  position: relative;

  padding-top: 55px;
  padding-bottom: 94px;

  mix-blend-mode: exclusion;
`;

export const Video = styled(UnstyledVideo)`
  position: absolute;

  max-width: 150%;
  height: auto;
  max-height: none;
  object-fit: cover;

  left: 50%;
  top: 50%;
  transform: translate(-50%, -47%);

  z-index: -10;

  ${media.greaterThan("sm")`
    max-width: min(100vw, 1125px);
  `}
`;
