import Typography from "components/typography/Typography";
import * as Styled from "./VideoTextDesktop.styles";

const VideoTextDesktop = () => {
  return (
    <Styled.Wrapper>
      <Styled.VideoTextRow>
        <Styled.Typography color="white">Innovative</Styled.Typography>
        <Typography
          as="span"
          weight="800"
          variant="xl"
          lineHeight="59px"
          color="hitPink"
        >
          solutions
        </Typography>
      </Styled.VideoTextRow>
      <Styled.VideoTextRow>
        <Styled.Typography color="white">for</Styled.Typography>
        <Styled.Typography color="ebonyClay">your</Styled.Typography>
        <Styled.Typography color="ebonyClay">business</Styled.Typography>
      </Styled.VideoTextRow>
    </Styled.Wrapper>
  );
};

export default VideoTextDesktop;
