import { SVGProps } from "react";

export const ContactCardPhoneIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="34"
      height="34"
      viewBox="0 0 34 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g filter="url(#filter0_b_493_270)">
        <circle
          cx="17.2256"
          cy="16.6152"
          r="16.5"
          fill="white"
          fillOpacity="0.1"
        />
      </g>
      <path
        d="M18.1298 11.2188L16.3207 11.2188C15.7482 11.2188 15.1992 11.4462 14.7943 11.851C14.3895 12.2558 14.1621 12.8048 14.1621 13.3773V19.8531C14.1621 20.4256 14.3895 20.9747 14.7943 21.3795C15.1992 21.7843 15.7482 22.0117 16.3207 22.0117H18.1298C18.7023 22.0117 19.2513 21.7843 19.6561 21.3795C20.061 20.9747 20.2884 20.4256 20.2884 19.8531V13.3773C20.2884 12.8048 20.061 12.2558 19.6561 11.851C19.2513 11.4462 18.7023 11.2188 18.1298 11.2188ZM19.2091 19.8531C19.2091 20.1394 19.0954 20.4139 18.893 20.6163C18.6906 20.8187 18.416 20.9324 18.1298 20.9324H16.3207C16.0345 20.9324 15.7599 20.8187 15.5575 20.6163C15.3551 20.4139 15.2414 20.1394 15.2414 19.8531V13.3773C15.2414 13.0911 15.3551 12.8166 15.5575 12.6142C15.7599 12.4118 16.0345 12.298 16.3207 12.298H18.1298C18.416 12.298 18.6906 12.4118 18.893 12.6142C19.0954 12.8166 19.2091 13.0911 19.2091 13.3773V19.8531Z"
        fill="white"
      />
      <defs>
        <filter
          id="filter0_b_493_270"
          x="-24.2744"
          y="-24.8848"
          width="83"
          height="83"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="12.5" />
          <feComposite
            in2="SourceAlpha"
            operator="in"
            result="effect1_backgroundBlur_493_270"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_backgroundBlur_493_270"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};

export default ContactCardPhoneIcon;
