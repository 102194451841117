import { Typography } from "components/typography/Typography";

import * as Styled from "./Info.styles";

interface InfoProps {
  title: string;
  description: string;
  maxWidth?: string;
  centerText?: boolean;
  maxTitleWidth?: string;
}

export const Info = ({
  title,
  description,
  maxWidth,
  centerText,
  maxTitleWidth,
}: InfoProps) => {
  return (
    <Styled.Container
      $maxWidth={maxWidth}
      $centerText={centerText}
      $maxTitleWidth={maxTitleWidth}
    >
      <Styled.TitleWrapper $maxTitleWidth={maxTitleWidth}>
        <Typography variant="md" weight="600">
          {title}
        </Typography>
      </Styled.TitleWrapper>
      <Typography variant="sm" color="gray" lineHeight="17px">
        {description}
      </Typography>
    </Styled.Container>
  );
};

export default Info;
