export const contactCards = [
  {
    id: 0,
    caption: "general contact",
    title: "Let's stay in touch!",
    email: "contact@krukolabs.studio",
  },
  {
    id: 1,
    caption: "ai department",
    title: "AI Experts at your disposal!",
    email: "ai@krukolabs.studio",
  },
  {
    id: 2,
    caption: "product workshop",
    title: "From Idea to Product!",
    email: "product@krukolabs.studio",
  },
  {
    id: 3,
    caption: "looking for a gig?",
    title: "Join Our Team!",
    email: "jobs@krukolabs.studio",
  },
];
