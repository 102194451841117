import { TypographyProps } from "components/typography/Typography";
import UnstyledTypography from "components/typography/Typography";
import styled from "styled-components";
import { media } from "styles/variables";
import { VideoTextRow } from "./InnovativeSolutions.styles";

export const Typography = ({
  children,
  color,
}: {
  children: React.ReactNode;
  color: TypographyProps["color"];
}) => {
  return (
    <UnstyledTypography
      as="span"
      weight="700"
      variant="innovativeSolutionsMobile"
      lineHeight="1"
      color={color}
    >
      {children}
    </UnstyledTypography>
  );
};

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  transform: translateX(-1vw);

  ${media.greaterThan("sm")`
    transform: translateX(0vw);
  `}

  & .solutionsFor {
    transform: translateX(11vw);
  }
`;

export { VideoTextRow };
