import * as Styled from "./ContactCard.styles";

import Typography from "components/typography/Typography";
import { ContactCardIcon } from "assets/contactCardIcon";
import { useScreenContext } from "context/ScreenContext";

export type ContactCardProps = {
  id?: number;
  caption: string;
  title: string;
  email: string;
};

export const ContactCard = ({ caption, title, email }: ContactCardProps) => {
  const { isDesktop } = useScreenContext();

  return (
    <Styled.Wrapper>
      <Styled.CaptionWrapper>
        <Typography variant="sm" weight="500" isUppercase letterSpacing={3}>
          {caption}
        </Typography>
      </Styled.CaptionWrapper>
      <Styled.TitleWrapper>
        <Typography variant="title" color="white" weight="800">
          {title}
        </Typography>
      </Styled.TitleWrapper>
      <Styled.EmailWrapper>
        <ContactCardIcon />
        <Styled.Email>
          <Typography
            variant="sm"
            weight="500"
            color="white"
            isUnderline={!isDesktop}
          >
            {email}
          </Typography>
        </Styled.Email>
      </Styled.EmailWrapper>
    </Styled.Wrapper>
  );
};

export default ContactCard;
