import { Hash } from "hooks/useHashLink";

export const hashIds = {
  home: "home",
  services: "services",
  about: "about",
  "contact-us": "contact-us",
  "personal-touch": "personal-touch",
  "innovative-solutions": "innovative-solutions"
} as const;

export type HashKey = (typeof hashIds)[keyof typeof hashIds];

export const desktopHashes: Record<HashKey, Hash> = {
  home: {
    hash: "home",
    href: "/",
    block: "start",
  },
  services: {
    hash: "services",
    href: "/",
    block: "start",
  },
  about: {
    hash: "about",
    href: "/",
  },
  "contact-us": {
    hash: "contact-us",
    href: "/",
  },
  "personal-touch": {
    href: "/",
    hash: "personal-touch",
  },
  "innovative-solutions": {
    hash: "innovative-solutions",
    href: "/",
    block: "start",
  }
};

export const mobileHashes: Record<HashKey, Hash> = {
  ...desktopHashes,
  about: {
    ...desktopHashes.about,
    block: "start",
  },
  "personal-touch": {
    ...desktopHashes["personal-touch"],
    block: "start",
  },
};
