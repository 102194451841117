import * as Styled from "./InnovativeSolutions.styles";

import { Logos } from "./logos/Logos";
import Button from "components/button/Button";
import VideoTextDesktop from "./VideoTextDesktop";
import VideoTextMobile from "./VideoTextMobile";
import { useScreenContext } from "context/ScreenContext";
import { useSectionRefs } from "context/SectionRefContext";

type InnovativeSolutionsProps = {
  src: string;
  mobileSrc: string;
  buttonLink: string;
  buttonCTA: string;
};

export const InnovativeSolutions = ({
  src,
  mobileSrc,
  buttonCTA,
  buttonLink,
}: InnovativeSolutionsProps) => {
  const { isMobile } = useScreenContext();

  const { innovativeSolutions } = useSectionRefs();

  return (
    <Styled.Wrapper ref={innovativeSolutions}>
      <Logos />
      <Styled.VideoAndText>
        {isMobile ? <VideoTextMobile /> : <VideoTextDesktop />}
        <Styled.Video src={src} mobileSrc={mobileSrc} />
      </Styled.VideoAndText>
      <Button variant="cta" aria-label={buttonCTA} cta={buttonCTA} href={buttonLink} />
    </Styled.Wrapper>
  );
};

export default InnovativeSolutions;
