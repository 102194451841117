import { SVGProps } from "react";

export const BehanceIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="26"
      height="17"
      viewBox="0 0 26 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10.7187 7.5339C11.2331 7.27517 11.6219 6.98701 11.8884 6.67561C12.3625 6.11478 12.5964 5.37113 12.5964 4.44933C12.5964 3.5523 12.3625 2.78542 11.8946 2.14248C11.1138 1.09518 9.79225 0.559137 7.92384 0.53125H0.521484V15.6412H7.42343C8.20116 15.6412 8.92157 15.5746 9.58775 15.4382C10.2539 15.3003 10.8303 15.0478 11.3183 14.6791C11.7521 14.3584 12.1146 13.9602 12.4028 13.4908C12.8582 12.7812 13.086 11.9772 13.086 11.0817C13.086 10.2141 12.8861 9.47512 12.4895 8.86781C12.0883 8.2605 11.4995 7.81587 10.7187 7.5339ZM3.57507 3.15569H6.90908C7.64188 3.15569 8.24609 3.23471 8.72016 3.39118C9.2686 3.61892 9.54282 4.08215 9.54282 4.78861C9.54282 5.42226 9.33367 5.86535 8.92002 6.11478C8.50327 6.36421 7.96257 6.4897 7.29949 6.4897H3.57507V3.15569ZM8.8472 12.7534C8.47848 12.9315 7.95948 13.0198 7.29484 13.0198H3.57507V8.9902H7.34597C8.00286 8.99485 8.51411 9.08161 8.87974 9.24428C9.53042 9.53864 9.85422 10.0778 9.85422 10.8664C9.85422 11.7959 9.51958 12.4218 8.8472 12.7534Z"
        fill="#FFA775"
      />
      <path
        d="M22.9253 1.23616H16.3611V3.11696H22.9253V1.23616Z"
        fill="#FFA775"
      />
      <path
        d="M25.2058 8.65866C25.0695 7.78333 24.7689 7.01335 24.3011 6.34872C23.7883 5.59578 23.1376 5.04424 22.3459 4.69566C21.5573 4.34553 20.6696 4.17046 19.6812 4.17201C18.0219 4.17201 16.6741 4.69101 15.6314 5.72127C14.5919 6.75462 14.0713 8.24036 14.0713 10.1769C14.0713 12.2421 14.6461 13.734 15.8018 14.6496C16.9529 15.5668 18.2822 16.0238 19.7912 16.0238C21.6177 16.0238 23.0384 15.48 24.0532 14.3956C24.7023 13.7108 25.0695 13.0369 25.1501 12.3753H22.1259C21.9508 12.7022 21.7479 12.9579 21.5155 13.1438C21.0941 13.4846 20.5457 13.655 19.8733 13.655C19.2334 13.655 18.6912 13.514 18.2388 13.2336C17.4921 12.7843 17.1017 11.9973 17.0521 10.8772H25.305C25.3174 9.91201 25.2864 9.16992 25.2058 8.65866ZM17.1264 8.94837C17.2349 8.22177 17.4983 7.64545 17.9166 7.2194C18.3349 6.7949 18.9267 6.58111 19.6843 6.57956C20.383 6.57956 20.9671 6.77941 21.4427 7.18067C21.9121 7.58503 22.177 8.17219 22.2312 8.94837H17.1264Z"
        fill="#FFA775"
      />
    </svg>
  );
};

export default BehanceIcon;
