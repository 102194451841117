export function isInViewportIntersection(
  element: HTMLElement,
  cb: (isInViewport: boolean) => void
) {
  const observer = new IntersectionObserver((entries) => {
    observer.disconnect();
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        cb(true);
      } else {
        cb(false);
      }
    });
  });
  observer.observe(element);
}
