import Typography from "components/typography/Typography";
import * as Styled from "./IndexPlus.styles";

import mockData from "./IndexPlus.mock.json";

import { IndexPlusProject } from "./project/project";
import { useScreenContext } from "context/ScreenContext";
import { ChevronDown } from "assets/chevronDown";
import { useCallback, useEffect, useRef, useState } from "react";

export const IndexPlus = () => {
  const { isDesktop } = useScreenContext();

  const handleScrollToNextProject = () => {
    projectsRef.current!.removeEventListener("scroll", handleScroll);
    projectsRef.current!.scrollTo({
      top: projectsRef.current!.offsetTop + projectsRef.current!.offsetHeight,
      behavior: "smooth",
    });
    setIsChevronVisible(false);
  };

  const projectsRef = useRef<HTMLDivElement>(null);
  const projectsRefs = useRef<{ isVisible: () => boolean }[]>([]);

  const [isChevronVisible, setIsChevronVisible] = useState(false);

  const checkAllProjectsInView = () => {
    const projectsInView = projectsRefs.current.filter((project) =>
      project.isVisible()
    );

    if (projectsInView.length === projectsRefs.current.length) {
      return true;
    }

    return false;
  };

  const handleScroll = useCallback(() => {
    if (projectsRef.current) {
      const isAllProjectsInView = checkAllProjectsInView();
      const isScrolledToBottom =
        projectsRef.current.scrollHeight - projectsRef.current.scrollTop ===
        projectsRef.current.clientHeight;
      const shouldShowChevron = !isAllProjectsInView && !isScrolledToBottom;
      setIsChevronVisible(shouldShowChevron);
    }
  }, []);

  useEffect(() => {
    const projectsRefSnapshot = projectsRef.current;

    if (projectsRef.current) {
      projectsRef.current.addEventListener("scroll", handleScroll);
    }

    if (!checkAllProjectsInView()) {
      setIsChevronVisible(true);
    }

    return () => {
      if (projectsRefSnapshot) {
        projectsRefSnapshot.removeEventListener("scroll", handleScroll);
      }
    };
  }, [handleScroll]);

  return (
    <Styled.Wrapper>
      <Styled.Content>
        <Styled.TitleWrapper>
          <Styled.Title $isUppercase>{mockData.indexPlus}</Styled.Title>
          <Styled.Title>{mockData.projectOverview}</Styled.Title>
        </Styled.TitleWrapper>
        <Styled.DescriptionWrapper>
          <Typography weight="400" variant="sm" color="gray">
            {mockData.description}
          </Typography>
        </Styled.DescriptionWrapper>
        <Styled.Projects ref={projectsRef}>
          {mockData.projects.map((project, idx) => (
            <IndexPlusProject
              title={project.title}
              tags={project.tags}
              href={project.href}
              key={project.id}
              id={idx.toString()}
              ref={(ref) => {
                if (ref) {
                  projectsRefs.current[idx] = ref;
                }
              }}
            />
          ))}
        </Styled.Projects>
        <Styled.SeeMoreChevron
          onClick={handleScrollToNextProject}
          $isVisible={isChevronVisible}
          aria-label="see more projects"
        >
          <ChevronDown />
        </Styled.SeeMoreChevron>
        {isDesktop && <Styled.FakeHeader />}
      </Styled.Content>
    </Styled.Wrapper>
  );
};

export default IndexPlus;
