import { Typography } from "components/typography/Typography";
import * as Styled from "./Representation.styles";
import Video from "components/video/Video";
import { useScreenContext } from "context/ScreenContext";
import representationVideo from "assets/video/representation.mp4";
import { useSectionRefs } from "context/SectionRefContext";

const Representation = () => {
  const { isMobile } = useScreenContext();
  const { representation } = useSectionRefs();

  return (
    <Styled.Wrapper ref={representation}>
      <Styled.Container
        initial={{ opacity: 0, x: "-9vw" }}
        whileInView={{ opacity: 1, x: isMobile ? 0 : "-5.5vw" }}
        transition={{ duration: 0.5 }}
        style={{ flexDirection: "row" }}
        viewport={{ once: true }}
      >
        <Typography
          weight="700"
          variant="leadSm"
          color="ebonyClay"
          letterSpacing={-5}
          className="MainHeading-highlighted"
          as="span"
        >
          Representation
        </Typography>
        <Typography
          weight="700"
          variant="leadSm"
          color="ebonyClay"
          letterSpacing={-5}
          className="MainHeading-highlighted"
          as="span"
        >
          of
        </Typography>
      </Styled.Container>
      <Styled.RelativeWrapper>
        <Styled.Container style={{ gap: 0 }}>
          <Styled.SecondLineWrapper
            initial={{ opacity: 0, x: "9vw" }}
            whileInView={{ opacity: 1, x: "5.5vw" }}
            transition={{ duration: 0.55 }}
            viewport={{ once: true }}
          >
            <Typography
              letterSpacing={-5}
              weight="700"
              variant="leadSm"
              color="white"
              className="MainHeading-projects"
              as="span"
            >
              the
            </Typography>
            <Typography
              letterSpacing={-5}
              weight="700"
              variant="leadSm"
              color="hitPink"
              className="MainHeading-projects"
              as="span"
            >
              good
            </Typography>
            <Styled.DesktopChange>
              <Typography
                letterSpacing={-5}
                weight="700"
                variant="leadSm"
                color="white"
                className="MainHeading-projects"
                as="span"
              >
                change
              </Typography>
            </Styled.DesktopChange>
          </Styled.SecondLineWrapper>
          <Styled.MobileChange
            initial={{ opacity: 0, x: "-18vw" }}
            whileInView={{ opacity: 1, x: "-11vw" }}
            transition={{ duration: 0.55 }}
          >
            <Typography
              letterSpacing={-5}
              weight="700"
              variant="leadSm"
              color="ebonyClay"
              className="MainHeading-projects"
              as="span"
            >
              change
            </Typography>
          </Styled.MobileChange>
        </Styled.Container>
        <Video
          src={representationVideo}
          mobileSrc={representationVideo}
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6 }}
          className="MainHeading-video"
        />
      </Styled.RelativeWrapper>
    </Styled.Wrapper>
  );
};
export default Representation;
