import * as Styled from "./CaseStudies.styles";

import Typography from "components/typography/Typography";
import CaseStudy from "components/case-study/CaseStudy";

import { MobileCaseStudies } from "./CaseStudies.mock";

export const CaseStudiesMobile = () => {
  return (
    <Styled.MobileWrapper>
      <Styled.TitleWrapper>
        <Typography
          variant="sm"
          color="hitPink"
          weight="500"
          isUppercase
          letterSpacing={3}
        >
          selected case studies
        </Typography>
      </Styled.TitleWrapper>
      {MobileCaseStudies.map((item) => (
        <CaseStudy
          isMobile
          href={item.href}
          key={item.id}
          imageSrc={item.imageSrc}
          imageWrapperCss={item.imageWrapperCss}
          imageCss={item.imageCss}
          title={item.title}
          description={item.description}
          icon={item.icon}
        />
      ))}
    </Styled.MobileWrapper>
  );
};

export default CaseStudiesMobile;
