import { styled } from "styled-components";
import { media } from "styles/variables";

export const MainHeading = styled.div`
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  transform: translateX(
    -5%
  ); // offset "projects" heading transform, so the whole heading is centered.
  margin-bottom: 116px;

  mix-blend-mode: exclusion;

  ${media.greaterThan("sm")`
    margin-bottom: 0;
  `}

  ${media.greaterThan("md")`
    margin-bottom: 80px;
  `}

  .MainHeading-motion_wrapper {
    position: relative;
    mix-blend-mode: difference;
    z-index: 1;
  }

  .MainHeading-video {
    mix-blend-mode: difference;
    width: 240%;
    height: 240%;
    left: -130%;
    top: -100%;
  }

  .MainHeading-highlighted,
  .MainHeading-projects {
    font-size: clamp(50px, 10vw, 145px);
    line-height: 1;
    letter-spacing: -5px;
  }

  .MainHeading-projects {
    position: relative;
    transform: translateX(12%);
  }
`;
