import * as Styled from "./Header.styles";
import { Button } from "../button/Button";
import { Typography } from "components/typography/Typography";

import { copy } from "copy";
import KrukoLogo from "assets/krukoLogo";
import { forwardRef } from "react";
import { useHashLink } from "hooks/useHashLink";
import { useHashes } from "hooks/useHashes";

type HeaderProps = {
  mobileButtonOnClick?: () => void;
  isMobileButtonToggled?: boolean;
  isSticky?: boolean;
  closeIndexPlus?: () => void;
};

const Header = forwardRef<HTMLDivElement, HeaderProps>(
  (
    {
      isMobileButtonToggled,
      mobileButtonOnClick,
      isSticky = true,
      closeIndexPlus,
    },
    ref
  ) => {
    const { onClickHashLink } = useHashLink();
    const hashes = useHashes();

    return (
      <Styled.Container
        $isSticky={isSticky}
        $isMobileButtonToggled={isMobileButtonToggled}
        ref={ref}
      >
        <Styled.Content>
          <div>
            <KrukoLogo
              width={88}
              height={33}
              onClick={() => {
                onClickHashLink(hashes.home);
              }}
              style={{ cursor: "pointer" }}
            />
          </div>
          <Styled.LinksList>
            {copy.footer.links.map((link, index) => (
              <Styled.LinksListItem key={`${link.name}_${index}`}>
                {link.isExternal ? (
                  <Styled.LinkAnchor
                    href={link.href}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Typography variant="sm" weight="500" letterSpacing={1.3}>
                      {link.name}
                    </Typography>
                  </Styled.LinkAnchor>
                ) : (
                  <Styled.LinkAnchor
                    onClick={(e) => {
                      e.preventDefault();
                      if (closeIndexPlus) {
                        closeIndexPlus();
                      }
                      onClickHashLink(hashes[link.hashId]);
                    }}
                    href={`${hashes[link.hashId].href}#${hashes[link.hashId].hash
                      }`}
                  >
                    <Typography variant="sm" weight="300" letterSpacing={1.3}>
                      {link.name}
                    </Typography>
                  </Styled.LinkAnchor>
                )}
              </Styled.LinksListItem>
            ))}
          </Styled.LinksList>
          <div>
            <Styled.DesktopButtonWrapper>
              <Button
                aria-label="move to personal touch"
                variant="ctaHash"
                cta={copy.footer.buttonCTA}
                hash={hashes["personal-touch"]}
                onClick={() => {
                  if (closeIndexPlus) {
                    closeIndexPlus();
                  }
                }}
              />
            </Styled.DesktopButtonWrapper>
            <Styled.MobileButtonWrapper>
              <Button
                aria-label="mobile menu"
                variant="menu"
                isOpen={isMobileButtonToggled}
                onClick={mobileButtonOnClick}
              />
            </Styled.MobileButtonWrapper>
          </div>
        </Styled.Content>
      </Styled.Container>
    );
  }
);

export default Header;
