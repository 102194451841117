import { motion } from "framer-motion";
import { styled } from "styled-components";
import { media } from "styles/variables";

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 105px 0;

  mix-blend-mode: exclusion;

  transform: translateX(-1.5vw);

  .MainHeading-highlighted,
  .MainHeading-projects {
    font-size: clamp(40px, 10vw, 145px);
    line-height: 1;
    letter-spacing: -5px;
    white-space: nowrap;
  }

  ${media.greaterThan("sm")`
    transform: translateX(0);
    padding: 190px 0 170px;
  `}
`;

export const Container = styled(motion.div)`
  position: relative;
  mix-blend-mode: difference;
  z-index: 1;
  display: flex;
  flex-direction: column;

  gap: min(3vw, 12px);
  ${media.greaterThan("sm")`
    gap: min(3vw, 24px);
  `}
`;

export const RelativeWrapper = styled.div`
  position: relative;

  .MainHeading-video {
    mix-blend-mode: difference;
    position: absolute;
    max-width: 168%;
    max-height: 168%;
    left: -65%;
    top: -60%;

    ${media.greaterThan("sm")`
      max-width: 215%;
      max-height: 215%;
      left: 44%;
      top: -110%;
    `}
  }
`;

export const SecondLineWrapper = styled(motion.div)`
  position: relative;
  display: flex;

  gap: min(3vw, 12px);
  ${media.greaterThan("sm")`
    gap: min(3vw, 24px);
  `}
`;

export const MobileChange = styled(motion.div)`
  ${media.greaterThan("sm")`
    display: none;
  `}
`;

export const DesktopChange = styled.div`
  display: none;

  ${media.greaterThan("sm")`
    display: flex;
  `}
`;
