import {
  AnimatePresence,
  AnimationPlaybackControls,
  useAnimate,
} from "framer-motion";
import MobileLinks from "./components/mobile-links/MobileLinks";
import * as Styled from "./MobileMenu.styles";
import HighlightedProject from "./components/highlighted-proejct/HighlightedProject";
import { highlightedProjects } from "sections/highlighted-projects/HighlightedProjects.mock";
import { forwardRef, useLayoutEffect } from "react";

type MobileMenuProps = {
  children: React.ReactNode;
  isToggled: boolean;
  isIndexPlusOpen: boolean;
  handleClickIndexPlus: () => void;
  handleOpenMenu: () => void;
  handleCloseMenu: () => void;
  onOpenComplete: () => void;
};

const MobileMenu = forwardRef<HTMLDivElement, MobileMenuProps>(
  (
    {
      isToggled,
      isIndexPlusOpen,
      handleClickIndexPlus,
      handleOpenMenu,
      handleCloseMenu,
      onOpenComplete,
      children,
    },
    ref
  ) => {
    useLayoutEffect(() => {
      if (!isToggled && isIndexPlusOpen) {
        handleOpenMenu();
      }
    }, [handleOpenMenu, isIndexPlusOpen, isToggled]);

    // Close menu when it unmounts
    useLayoutEffect(() => {
      return () => {
        handleCloseMenu();
      };
    }, [handleCloseMenu]);

    // Animate the menu slide out imperatively, to know when the animation is done.
    const [scope, animate] = useAnimate();

    let animation: AnimationPlaybackControls | null = null;

    if (isToggled && scope.current) {
      animation = animate(
        '[data-animate="Blur"]',
        {
          x: "-100%",
        },
        {
          ease: "easeInOut",
          duration: isIndexPlusOpen ? 0 : 0.3,
        }
      );
    } else if (scope.current) {
      animate(
        '[data-animate="Blur"]',
        {
          x: "0",
        },
        {
          ease: "easeInOut",
          duration: 0.3,
        }
      );
    }

    animation?.then(() => {
      onOpenComplete();
    });

    return (
      <AnimatePresence initial={false}>
        <div ref={scope} data-lenis-prevent>
          <Styled.Blur data-animate="Blur" key="Blur" />
          <Styled.MobileMenu
            key="MobileMenu"
            $isIndexPlusOpen={isIndexPlusOpen}
            animate={{
              x: isToggled ? 0 : "100%",
              transition: {
                ease: "easeInOut",
                duration: isIndexPlusOpen ? 0 : 0.3,
              },
            }}
            ref={ref}
          >
            {children}
            <Styled.MobileMenuContent>
              <Styled.MobileLinks>
                <MobileLinks
                  onClickIndexPlus={handleClickIndexPlus}
                  handleCloseMenu={handleCloseMenu}
                />
              </Styled.MobileLinks>
              <Styled.HighlightedProject>
                <HighlightedProject project={highlightedProjects[0]} />
              </Styled.HighlightedProject>
            </Styled.MobileMenuContent>
            <Styled.MobileMenuBackground />
          </Styled.MobileMenu>
        </div>
      </AnimatePresence>
    );
  }
);

export default MobileMenu;
