import { hashIds } from "constants/hashes";

export const copy = {
  footer: {
    title: "Innovation in every detail",
    description:
      "We help you push the boundaries of your imagination by creating unique designs tailored to your needs.",
    buttonCTA: "Get in touch",
    links: [
      {
        isExternal: false,
        name: "services",
        hashId: hashIds.services,
      },
      {
        name: "kruko:io",
        href: "https://kruko.io/",
        isExternal: true,
      },
      {
        isExternal: false,
        name: "about",
        hashId: hashIds.about,
      },
      {
        isExternal: false,
        name: "contact us",
        hashId: hashIds["contact-us"],
      },
    ] as const,
  },
};
