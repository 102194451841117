import { Typography } from "components/typography/Typography";
import * as Styled from "./ButtonVertical.styles";

type ButtonVerticalProps = {
  onClick?: () => void;
  text: string;
  icon: React.ReactNode;
  className?: string;
  ariaLabel?: string;
};

export const ButtonVertical = ({
  onClick,
  text,
  icon,
  className,
  ariaLabel = ""
}: ButtonVerticalProps) => {
  return (
    <Styled.ButtonVertical onClick={onClick} className={className} aria-label={ariaLabel}>
      <Typography className="text" variant="xSm" weight="600" color="hitPink">
        {text}
      </Typography>
      <div className="button-vertical-icon">{icon}</div>
    </Styled.ButtonVertical>
  );
};
