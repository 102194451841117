import Layout from "components/layout/Layout";
import Info from "components/info/Info";

import * as Styled from "./Hero.styles";
import { Button } from "components/button/Button";
import { copy } from "copy";
import Typography from "components/typography/Typography";
import { useScreenContext } from "context/ScreenContext";
import { useEffect, useRef } from "react";
import { useHashes } from "hooks/useHashes";
import discoverVideo from "assets/video/discover-video.mp4";

import smallVideoMockup from "assets/images/small-video-mockup.png";
import { useHashLink } from "hooks/useHashLink";
import { motion } from "framer-motion";

type HeroTextProps = {
  children: string;
};

const HeroText = ({ children }: HeroTextProps) => (
  <motion.div
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    transition={{ duration: 2 }}
  >
    <Typography
      color="white"
      variant="heroResponsive"
      weight="800"
      lineHeight="1"
      letterSpacing={-8}
      as="span"
      isUppercase
      fixIndent
    >
      {children}
    </Typography>
  </motion.div>
);

export const Hero = () => {
  const { isDesktop, dimensions } = useScreenContext();

  const wrapperRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (wrapperRef.current && !isDesktop) {
      wrapperRef.current.style.height = dimensions[1] + "px";
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDesktop]);

  const hashes = useHashes();
  const { onClickHashLink } = useHashLink();

  return (
    <div
      ref={wrapperRef}
      style={{ position: "relative", height: "100vh", pointerEvents: "none" }}
      id={hashes.home.hash}
    >
      <Layout>
        <Styled.Wrapper>
          <Styled.SmallVideoWrapper>
            <Styled.SmallVideoButton>
              <Button
                aria-label="scroll to innovative solutions section"
                variant="link"
                onClick={() => onClickHashLink(hashes["innovative-solutions"])}
              />
            </Styled.SmallVideoButton>
            <Styled.SmallVideoImageWrapper>
              <Styled.SmallVideoImage src={smallVideoMockup} alt="" />
            </Styled.SmallVideoImageWrapper>
          </Styled.SmallVideoWrapper>
          <Styled.InfoDesktopWrapper>
            <Info
              title="Innovation in every detail"
              description="We help you push the boundaries of your imagination by creating unique designs tailored to your needs."
              maxWidth="440px"
            />
          </Styled.InfoDesktopWrapper>
          <Styled.InfoMobileWrapper>
            <Info
              title="Innovation in every detail"
              description="We help you push the boundaries of your imagination by creating unique designs tailored to your needs."
              maxWidth="440px"
              centerText
            />
            <Styled.InfoMobileButtonWrapper>
              <Button
                aria-label="scroll to personal touch section"
                variant="ctaHash"
                cta={copy.footer.buttonCTA}
                hash={hashes["personal-touch"]}
              />
            </Styled.InfoMobileButtonWrapper>
          </Styled.InfoMobileWrapper>
        </Styled.Wrapper>
        <Styled.ImagineMoreWrapper>
          {isDesktop ? (
            <HeroText>Imagine More</HeroText>
          ) : (
            <>
              <HeroText>Imagine</HeroText>
              <HeroText>More</HeroText>
            </>
          )}
        </Styled.ImagineMoreWrapper>
        <Styled.VideoWrapper>
          <Styled.HeroVideo
            hasContainer
            src={discoverVideo}
            mobileSrc={discoverVideo}
          />
          {!isDesktop && <Styled.KrukoLogo />}
        </Styled.VideoWrapper>
      </Layout>
    </div>
  );
};

export default Hero;
