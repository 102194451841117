// import Typography from "components/typography/Typography";
import * as Styled from "./Footer.styles";

// import { footerData } from "./Footer.data";
// import { useScreenContext } from "context/ScreenContext";

import { useSectionRefs } from "context/SectionRefContext";

// TODO: add links functionality once we agree on what has to be added in footer and bring back content
export const Footer = () => {
  // const { isMobile } = useScreenContext();

  const { footer } = useSectionRefs();

  return (
    <Styled.Wrapper ref={footer}>
      {/* <Styled.Content>
        {footerData.map((item) => (
          <Styled.Column key={item.id}>
            <Styled.ColumnBody>
              <Styled.ColumnTitleWrapper>
                <Typography
                  variant={isMobile ? "footerTitleSm" : "footerTitle"}
                  weight="700"
                >
                  {item.title}
                </Typography>
              </Styled.ColumnTitleWrapper>
              <Styled.ColumnLinksWrapper>
                {item.links.map((link) => (
                  <Styled.ColumnLink key={link.id}>
                    <Typography variant="sm" weight="400" color="gray">
                      {link.name}
                    </Typography>
                  </Styled.ColumnLink>
                ))}
              </Styled.ColumnLinksWrapper>
            </Styled.ColumnBody>
          </Styled.Column>
        ))}
      </Styled.Content> */}
    </Styled.Wrapper>
  );
};

export default Footer;
