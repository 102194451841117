import KrukoLogo from "assets/krukoLogo";
import * as Styled from "./LoadingScreen.styles";
import { circleSize } from "./LoadingScreen.styles";
import { useLoadingContext } from "context/LoadingContext";
import gsap from "gsap";
import { useGSAP } from "@gsap/react";

// TODO: Prevent scroll when loading screen visible. It's difficult, because
// position: fixed on a body creates a new stacking context, which makes the
// mix-blend-moded videos have their background color.
export const LoadingScreen = ({
  setIsVisible,
  isDesktop,
}: {
  setIsVisible: (isVisible: boolean) => void;
  isDesktop: boolean;
}) => {
  const { videoProgress, isCanvasLoaded } = useLoadingContext();

  useGSAP(() => {
    if (!isCanvasLoaded) return;

    gsap.to(".orange-svg-moving", {
      rotate: videoProgress * 360,
      ease: "sine.inOut",
      duration: 1,
    });

    gsap.to('[data-animate="KrukoLogoContainer"]', {
      opacity: Math.max(videoProgress, 0.77),
      ease: "sine.inOut",
    });

    const dash = videoProgress * Styled.CIRCUMFERENCE;

    gsap.to("circle.fg", {
      strokeDasharray: `${dash} ${Styled.CIRCUMFERENCE - dash}`,
      ease: "sine.inOut",
      duration: 1,
    });

    if (videoProgress === 1) {
      gsap.to('[data-animate="LoadingScreenInner"] *', {
        opacity: 0,
        duration: 0.6,
        delay: 1,
        ease: "power2.out",
      });
      gsap.to('[data-animate="LoadingScreenInner"]', {
        backdropFilter: "blur(0px) opacity(0)",
        duration: 0.8,
        delay: 0.8,
        ease: "power3.inOut",
      }).then(() => {
        setIsVisible(false);
      });
      gsap.to('[data-animate="Bg"]', {
        opacity: 0,
        duration: 0.5,
        delay: 1.2,
        ease: "power2.out",
      });
      gsap.to('[data-animate="LoadingText"]', {
        opacity: 0,
        duration: 1,
        ease: "power2.out",
      });
    }
  }, [videoProgress, isCanvasLoaded]);

  return (
    <Styled.Wrapper data-animate="LoadingScreen">
      {isDesktop && <Styled.Background data-animate="Bg" />}
      <Styled.LoadingScreen data-animate="LoadingScreenInner">
        <svg
          width={circleSize}
          height={circleSize}
          viewBox={`0 0 ${circleSize} ${circleSize}`}
        >
          <circle className="bg" />
          <circle className="fg" />
        </svg>
        <Styled.KrukoLogoContainer data-animate="KrukoLogoContainer">
          <KrukoLogo />
        </Styled.KrukoLogoContainer>
        <div className="oranges">
          <svg
            className="orange-svg"
            width={circleSize}
            height={circleSize}
            viewBox={`0 0 ${circleSize} ${circleSize}`}
          >
            <circle className="orange-circle" fill="#ff8e4d" />
          </svg>
          <svg
            className="orange-svg-moving"
            width={circleSize}
            height={circleSize}
            viewBox={`0 0 ${circleSize} ${circleSize}`}
          >
            <circle className="orange-circle" fill="#ff8e4d" />
          </svg>
        </div>
        <Styled.LoadingText data-animate="LoadingText">
          Loading
          <span className="loading-dot">.</span>
          <span className="loading-dot">.</span>
          <span className="loading-dot">.</span>
        </Styled.LoadingText>
      </Styled.LoadingScreen>
    </Styled.Wrapper>
  );
};
