import styled from "styled-components";
import { motion } from "framer-motion";

const SwitchWrapper = styled.div<{ readonly?: boolean }>`
  display: inline-block;
  cursor: ${(props) => (props.readonly ? "default" : "pointer")};
  opacity: ${(props) => (props.readonly ? 0.5 : 1)};
  pointer-events: ${(props) => (props.readonly ? "none" : "auto")};
`;

const SwitchTrack = styled(motion.div)`
  width: 50px;
  height: 24px;
  background-color: #e0e0e0;
  border-radius: 12px;
  padding: 2px;
`;

const SwitchThumb = styled(motion.div)`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: white;
`;

interface AnimatedSwitchProps {
  isOn: boolean;
  toggleSwitch?: () => void;
  readonly?: boolean;
}

export const Switch: React.FC<AnimatedSwitchProps> = ({
  isOn,
  toggleSwitch,
  readonly,
}) => {
  return (
    <SwitchWrapper onClick={toggleSwitch} readonly={readonly}>
      <SwitchTrack
        initial={false}
        animate={{
          backgroundColor: isOn ? "#FFA775" : "#424242",
        }}
      >
        <SwitchThumb
          initial={false}
          animate={{
            x: isOn ? 26 : 0,
          }}
          transition={{
            type: "spring",
            stiffness: 700,
            damping: 30,
          }}
        />
      </SwitchTrack>
    </SwitchWrapper>
  );
};
