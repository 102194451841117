import Typography from "components/typography/Typography";
import { TypographyProps } from "components/typography/Typography";
import Video from "components/video/Video";
import { useScreenContext } from "context/ScreenContext";
import { motion } from "framer-motion";
import styled from "styled-components";
import { media } from "styles/variables";

export const Wrapper = styled.div`
  max-width: 1920px;

  display: flex;
  place-items: center;
  margin: 0 auto;
  flex-direction: column;
  overflow: hidden;
  justify-content: center;

  padding-block: 90px 40px;

  ${media.greaterThan("md")`
    height: auto;
    padding-bottom: 209px; 
  `}
  mix-blend-mode: exclusion;
`;

export const Heading = ({
  children,
  color,
}: {
  children: React.ReactNode;
  color: TypographyProps["color"];
}) => {
  const { isMobile } = useScreenContext();
  return (
    <Typography
      color={color}
      variant="transformingVisionsResponsive"
      weight="700"
      lineHeight="1"
      letterSpacing={isMobile ? -3 : -5}
      as="span"
    >
      {children}
    </Typography>
  );
};

export const HeadingWrapper = styled.div`
  display: flex;
  flex-direction: column;

  transform: translateX(2%);

  ${media.greaterThan("sm")`
    transform: translateX(19%);
  `}
`;

export const VisionsInto = styled(motion.div)`
  display: flex;
  transform: translateX(-8%);
  gap: 8px;

  ${media.greaterThan("sm")`
    transform: translateX(-32%);
    gap: 24px; 
  `}
`;

export const Reality = styled.div`
  margin-left: auto;
  position: relative;
  display: inline-flex;
  mix-blend-mode: difference;

  ${media.greaterThan("sm")`
    transform: translateX(-36%);
  `}
`;

export const RealityVideo = styled(Video)`
  position: absolute;
  rotate: -50deg;
  top: -210%;
  left: -45%;
  max-width: 175%;
  max-height: none;
  mix-blend-mode: difference;

  ${media.greaterThan("sm")`
    top: -210%;
  `}
`;

export const Description = styled.div`
  margin-top: 61px;
  max-width: 550px;
  align-self: flex-start;
  font-size: 15px;

  margin-inline: auto;
  padding-inline: 25px;

  ${media.greaterThan("md")`
    margin-inline: 5%;
  `}
`;
