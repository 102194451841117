import GlobalStyles from "styles/globalStyles";
import { ReactLenis } from "lenis/dist/lenis-react";
import HomePage from "pages/home/home.page";
import { BackgroundExperience } from "components/background/BackgroundExperience";
import { LoadingScreen } from "components/loading-screen/LoadingScreen";
import { useEffect, useState } from "react";
import { useScreenContext } from "context/ScreenContext";
import { CookieBanner } from "components/cookie-banner/CookieBanner";
import { AnimatePresence } from "framer-motion";

const App = () => {
  const [isLoadingScreenVisible, setIsLoadingScreenVisible] = useState(true);

  const { isDesktop } = useScreenContext();

  const [showCookieBanner, setShowCookieBanner] = useState(false);

  useEffect(() => {
    const cookieConsent = localStorage.getItem("consentMode");
    if (!cookieConsent) {
      setShowCookieBanner(true);
    }
  }, []);

  return (
    <ReactLenis root>
      <GlobalStyles />
      {isLoadingScreenVisible && (
        <LoadingScreen
          setIsVisible={setIsLoadingScreenVisible}
          isDesktop={isDesktop}
        />
      )}
      <BackgroundExperience />
      <HomePage />
      <AnimatePresence>
        {showCookieBanner && (
          <CookieBanner
            isLoadingPage={isLoadingScreenVisible}
            showCookieBanner={showCookieBanner}
            setShowCookieBanner={setShowCookieBanner}
          />
        )}
      </AnimatePresence>
    </ReactLenis>
  );
};

export default App;
