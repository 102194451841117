import { useScreenContext } from "context/ScreenContext";
import styled from "styled-components";

const StyledMobileHashTarget = styled.div<{ $top: number }>`
  position: absolute;
  top: ${({ $top }) => $top}px;
  left: 0;
  width: 1px;
  height: 1px;
  visibility: hidden;
`;

export const MobileHashTarget = ({ id, top }: { id: string; top: number }) => {
  const { isDesktop } = useScreenContext();
  return <StyledMobileHashTarget $top={top} id={isDesktop ? undefined : id} />;
};
