import styled from "styled-components";

export const circleSize = 260;
export const orangeCircleSize = 10;
export const strokeWidth = 3;

export const RADIUS = (circleSize - strokeWidth - orangeCircleSize) / 2;
export const CIRCUMFERENCE = RADIUS * Math.PI * 2;

export const Background = styled.div<{ $isMobile?: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: url(/noise-light.png),
    radial-gradient(circle, hsl(232, 24%, 55%) -200%, transparent 91%);
  opacity: 0.3;
  mask-image: radial-gradient(circle, black 61%, transparent 100%);
  -webkit-mask-image: radial-gradient(circle, black 61%, transparent 100%);
  z-index: 10001;
`;

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10000;
`;

export const LoadingScreen = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;

  backdrop-filter: blur(100px) opacity(1);
  -webkit-backdrop-filter: blur(100px) opacity(1);

  will-change: transform;

  --size: ${circleSize}px;
  --orange-size: ${orangeCircleSize}px;
  --stroke-width: ${strokeWidth}px;
  --half-size: calc(var(--size) / 2);
  --radius: ${RADIUS}px;
  --circumference: ${CIRCUMFERENCE}px;
  --progress: 0;
  --dash: calc((var(--progress) * var(--circumference)));

  circle.bg,
  circle.fg {
    z-index: 10002;
    stroke-linecap: round;
    cx: var(--half-size);
    cy: var(--half-size);
    r: var(--radius);
    stroke-width: var(--stroke-width);
    fill: none;
    stroke-linecap: round;
  }

  circle.bg {
    stroke: hsla(0, 0%, 100%, 0.08);
  }

  circle.fg {
    transform: rotate(-90deg);
    transform-origin: var(--half-size) var(--half-size);
    stroke-dasharray: var(--dash) calc(var(--circumference) - var(--dash));
    will-change: stroke-dasharray;
    stroke: hsl(0, 0%, 77%);
  }

  & .oranges,
  & .orange-svg,
  & .orange-svg-moving {
    z-index: 10003;
    position: absolute;
    width: ${circleSize}px;
    height: ${circleSize}px;
  }

  & .orange-circle {
    cx: var(--half-size);
    cy: ${orangeCircleSize / 2 + 1}px;
    r: ${orangeCircleSize / 2}px;
  }

  & .orange-svg-moving {
    transform-origin: var(--half-size) var(--half-size);
    will-change: transform;
  }
`;

export const KrukoLogoContainer = styled.div`
  z-index: 10002;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0.77;
`;

export const LoadingText = styled.div`
  position: absolute;
  bottom: 20%;
  left: 50%;
  transform: translateX(-50%);
  font-size: 18px;
  color: hsl(0, 0%, 77%);
  letter-spacing: 1px;
  z-index: 10002;

  .loading-dot {
    animation: blink 1s infinite;
    opacity: 0;

    &:nth-child(2) {
      animation-delay: 0.2s;
    }

    &:nth-child(3) {
      animation-delay: 0.4s;
    }
  }

  @keyframes blink {
    0%,
    100% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
  }

  .loading-dot {
    display: inline-block;
    margin-left: 2px;
  }
`;
