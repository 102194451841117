import { forwardRef, ReactNode } from "react";
import * as Styled from "./Layout.styles";

interface LayoutProps {
  children: ReactNode;
}

export const Layout = forwardRef<HTMLDivElement, LayoutProps>(
  ({ children }, ref) => {
    return (
      <Styled.LayoutWrapper ref={ref}>
        <Styled.LayoutContent>{children}</Styled.LayoutContent>
      </Styled.LayoutWrapper>
    );
  }
);

export default Layout;
