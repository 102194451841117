import { motion, MotionValue } from "framer-motion";
import { forwardRef } from "react";
import { StyledCircle } from "./Circle.styles";

type CircleProps = {
  rotationHighlight: MotionValue<number>;
  rotationBackground: MotionValue<number>;
};

export type Ref = HTMLDivElement;

const Circle = forwardRef<Ref, CircleProps>(
  ({ rotationBackground, rotationHighlight }, ref) => {
    return (
      <StyledCircle ref={ref}>
        <motion.svg
          width="110%"
          height="110%"
          viewBox="0 0 904 904"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          style={{
            rotate: rotationBackground,
          }}
        >
          <circle
            cx="451.822"
            cy="451.858"
            r="450.581"
            stroke="#4C4C4C"
            strokeWidth="2"
            strokeDasharray="2 15"
          />
        </motion.svg>
        <motion.svg
          width="110%"
          height="110%"
          viewBox="0 0 904 904"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          style={{
            rotate: rotationHighlight,
          }}
        >
          <mask
            id="mask0_437_337"
            maskUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="904"
            height="904"
          >
            <circle
              cx="451.822"
              cy="451.858"
              r="450.581"
              stroke="white"
              strokeWidth="2"
              strokeDasharray="2 15"
            />
          </mask>
          <g mask="url(#mask0_437_337)">
            <circle cx="66.7525" cy="731.058" r="168.703" fill="#FFA775" />
            <circle cx="809.08" cy="196.838" r="168.703" fill="#FFA775" />
          </g>
        </motion.svg>
      </StyledCircle>
    );
  }
);

export default Circle;
