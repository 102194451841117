import BehanceIcon from "assets/logos/behance";
import * as Styled from "./Like.styles";
import DribbleIcon from "assets/logos/dribble";
import InstagramIcon from "assets/logos/instagram";
import { HTMLMotionProps, motion } from "framer-motion";

type LikeProps = {
  platform: "Behance" | "Dribble" | "Instagram";
  likes: number;
} & HTMLMotionProps<"div">;

const likeToReadableFormat = (likes: number) => {
  if (likes < 1000) {
    return likes;
  }
  if (likes >= 1000) {
    let string = (likes / 1000).toFixed(1).toString();
    const hasZeroAtEnd = string.endsWith("0");
    string = hasZeroAtEnd ? string.slice(0, -2) : string;
    return string + "K";
  }
  return likes;
};

const likeOrLikes = (likes: number) => (likes === 1 ? "Like" : "Likes");

const Like = ({ platform, likes, ...props }: LikeProps) => {
  return (
    <Styled.Like {...props}>
      {platform === "Behance" ? (
        <BehanceIcon />
      ) : platform === "Dribble" ? (
        <DribbleIcon />
      ) : (
        <InstagramIcon />
      )}
      <div className="Like-text">
        <motion.div
          key={likes}
          initial={{
            opacity: 0,
            scale: 0.8,
          }}
          animate={{
            opacity: 1,
            scale: 1,
          }}
          className="Like-likes"
        >
          {likeToReadableFormat(likes)}
        </motion.div>
        <div className="Like-description">
          {likeOrLikes(likes)} on {platform}
        </div>
      </div>
    </Styled.Like>
  );
};
export default Like;
