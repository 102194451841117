import { SVGProps } from "react";

export const ProjectArrow = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="19"
      height="16"
      viewBox="0 0 19 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M1.18575 6.97245C0.633467 6.97245 0.185751 7.42017 0.185751 7.97245C0.185751 8.52474 0.633466 8.97245 1.18575 8.97245L1.18575 6.97245ZM18.5428 8.67956C18.9334 8.28903 18.9334 7.65587 18.5428 7.26535L12.1789 0.901384C11.7884 0.51086 11.1552 0.51086 10.7647 0.901384C10.3741 1.29191 10.3741 1.92507 10.7647 2.3156L16.4215 7.97245L10.7647 13.6293C10.3741 14.0198 10.3741 14.653 10.7647 15.0435C11.1552 15.434 11.7884 15.434 12.1789 15.0435L18.5428 8.67956ZM1.18575 8.97245H17.8357L17.8357 6.97245H1.18575L1.18575 8.97245Z"
        fill="white"
      />
    </svg>
  );
};

export default ProjectArrow;
