import { motion } from "framer-motion";
import styled from "styled-components";

export const StyledCircle = styled(motion.div)`
  position: absolute;
  width: 100%;
  height: 100%;
  top: -5%;
  left: -5%;

  svg {
    position: absolute;
  }
`;
