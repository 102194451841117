import * as Styled from "./MenuIcon.styles";

interface MenuIconProps {
  isOpen?: boolean;
}

export const MenuIcon = ({ isOpen }: MenuIconProps) => {
  return (
    <Styled.Icon $isOpen={isOpen}>
      <Styled.IconRow>
        <Styled.IconLine $isOpen={isOpen} $variant="lt" />
        <Styled.IconLine $isOpen={isOpen} $variant="rt" />
      </Styled.IconRow>
      <Styled.IconRow>
        <Styled.IconLine $isOpen={isOpen} $variant="lb" />
        <Styled.IconLine $isOpen={isOpen} $variant="rb" />
      </Styled.IconRow>
    </Styled.Icon>
  );
};

export default MenuIcon;
