import { useEffect } from "react";
import { useHashLink } from "./useHashLink";
import { useHashes } from "./useHashes";

/**
 * Instant navigate to hash on load, if present in the URL.
 */
export const useTeleportToHashOnLoad = () => {
  const { onClickHashLink } = useHashLink();

  const hashes = useHashes();

  useEffect(() => {
    // Don't scroll to the hash if user is just reloading the page.
    if (sessionStorage.getItem("hasLoaded")) {
      return;
    }

    sessionStorage.setItem("hasLoaded", "true");

    const hash = window.location.hash;
    const hashObj = hashes?.[hash.replace("#", "") as keyof typeof hashes];
    if (hashObj) {
      onClickHashLink({ ...hashObj, isSmooth: false });
    }
  }, [hashes, onClickHashLink]);
};
