import styled from "styled-components";

export const Wrapper = styled.div<{ $isOpen: boolean }>`
  display: flex;
  align-items: center;
  flex: 1;

  ${({ $isOpen }) =>
    $isOpen &&
    `
    cursor: pointer;
  `}
`;

export const ButtonWrapper = styled.div``;

export const TextWrapper = styled.div`
  padding-left: 21px;
`;
