import * as Styled from "./VideoTextMobile.styles";

const VideoTextMobile = () => {
  return (
    <Styled.Wrapper>
      <Styled.VideoTextRow>
        <Styled.Typography color="white">Innovative</Styled.Typography>
      </Styled.VideoTextRow>
      <Styled.VideoTextRow className="solutionsFor">
        <Styled.Typography color="hitPink">solutions</Styled.Typography>
        <Styled.Typography color="white">for</Styled.Typography>
      </Styled.VideoTextRow>
      <Styled.VideoTextRow>
        <Styled.Typography color="ebonyClay">your</Styled.Typography>
        <Styled.Typography color="ebonyClay">business</Styled.Typography>
      </Styled.VideoTextRow>
    </Styled.Wrapper>
  );
};

export default VideoTextMobile;
