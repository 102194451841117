import { generateMedia } from "styled-media-query";
import { rgba } from "polished";

export const colors = {
  black: "#000",
  white: "#fff",
  hitPink: "#ffa775",
  mineShaft: "#2d2d2d",
  silver: "#c7c7c7",
  gray: "#838383",
  tundora: "#424242",
  woodsmoke: "#111112",
  ebonyClay: "#232635",
  mercury: "#e6e6e6",
  scorpion: "#5f5f5f40",
  scorpionSolid: "#181818",
  alto: "#d9d9d9",
  alto2: "#9D9D9D",
  border: "",
  shaderBg: "#25283c",
};

colors.border = rgba(colors.white, 0.2);

export const media = generateMedia({
  xs: "450px",
  xSm: "576px",
  sm: "768px",
  md: "1100px",
  xmd: "1352px",
  lg: "1440px",
  xl: "1920px",
});

export const border = `1px solid ${colors.border}`;
