import { useState } from "react";

import CaseStudy, { CaseStudyProps } from "components/case-study/CaseStudy";

import { DesktopCaseStudies } from "./CaseStudies.mock";

import * as Styled from "./CaseStudies.styles";
import { Plus } from "sections/services/Services.styles";

export const CaseStudiesDesktop = () => {
  const [currentSlide, setCurrentSlide] = useState<CaseStudyProps>(
    DesktopCaseStudies[0]
  );
  const handleSlideChange = (slide: number) => {
    setCurrentSlide(DesktopCaseStudies[slide]);
  };

  return (
    <Styled.DesktopWrapper>
      <Plus $isTopCenter />
      {currentSlide && (
        <CaseStudy
          href={currentSlide.href}
          id={currentSlide.id}
          imageSrc={currentSlide.imageSrc}
          imageCss={currentSlide.imageCss}
          imageWrapperCss={currentSlide.imageWrapperCss}
          title={currentSlide.title}
          description={currentSlide.description}
          icon={currentSlide.icon}
          desktopSlideChange={handleSlideChange}
          desktopMaxSlides={DesktopCaseStudies.length}
        />
      )}
    </Styled.DesktopWrapper>
  );
};

export default CaseStudiesDesktop;
