import { motion } from "framer-motion";
import styled from "styled-components";
import { colors } from "styles/variables";

export const Like = styled(motion.div)`
  display: flex;
  gap: 38px;
  align-items: center;

  .Like-text {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }

  .Like-likes {
    font-weight: 600;
    font-size: 22px;
    color: ${colors.white};
  }

  .Like-description {
    font-weight: 400;
    font-size: 15px;
  }
`;
