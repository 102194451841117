import { css, styled } from "styled-components";
import { colors } from "styles/variables";
import { Variants, Colors, Weight } from "./Typography.types";
import { sizes } from "./Typography.constants";

export const Typography = styled.p<{
  $variant: Variants;
  $color: Colors;
  $weight: Weight;
  $letterSpacing: number;
  $lineHeight?: string;
  $isUppercase?: boolean;
  $isUnderline?: boolean;
  // https://stackoverflow.com/questions/21612058/letter-spacing-wrong-text-center-alignment
  $fixIndent?: boolean;
  $fixIndentValue: number;
}>`
  transition: color 0.2s ease-in-out;
  font-size: ${({ $variant }) => $variant && sizes[$variant]};
  color: ${({ $color }) => $color && colors[$color]};
  font-weight: ${({ $weight }) => $weight};
  letter-spacing: ${({ $letterSpacing }) => `${$letterSpacing}px`};
  line-height: ${({ $lineHeight }) => ($lineHeight ? $lineHeight : 1.2)};

  ${({ $fixIndent, $letterSpacing, $fixIndentValue }) =>
    $fixIndent && $letterSpacing && $letterSpacing < 0
      ? `padding-right: ${-$letterSpacing + $fixIndentValue}px;`
      : `padding-left: ${$letterSpacing + $fixIndentValue}px;`}

  ${({ $isUppercase }) =>
    $isUppercase &&
    css`
      text-transform: uppercase;
    `}

  ${({ $isUnderline }) =>
    $isUnderline &&
    css`
      text-decoration: underline;
    `}
`;
