/**
 * - Register video to loading context
 * - Animate video on load
 */
export const handleVideoRef = (
  ref: React.ForwardedRef<HTMLVideoElement>,
  _ref: HTMLVideoElement | null,
  registerVideo: (video: HTMLVideoElement) => void
) => {
  if (ref && typeof ref === "function") {
    ref(_ref);
  } else if (ref && "current" in ref) {
    ref.current = _ref;
  }
  if (_ref) {
    registerVideo(_ref);
  }
};
