import styled from "styled-components";
import { border, media } from "styles/variables";

export const Wrapper = styled.div`
  display: flex;

  ${media.greaterThan("md")`  
    border-top: ${border};
    width: 100%;
    align-items: center;
    justify-content: center;
  `}
`;

export const Container = styled.div`
  width: 100%;

  ${media.greaterThan("md")`  
    max-width: 1920px;
    padding: 150px 50px 100px;
    display: flex;
    flex-direction: column-reverse;
  `}
`;

export const HeaderWrapper = styled.div`
  display: flex;
  text-align: center;
  justify-content: center;
`;

export const InfoWrapper = styled.div`
  position: relative;
  display: flex;
  margin-top: 70px;
  padding: 0 42px 0;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  ${media.greaterThan("md")`  
    padding: 0;
    margin-top: 0;
    flex-direction: row;
    justify-content: space-between;
  `}
`;

export const TitleWrapper = styled.div`
  max-width: 600px;
  display: flex;
  justify-content: center;

  ${media.greaterThan("md")`  
    max-width: 100%;
    padding-top: 90px;
  `}
`;

export const SocialsWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 92px;
  margin-top: 42px;

  ${media.greaterThan("md")`  
    padding-bottom: 0;
    margin-top: 0;
    width: 33%;
    justify-content: flex-end;
  `}
`;

export const LogoWrapper = styled.div`
  display: none;

  ${media.greaterThan("md")`
    display: flex;
    width: 33%;
    justify-content: center;
  `}
`;

export const InfoDesktopWrapper = styled.div`
  ${media.greaterThan("md")`
    display: flex;
    width: 33%;
  `}
`;
