import { sizes } from "components/typography/Typography.constants";
import styled, { css } from "styled-components";
import { colors } from "styles/variables";

export const MobileLink = styled.a<{ $isCurrent?: boolean }>`
  color: ${colors.white};
  font-size: ${sizes.mobileMenuLink};
  font-weight: 600;
  line-height: 1;
  letter-spacing: 3px;

  ${({ $isCurrent }) =>
    $isCurrent &&
    css`
      color: ${colors.hitPink};
    `};
`;

export const MobileLinkButton = styled(MobileLink)`
  padding: 0;
  text-align: left;
`;
