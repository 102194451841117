import { SVGProps } from "react";

export const LinkedInIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M17.565 21.347H20.9529C21.0757 21.347 21.1935 21.2983 21.2803 21.2115C21.3671 21.1247 21.4159 21.0069 21.4159 20.8842L21.4177 13.7261C21.4177 9.98473 20.6115 7.10897 16.2393 7.10897C14.5773 7.04716 13.01 7.90393 12.1642 9.33458C12.1601 9.34153 12.1538 9.34693 12.1463 9.34997C12.1388 9.353 12.1305 9.3535 12.1227 9.35139C12.1149 9.34928 12.108 9.34467 12.103 9.33827C12.0981 9.33187 12.0954 9.32403 12.0953 9.31594V7.91736C12.0953 7.79456 12.0465 7.6768 11.9597 7.58997C11.8729 7.50314 11.7551 7.45436 11.6323 7.45436H8.41719C8.2944 7.45436 8.17663 7.50314 8.0898 7.58997C8.00298 7.6768 7.9542 7.79456 7.9542 7.91736V20.8835C7.9542 21.0063 8.00298 21.124 8.0898 21.2108C8.17663 21.2977 8.2944 21.3465 8.41719 21.3465H11.8049C11.9277 21.3465 12.0455 21.2977 12.1323 21.2108C12.2191 21.124 12.2679 21.0063 12.2679 20.8835V14.4741C12.2679 12.6619 12.6117 10.9068 14.8585 10.9068C17.0733 10.9068 17.102 12.9805 17.102 14.5915V20.884C17.102 21.0068 17.1508 21.1246 17.2376 21.2114C17.3244 21.2983 17.4422 21.347 17.565 21.347ZM0.583008 3.05223C0.583008 4.42547 1.71352 5.5554 3.08687 5.5554C4.45988 5.55528 5.58969 4.42466 5.58969 3.05165C5.58946 1.67865 4.45953 0.548828 3.08641 0.548828C1.71294 0.548828 0.583008 1.67899 0.583008 3.05223ZM1.3885 21.347H4.78073C4.90352 21.347 5.02129 21.2983 5.10812 21.2114C5.19494 21.1246 5.24372 21.0068 5.24372 20.884V7.91736C5.24372 7.79456 5.19494 7.6768 5.10812 7.58997C5.02129 7.50314 4.90352 7.45436 4.78073 7.45436H1.3885C1.26571 7.45436 1.14794 7.50314 1.06111 7.58997C0.974286 7.6768 0.925507 7.79456 0.925507 7.91736V20.884C0.925507 21.0068 0.974286 21.1246 1.06111 21.2114C1.14794 21.2983 1.26571 21.347 1.3885 21.347Z"
        fill="white"
      />
    </svg>
  );
};

export default LinkedInIcon;
