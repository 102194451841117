import { useScreenContext } from "context/ScreenContext";
import ServicesDesktop from "./ServicesDesktop";
import ServicesMobile from "./ServicesMobile";

export const Services = () => {
  const { isDesktop } = useScreenContext();
  return <>{isDesktop ? <ServicesDesktop /> : <ServicesMobile />}</>;
};

export default Services;
