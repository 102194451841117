import { Button } from "components/button/Button";
import { motion } from "framer-motion";
import { useState } from "react";
import * as Styled from "./SwitchImageButtons.styles";
import { useScreenContext } from "context/ScreenContext";

type SwitchImageButtonsProps = {
  handleNextClick: () => void;
  handlePrevClick: () => void;
  children?: React.ReactNode;
};

const SwitchImageButtons = ({
  handleNextClick,
  handlePrevClick,
  children,
}: SwitchImageButtonsProps) => {
  const [moveVariant, setMoveVariant] = useState<
    "moveRight" | "moveLeft" | "initial"
  >("initial");

  const { isDesktopxmd } = useScreenContext();

  return (
    <Styled.ImageButtonsWrapper>
      {children}
      <motion.div
        className="left"
        initial={{ x: !isDesktopxmd ? -50 : -200 }}
        whileInView={{ x: 0, transition: { duration: 0.7 } }}
      >
        <motion.div
          animate={moveVariant === "moveLeft" ? "moveLeft" : "initial"}
          variants={{
            initial: {
              x: 0,
            },
            moveLeft: {
              scale: [
                1, 0.8, 0.8, 0.8, 0.8, 0.8, 0.8, 0.8, 0.8, 0.8, 0.8, 0.8, 0.8,
                1,
              ],
              x: [0, 0, -20, 0],
              transition: {
                duration: 0.6,
              },
            },
          }}
          onAnimationComplete={() => {
            setMoveVariant("initial");
          }}
        >
          <Button
            ariaLabel="see previous highlighted project"
            variant="arrowHighlight"
            direction="left"
            onClick={() => {
              setMoveVariant("moveLeft");
              handlePrevClick();
            }}
          />
        </motion.div>
        <motion.div
          className="left-line"
          animate={moveVariant === "moveLeft" ? "moveLeft" : "initial"}
          variants={{
            initial: {
              x: 0,
            },
            moveLeft: {
              x: [0, 0, -20, 0],
              transition: {
                duration: 0.6,
              },
            },
          }}
        />
      </motion.div>
      <motion.div
        className="right"
        initial={{ x: !isDesktopxmd ? 50 : 200 }}
        whileInView={{ x: 0, transition: { duration: 0.7 } }}
      >
        <motion.div
          animate={moveVariant === "moveRight" ? "moveRight" : undefined}
          variants={{
            initial: {
              x: 0,
            },
            moveRight: {
              scale: [
                1, 0.8, 0.8, 0.8, 0.8, 0.8, 0.8, 0.8, 0.8, 0.8, 0.8, 0.8, 0.8,
                1,
              ],
              x: [0, 0, 20, 0],
              transition: {
                duration: 0.6,
              },
            },
          }}
          onAnimationComplete={() => {
            setMoveVariant("initial");
          }}
        >
          <Button
            ariaLabel="see next highlighted project"
            variant="arrowHighlight"
            direction="right"
            onClick={() => {
              setMoveVariant("moveRight");
              handleNextClick();
            }}
          />
        </motion.div>
        <motion.div
          className="right-line"
          animate={moveVariant === "moveRight" ? "moveRight" : "initial"}
          variants={{
            initial: {
              x: 0,
            },
            moveRight: {
              x: [0, 0, 40, 0],
              transition: {
                duration: 0.6,
              },
            },
          }}
        />
      </motion.div>
    </Styled.ImageButtonsWrapper>
  );
};

export default SwitchImageButtons;
