import { SVGLoader } from "three/examples/jsm/loaders/SVGLoader";
import * as THREE from "three";
import krukoLogo from "assets/logos/kruko-logo.svg";

export const krukoLogoBackground = new Promise<
  THREE.Group<THREE.Object3DEventMap>
>((resolve) =>
  new SVGLoader().load(krukoLogo, (data) => {
    const paths = data.paths;
    const group = new THREE.Group();

    for (let i = 0; i < paths.length; i++) {
      const path = paths[i];

      const material = new THREE.MeshLambertMaterial({
        color: path.color,
        side: THREE.DoubleSide,
        depthWrite: false,
        opacity: 0.25,
        transparent: true,
      });

      const shapes = SVGLoader.createShapes(path);

      for (let j = 0; j < shapes.length; j++) {
        const shape = shapes[j];
        const geometry = new THREE.ShapeGeometry(shape);
        const mesh = new THREE.Mesh(geometry, material);
        group.add(mesh);
      }
    }

    resolve(group);
  })
);
