import { createContext, useContext, useMemo, useRef, useState } from "react";
import { isInViewportIntersection } from "utils/isInViewport";

const LoadingContext = createContext({
  registerVideo: (_: HTMLVideoElement) => {},
  videoProgress: 0,
  isCanvasLoaded: false,
  setCanvasIsLoaded: (_: boolean) => {},
});

export const LoadingContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const loadedVideos = useRef(0);
  const totalVideos = useRef(0);

  const [videoProgress, setVideoProgress] = useState(0);
  const [isCanvasLoaded, setCanvasIsLoaded] = useState(false);

  const registerVideo = (video: HTMLVideoElement) => {
    video.style.opacity = "0";

    let intersectRef = false;

    isInViewportIntersection(video, (v) => {
      if (v) {
        totalVideos.current += 1;
        intersectRef = true;
      }
    });

    video.addEventListener("loadeddata", () => {
      if (totalVideos.current === 0) {
        setVideoProgress(1);
        return;
      }

      if (intersectRef) {
        loadedVideos.current += 1;
        requestAnimationFrame(() => {
          video.animate([{ opacity: 1 }], {
            duration: 1000,
            fill: "forwards",
            easing: "ease-in-out",
          });
        });
        setVideoProgress(
          Math.min(loadedVideos.current, totalVideos.current) /
            totalVideos.current
        );
      } else {
        isInViewportIntersection(video, (v) => {
          if (!v) {
            video.style.opacity = "1";
          } else {
            totalVideos.current += 1;
            loadedVideos.current += 1;
            requestAnimationFrame(() => {
              video.animate([{ opacity: 1 }], {
                duration: 1000,
                fill: "forwards",
                easing: "ease-in-out",
              });
            });
            setVideoProgress(
              Math.min(loadedVideos.current, totalVideos.current) /
                totalVideos.current
            );
          }
        });
      }
    });
  };

  const contextValue = useMemo(
    () => ({
      registerVideo,
      videoProgress,
      isCanvasLoaded,
      setCanvasIsLoaded,
    }),
    [videoProgress, isCanvasLoaded, setCanvasIsLoaded]
  );

  return (
    <LoadingContext.Provider value={contextValue}>
      {children}
    </LoadingContext.Provider>
  );
};

export const useLoadingContext = () => {
  return useContext(LoadingContext);
};
