import { SVGProps } from "react";

export const DBMIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M0.0605469 37.2828H1.12112C1.96957 37.2828 2.58235 37.8542 2.58235 38.6086C2.58235 39.3629 1.99314 39.9344 1.12112 39.9344H0.0605469V37.2828ZM1.12112 39.3858C1.61605 39.3858 1.94601 39.0658 1.94601 38.5857C1.94601 38.1057 1.61605 37.7857 1.12112 37.7857H0.649753V39.3858H1.12112Z"
        fill="white"
      />
      <path
        d="M3.85503 37.2599H4.3971L5.57552 39.9344H4.93917L4.67992 39.3401H3.52508L3.26583 39.9344H2.65305L3.85503 37.2599ZM4.46781 38.8143L4.11429 37.9457L3.76076 38.8143H4.46781Z"
        fill="white"
      />
      <path
        d="M5.45768 37.8085V37.2828H7.72023V37.8085H6.89534V39.9115H6.30613V37.8085H5.45768Z"
        fill="white"
      />
      <path
        d="M8.75723 37.2599H9.2993L10.4777 39.9344H9.84137L9.58212 39.3401H8.42727L8.16802 39.9344H7.55525L8.75723 37.2599ZM9.39357 38.8143L9.04005 37.9457L8.68653 38.8143H9.39357Z"
        fill="white"
      />
      <path
        d="M12.0804 37.2828H13.353C13.6594 37.2828 13.9187 37.3742 14.0601 37.5114C14.1779 37.6256 14.2486 37.7857 14.2486 37.9685C14.2486 38.2657 14.0837 38.4257 13.8951 38.54C14.2015 38.6543 14.4136 38.8372 14.4136 39.18C14.4136 39.6601 14.0129 39.9115 13.4002 39.9115H12.1039V37.2828H12.0804ZM13.2116 38.3343C13.4709 38.3343 13.6359 38.2428 13.6359 38.06C13.6359 37.8771 13.4944 37.7857 13.2352 37.7857H12.646V38.3343H13.2116ZM13.3766 39.4086C13.6359 39.4086 13.8008 39.3172 13.8008 39.1115C13.8008 38.9286 13.6594 38.8143 13.353 38.8143H12.6696V39.3858H13.3766V39.4086Z"
        fill="white"
      />
      <path
        d="M14.9085 37.2828H15.4977V39.9344H14.9085V37.2828Z"
        fill="white"
      />
      <path
        d="M16.2048 37.2828H17.2654C18.1138 37.2828 18.7266 37.8542 18.7266 38.6086C18.7266 39.3629 18.1374 39.9344 17.2654 39.9344H16.2048V37.2828ZM17.2654 39.3858C17.7603 39.3858 18.0903 39.0658 18.0903 38.5857C18.0903 38.1057 17.7603 37.7857 17.2654 37.7857H16.794V39.3858H17.2654Z"
        fill="white"
      />
      <path
        d="M21.0599 37.2828L21.7669 38.4028L22.4739 37.2828H23.1103V39.9344H22.5211V38.1971L21.7433 39.3172H21.7198L20.9656 38.1971V39.9115H20.3764V37.2828H21.0599Z"
        fill="white"
      />
      <path
        d="M24.7365 37.2599H25.2786L26.457 39.9344H25.8206L25.5614 39.3401H24.4065L24.1473 39.9344H23.5345L24.7365 37.2599ZM25.3728 38.8143L25.0193 37.9457L24.6658 38.8143H25.3728Z"
        fill="white"
      />
      <path
        d="M26.5984 38.6086C26.5984 37.8542 27.1876 37.237 28.0125 37.237C28.531 37.237 28.8374 37.3971 29.0966 37.6485L28.7195 38.0828C28.5074 37.9 28.2953 37.7857 28.0125 37.7857C27.5411 37.7857 27.2112 38.1514 27.2112 38.6086C27.2112 39.0658 27.5411 39.4315 28.0125 39.4315C28.3189 39.4315 28.5074 39.3172 28.7195 39.1115L29.0966 39.4772C28.8138 39.7744 28.5074 39.9572 27.9889 39.9572C27.1876 39.9572 26.5984 39.3629 26.5984 38.6086Z"
        fill="white"
      />
      <path
        d="M29.5916 37.2828H30.1808V38.3343H31.2885V37.2828H31.8777V39.9344H31.2885V38.8829H30.1808V39.9344H29.5916V37.2828Z"
        fill="white"
      />
      <path
        d="M32.5847 37.2828H33.1739V39.9344H32.5847V37.2828Z"
        fill="white"
      />
      <path
        d="M34.3995 37.2828L35.6722 38.9057V37.2828H36.2614V39.9344H35.7429L34.4231 38.2428V39.9344H33.8338V37.2828H34.3995Z"
        fill="white"
      />
      <path
        d="M36.9449 37.2828H38.9953V37.8085H37.5341V38.3343H38.8303V38.86H37.5341V39.4086H39.0189V39.9344H36.9449V37.2828Z"
        fill="white"
      />
      <path
        d="M27.2347 23.0417H25.8206V20.0472C25.8206 18.5842 27.0462 17.3956 28.5546 17.3956H31.642C32.3726 17.3956 32.9618 16.8241 32.9618 16.1155V14.9954H34.3759V16.1155C34.3759 17.5784 33.1504 18.7671 31.642 18.7671H28.5546C27.8239 18.7671 27.2347 19.3386 27.2347 20.0472V23.0417Z"
        fill="white"
      />
      <path
        d="M33.6453 16.0698C32.1134 16.0698 30.8642 14.8582 30.8642 13.3724C30.8642 11.8866 32.1134 10.6751 33.6453 10.6751C35.1772 10.6751 36.4264 11.8866 36.4264 13.3724C36.4264 14.8811 35.1772 16.0698 33.6453 16.0698ZM33.6453 12.0695C32.8911 12.0695 32.3019 12.6638 32.3019 13.3724C32.3019 14.1039 32.9147 14.6754 33.6453 14.6754C34.3759 14.6754 34.9887 14.081 34.9887 13.3724C34.9887 12.6638 34.3759 12.0695 33.6453 12.0695Z"
        fill="white"
      />
      <path
        d="M11.7268 23.0417V20.0472C11.7268 19.3386 11.1376 18.7671 10.407 18.7671H7.34314C5.83477 18.7671 4.60922 17.5784 4.60922 16.1155V14.9954H6.02331V16.1155C6.02331 16.8241 6.61252 17.3956 7.34314 17.3956H10.4306C11.9389 17.3956 13.1645 18.5842 13.1645 20.0472V23.0417H11.7268Z"
        fill="white"
      />
      <path
        d="M5.31627 16.0698C3.78433 16.0698 2.53521 14.8582 2.53521 13.3724C2.53521 11.8866 3.78433 10.6751 5.31627 10.6751C6.8482 10.6751 8.09732 11.8866 8.09732 13.3724C8.09732 14.8811 6.8482 16.0698 5.31627 16.0698ZM5.31627 12.0695C4.56208 12.0695 3.97288 12.6638 3.97288 13.3724C3.97288 14.1039 4.58565 14.6754 5.31627 14.6754C6.07045 14.6754 6.65966 14.081 6.65966 13.3724C6.65966 12.6638 6.07045 12.0695 5.31627 12.0695Z"
        fill="white"
      />
      <path
        d="M20.1878 6.67478H18.7737V18.8128H20.1878V6.67478Z"
        fill="white"
      />
      <path
        d="M20.1878 20.6644H18.7737V28.1849H20.1878V20.6644Z"
        fill="white"
      />
      <path
        d="M19.4808 7.26911C17.9488 7.26911 16.6997 6.05759 16.6997 4.57177C16.6997 3.08594 17.9488 1.87442 19.4808 1.87442C21.0127 1.87442 22.2618 3.08594 22.2618 4.57177C22.2618 6.05759 21.0127 7.26911 19.4808 7.26911ZM19.4808 3.26881C18.7266 3.26881 18.1374 3.86314 18.1374 4.57177C18.1374 5.30325 18.7502 5.87472 19.4808 5.87472C20.2114 5.87472 20.8242 5.28039 20.8242 4.57177C20.8242 3.86314 20.235 3.26881 19.4808 3.26881Z"
        fill="white"
      />
      <path
        d="M16.5819 14.4011H15.1678V24.6418H16.5819V14.4011Z"
        fill="white"
      />
      <path
        d="M16.5819 26.0134H15.1678V27.3849H16.5819V26.0134Z"
        fill="white"
      />
      <path
        d="M23.6052 24.4132H22.1911V25.7848H23.6052V24.4132Z"
        fill="white"
      />
      <path
        d="M19.4808 30.9051C14.6964 30.9051 10.7841 27.6821 10.7841 23.0417H9.20503C9.20503 28.5507 13.8008 32.4595 19.4808 32.4595C25.1607 32.4595 29.7565 28.5507 29.7565 23.0417H28.1775C28.1775 27.6821 24.2651 30.9051 19.4808 30.9051Z"
        fill="white"
      />
      <path
        d="M8.82794 6.05759H7.50811V7.42912H8.82794C10.4306 7.42912 11.7268 8.68636 11.7268 10.2408V15.9783H13.1409V10.2408C13.1409 7.93202 11.2083 6.05759 8.82794 6.05759Z"
        fill="white"
      />
      <path
        d="M5.3634 9.4407C3.83147 9.4407 2.58235 8.22918 2.58235 6.74336C2.58235 5.25753 3.83147 4.04601 5.3634 4.04601C6.89534 4.04601 8.14446 5.25753 8.14446 6.74336C8.14446 8.22918 6.89534 9.4407 5.3634 9.4407ZM5.3634 5.4404C4.60922 5.4404 4.02001 6.03473 4.02001 6.74336C4.02001 7.47484 4.63279 8.04631 5.3634 8.04631C6.09402 8.04631 6.70679 7.45198 6.70679 6.74336C6.73036 6.01187 6.11759 5.4404 5.3634 5.4404Z"
        fill="white"
      />
      <path
        d="M15.427 6.53763L13.4473 4.13745C13.1409 4.50319 12.7167 4.75464 12.2453 4.86893L14.3193 7.3834C14.885 8.06917 15.1914 8.91495 15.1914 9.78358V12.2295C15.6392 11.978 16.1105 11.7723 16.6055 11.5894V9.76072C16.6055 8.61778 16.1812 7.47484 15.427 6.53763Z"
        fill="white"
      />
      <path
        d="M11.6561 5.39469C10.1242 5.39469 8.87507 4.18317 8.87507 2.69734C8.87507 1.21152 10.1242 0.0228585 11.6561 0.0228585C13.1881 0.0228585 14.4372 1.23438 14.4372 2.7202C14.4372 4.20603 13.1881 5.39469 11.6561 5.39469ZM11.6561 1.39439C10.9019 1.39439 10.3127 1.98872 10.3127 2.69734C10.3127 3.42883 10.9255 4.0003 11.6561 4.0003C12.3867 4.0003 12.9995 3.40597 12.9995 2.69734C12.9995 1.98872 12.3867 1.39439 11.6561 1.39439Z"
        fill="white"
      />
      <path
        d="M25.3728 4.16031L23.3931 6.56049C22.6389 7.47484 22.2147 8.64064 22.2147 9.80644V23.0417H23.6288V9.80644C23.6288 8.93781 23.9352 8.09203 24.5008 7.40626L26.5277 4.96037C26.0563 4.80036 25.6557 4.52605 25.3728 4.16031Z"
        fill="white"
      />
      <path
        d="M31.6656 6.05759H30.1336C27.7532 6.05759 25.7971 7.93202 25.7971 10.2636V16.0012H27.2112V10.2408C27.2112 8.68636 28.5074 7.42912 30.1101 7.42912H31.642V6.05759H31.6656Z"
        fill="white"
      />
      <path
        d="M33.7867 9.4407C32.2548 9.4407 31.0057 8.22918 31.0057 6.74336C31.0057 5.25753 32.2548 4.04601 33.7867 4.04601C35.3186 4.04601 36.5678 5.25753 36.5678 6.74336C36.5678 8.22918 35.3186 9.4407 33.7867 9.4407ZM33.7867 5.4404C33.0325 5.4404 32.4433 6.03473 32.4433 6.74336C32.4433 7.47484 33.0561 8.04631 33.7867 8.04631C34.5409 8.04631 35.1301 7.45198 35.1301 6.74336C35.1537 6.01187 34.5409 5.4404 33.7867 5.4404Z"
        fill="white"
      />
      <path
        d="M27.3054 5.39469C25.7735 5.39469 24.5244 4.18317 24.5244 2.69734C24.5244 1.21152 25.7735 0 27.3054 0C28.8374 0 30.0865 1.21152 30.0865 2.69734C30.0865 4.18317 28.8374 5.39469 27.3054 5.39469ZM27.3054 1.39439C26.5513 1.39439 25.962 1.98872 25.962 2.69734C25.962 3.42883 26.5748 4.0003 27.3054 4.0003C28.0361 4.0003 28.6488 3.40597 28.6488 2.69734C28.6724 1.98872 28.0596 1.39439 27.3054 1.39439Z"
        fill="white"
      />
    </svg>
  );
};

export default DBMIcon;
