import * as Styled from "./MobileLink.styles";
import {
  MobileLinkButtonProps,
  MobileLinkExternalProps,
  MobileLinkHashProps,
} from "./MobileLink.types";

export const MobileLinkHash = ({
  text,
  onClick,
  isCurrent,
  hash,
}: MobileLinkHashProps) => {
  return (
    <Styled.MobileLink
      href={hash.href + "#" + hash.hash}
      onClick={(e) => {
        e.preventDefault();
        onClick(hash);
      }}
      $isCurrent={isCurrent}
    >
      {text}
    </Styled.MobileLink>
  );
};

export const MobileLinkExternal = ({
  text,
  href,
  isCurrent,
}: MobileLinkExternalProps) => {
  return (
    <Styled.MobileLink href={href} $isCurrent={isCurrent}>
      {text}
    </Styled.MobileLink>
  );
};

export const MobileLinkButton = ({ onClick, text }: MobileLinkButtonProps) => {
  return (
    <Styled.MobileLinkButton as="button" onClick={onClick}>
      {text}
    </Styled.MobileLinkButton>
  );
};
