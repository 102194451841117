import { SVGProps } from "react";

export const ArrowButtonIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="19"
      viewBox="0 0 24 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M22.0088 8.52246C22.6991 8.52246 23.2588 9.0821 23.2588 9.77246C23.2588 10.4628 22.6991 11.0225 22.0088 11.0225L22.0088 8.52246ZM1.1249 10.6563C0.636751 10.1682 0.636751 9.37673 1.1249 8.88858L9.07986 0.933627C9.56801 0.445472 10.3595 0.445472 10.8476 0.933627C11.3358 1.42178 11.3358 2.21324 10.8476 2.70139L3.77656 9.77246L10.8476 16.8435C11.3358 17.3317 11.3358 18.1231 10.8476 18.6113C10.3595 19.0995 9.56801 19.0995 9.07986 18.6113L1.1249 10.6563ZM22.0088 11.0225L2.00879 11.0225L2.00879 8.52246L22.0088 8.52246L22.0088 11.0225Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default ArrowButtonIcon;
