import { rgba } from "polished";
import styled, { keyframes } from "styled-components";
import { colors, media } from "styles/variables";

const shake = keyframes`
  0%, 100% { transform: translateX(0); }
  10%, 30%, 50%, 70%, 90% { transform: translateX(-5px); }
  20%, 40%, 60%, 80% { transform: translateX(5px); }
`;

export const StyledLink = styled.a`
  text-decoration: none;
  border-bottom: 1px solid ${rgba(colors.mercury, 0.15)};

  &:last-of-type {
    border-bottom: none;
  }
`;

export const ArrowWrapper = styled.div`
  display: none;

  ${media.greaterThan("md")`
    display: flex;
    transition: transform 0.4s ease;
  `}
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 0;

  ${media.greaterThan("md")`
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 30px 0 25px;

    &:hover ${ArrowWrapper} {
      ${media.greaterThan("md")`
        animation: ${shake} 4s ease infinite;
      `}
    }
  `}
`;

export const TitleWrapper = styled.div`
  padding-bottom: 15px;
  width: 200px;

  ${media.greaterThan("md")`
    padding-bottom: 0;
  `}
`;

export const Description = styled.div`
  ${media.greaterThan("md")`
    width: 300px;
  `}
`;
