import { Canvas } from "@react-three/fiber";
import { isMobileBrowser } from "constants/browser";
import { MOBILE_BAR_INCREASE } from "constants/constants";
import { useScreenContext } from "context/ScreenContext";
import { useEffect, useRef, useState } from "react";
import { Group, Mesh, ReinhardToneMapping } from "three";
import Background from "./Background";
import { useLoadingContext } from "context/LoadingContext";

export function BackgroundExperience() {
  const { initialDimensions, isDesktop } = useScreenContext();
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const { setCanvasIsLoaded } = useLoadingContext();
  const meshRef = useRef<Mesh>(null);

  const [isMeshLoaded, setIsMeshLoaded] = useState(false);

  const [shapes, setShapes] = useState<Group>();

  const showCanvas = isDesktop ? shapes && isMeshLoaded : isMeshLoaded;

  useEffect(() => {
    setCanvasIsLoaded(!!showCanvas);
  }, [showCanvas, setCanvasIsLoaded]);

  return (
    <Canvas
      gl={{
        toneMapping: ReinhardToneMapping,
        toneMappingExposure: 1.5,
      }}
      ref={canvasRef}
      orthographic
      className="background-experience"
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        height: isMobileBrowser
          ? initialDimensions[1] + MOBILE_BAR_INCREASE
          : "auto",
        opacity: showCanvas ? 1 : 0,
        pointerEvents: 'none'
      }}
    >
      <Background
        shapes={shapes}
        setShapes={setShapes}
        meshRef={meshRef}
        setIsMeshLoaded={setIsMeshLoaded}
      />
    </Canvas>
  );
}
