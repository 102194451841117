import styled, { css, keyframes } from "styled-components";
import { colors, media } from "styles/variables";

export const Wrapper = styled.div`
  width: 100vw;
  height: 100dvh;

  display: flex;
  justify-content: center;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 0 25px;

  ${media.greaterThan("md")`
    max-width: min(90%, 1550px);
  `}
`;

export const DescriptionWrapper = styled.div`
  padding-top: 15px;
  max-width: 550px;
  display: none;

  ${media.greaterThan("md")`
    display: flex;
  `}
`;

export const TitleWrapper = styled.div`
  padding-top: 135px;
  display: flex;
  flex-direction: column;

  ${media.greaterThan("md")`
    padding-top: 70px;
    flex-direction: row;
    gap: 10px;
  `}
`;

export const Title = styled.div<{ $isUppercase?: boolean }>`
  font-size: 45px;
  font-weight: 700;
  line-height: 51px;
  color: ${colors.white};
  letter-spacing: -2px;

  ${media.greaterThan("md")`
    font-size: 70px;
    line-height: 116px;
    font-weight: 500;
  `}

  ${({ $isUppercase }) =>
    $isUppercase &&
    css`
      text-transform: uppercase;
      color: ${colors.ebonyClay};

      ${media.greaterThan("md")`
        font-weight: 800;
        color: ${colors.white};
      `}
    `}
`;

export const Projects = styled.div`
  margin-top: 67px;

  display: flex;
  flex-direction: column;
  overflow: hidden;
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  ${media.greaterThan("md")`
    margin-top: min(10vh, 100px);
    padding: 0 140px;
  `}
`;

export const FakeHeader = styled.div`
  height: 101px;
  width: 100%;
`;

const bounce = keyframes`
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-6px);
  }
  60% {
    transform: translateY(-3px);
  }
`;

export const SeeMoreChevron = styled.button<{ $isVisible: boolean }>`
  align-self: center;
  margin-bottom: 100px;
  animation: ${bounce} 1s infinite;
  opacity: ${({ $isVisible }) => ($isVisible ? 1 : 0)};
  cursor: pointer;
  transition: transform 2s ease-in-out, opacity 0.5s ease-in-out;

  &:hover {
    animation: none;
    transition: opacity 0.5s ease-out, transform 2s ease-out;
  }
`;
