export type ConsentMode = {
  necessary: boolean;
  marketing: boolean;
  analytics: boolean;
  preferences: boolean;
};

export const setConsentMode = (consent: ConsentMode) => {
  const consentMode = {
    functionality_storage: consent.necessary ? "granted" : "denied",
    security_storage: consent.necessary ? "granted" : "denied",
    ad_storage: consent.marketing ? "granted" : "denied",
    analytics_storage: consent.analytics ? "granted" : "denied",
    personalization: consent.preferences ? "granted" : "denied",
  };

  window.gtag("consent", "update", consentMode);

  localStorage.setItem("consentMode", JSON.stringify(consentMode));
};
