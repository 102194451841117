import { ReactNode } from "react";

import * as Styled from "./IconCircle.styles";

export interface IconCircleProps {
  variant?: "sm" | "md";
  icon?: ReactNode;
}

export const IconCircle = ({ variant = "sm", icon }: IconCircleProps) => {
  return <Styled.IconWrapper $variant={variant}>{icon}</Styled.IconWrapper>;
};

export default IconCircle;
