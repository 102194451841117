import * as Styled from "./Services.styles";

import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";

import "swiper/css";
import "swiper/css/pagination";

import ServiceCard from "components/service-card/ServiceCard";
import { useScreenContext } from "context/ScreenContext";

import { ServicesCardsMobile } from "./Services.mock";
import { hashIds } from "constants/hashes";
import { useSectionRefs } from "context/SectionRefContext";

export const ServicesMobile = () => {
  const { isMobile } = useScreenContext();

  const { services } = useSectionRefs();

  return (
    <Styled.ScrollPadding id={hashIds.services} ref={services}>
      <Styled.Wrapper>
        <Swiper
          spaceBetween={0}
          slidesPerView={isMobile ? 1 : 2}
          pagination={true}
          modules={[Pagination]}
        >
          {ServicesCardsMobile.map((item) => (
            <SwiperSlide key={item.id}>
              <Styled.ItemWrapper>
                {!!item.title && !!item.description && (
                  <ServiceCard
                    front={{
                      title: item.title,
                      description: item.description,
                    }}
                    icon={item.icon}
                  />
                )}
              </Styled.ItemWrapper>
            </SwiperSlide>
          ))}
          <Styled.PaginationWrapper />
        </Swiper>
      </Styled.Wrapper>
    </Styled.ScrollPadding>
  );
};

export default ServicesMobile;
