import styled from "styled-components";
import { media } from "styles/variables";
import UnstyledVideo from "components/video/Video";

export const Wrapper = styled.div`
  position: relative;

  ${media.greaterThan("sm")`
    height: 1000px;
    display: flex;
  `}

  ${media.greaterThan("md")`
    display: flex;
    justify-content: center;
  `}

  mix-blend-mode: exclusion;
`;

export const HashTarget = styled.div`
  position: absolute;
  top: -125px;
  left: 0;
  width: 1px;
  height: 1px;
  visibility: hidden;
`;

export const Container = styled.div`
  position: relative;
  display: flex;
  padding: 0 25px;
  flex-direction: column;
  z-index: 10;

  margin-top: 148px;

  ${media.greaterThan("sm")`
    display: flex;
    width: 100%;
    max-width: 1920px;
    margin: 0 50px 0;
    padding-top: 200px;
  `};

  ${media.greaterThan("md")`
    padding-top: 300px;
  `}
`;

export const CaptionWrapper = styled.div`
  padding-bottom: 50px;
  position: relative;
`;

export const TitleWrapper = styled.div`
  padding-bottom: 30px;
`;

export const DescriptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 25px;
  max-width: 350px;
  padding-bottom: 20px;

  ${media.greaterThan("md")`
    position: absolute;
    right: -24px;
    top: 380px;
  `}

  ${media.greaterThan("lg")`
    right: 0;
  `}

  ${media.greaterThan("xl")`
    margin-right: 200px;
  `}
`;

export const ImageWrapper = styled.div`
  height: 636px;
  width: 100%;

  ${media.greaterThan("sm")`
    position: absolute;
    top: 0;
    left: 0;
    height: 1000px;
    display: flex;
    justify-content: center;
  `}
`;

export const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;

  ${media.greaterThan("xl")`
    object-fit: contain;
  `}
`;

export const Video = styled(UnstyledVideo)`
  position: absolute;

  max-width: 100%;
  max-height: none;
  object-fit: cover;

  // TODO: Remove this once Piotrek Olech gives updated video, with full black background.
  filter: contrast(1.2) brightness(0.95);

  height: 65%;
  bottom: 0;

  z-index: -10;

  ${media.greaterThan("md")`
    height: 100%;
    top: 0;
    bottom: auto;
  `}
`;

export const Description = styled.div`
  p {
    text-shadow: 0.05em 0 black, 0 0.05em black, -0.05em 0 black,
      0 -0.05em black, -0.05em -0.05em black, -0.05em 0.05em black,
      0.05em -0.05em black, 0.05em 0.05em black;
  }
`;
