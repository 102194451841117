import styled from "styled-components";
import { colors, media } from "styles/variables";

export const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

export const BorderLine = styled.div`
  display: none;

  ${media.greaterThan("md")` 
    display: flex;
    width: 2px;
    transform: translateX(-100%);
    height: 76px;
    background: ${colors.hitPink};
    position: absolute;
    top: 240px;
    left: 0;
    border-radius: 100px;
    opacity: 1;
    transition: opacity 0.4s ease;
  `}
`;

export const CardContent = styled.div`
  transition: transform 0.5s ease;
  transform-style: preserve-3d;
  position: absolute;
  inset: 0;
  transform-origin: center right;
`;

export const CardWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

export const CardFaceFront = styled.div`
  position: absolute;
  inset: 0;
  padding: 30px 40px;
  display: flex;
  align-items: flex-end;

  ${media.greaterThan("sm")`
    padding-block: 10px; 
  `}
`;
