import type { HighlightedProject as HighlightedProjectType } from "sections/highlighted-projects/HighlightedProjects.mock";
import * as Styled from "./HighlightedProject.styles";
import CaseStudyIconEllipsis from "assets/caseStudyIconEllipsis";
import CaseStudyIconSpiral from "assets/caseStudyIconSpiral";
import Typography from "components/typography/Typography";

type HighlightedProjectProps = {
  project: HighlightedProjectType;
};

const HighlightedProject = ({
  project: { description, icon, imageSrc, title, href },
}: HighlightedProjectProps) => {
  return (
    <Styled.HighlightedProject as="a" href={href}>
      <Styled.Header>
        <Styled.HeaderHeading
          color="hitPink"
          weight="500"
          variant="xSm"
          letterSpacing={2}
        >
          HIGHLIGHTED PROJECT
        </Styled.HeaderHeading>
        {icon === "ellipsis" ? (
          <CaseStudyIconEllipsis width={30} height={30} />
        ) : icon === "spiral" ? (
          <CaseStudyIconSpiral width={30} height={30} />
        ) : null}
      </Styled.Header>
      <Styled.Image src={imageSrc} alt="Highlighted Project mockup" />
      <Styled.DescriptionWrapper>
        <Typography variant="captionXxl" weight="600" color="white">
          {title}
        </Typography>
        <Styled.DescriptionSmallText variant="sm" weight="400">
          {description}
        </Styled.DescriptionSmallText>
      </Styled.DescriptionWrapper>
    </Styled.HighlightedProject>
  );
};

export default HighlightedProject;
