import styled from "styled-components";
import { colors, media } from "styles/variables";
import UnstyledVideo from "components/video/Video";

export const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 357px;

  ${media.greaterThan("md")`
    height: 450px;
  `}
`;

export const FrontCard = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
  align-items: center;
`;

export const IconWrapper = styled.div`
  display: flex;
  flex: 6;
  justify-content: center;
  align-items: center;
  width: 176px;
  height: 176px;
  padding: 50px 0;
`;

export const InfoWrapper = styled.div`
  display: flex;
  flex: 4;
`;

export const LogoCard = styled.div`
  position: absolute;
  inset: 0;
  background: ${colors.hitPink};
  display: flex;
  justify-content: center;
  align-items: center;

  & > svg {
    transform: scale(1.4);
  }
`;

export const VideoWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

export const Video = styled(UnstyledVideo)`
  position: relative;
  width: 382px;
  object-fit: cover;
  z-index: 30;
  mix-blend-mode: difference;
`;
