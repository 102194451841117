import { mobileHashes } from "constants/hashes";
import {
  MobileLinkButton,
  MobileLinkExternal,
  MobileLinkHash,
} from "../mobile-link/MobileLink";
import { MobileLinkProps } from "../mobile-link/MobileLink.types";
import { useHashLink } from "hooks/useHashLink";

const MobileLinks = ({
  onClickIndexPlus,
  handleCloseMenu,
}: {
  onClickIndexPlus: () => void;
  handleCloseMenu: () => void;
}) => {
  const { onClickHashLink, pathname } = useHashLink(handleCloseMenu);

  const mobileLinks: MobileLinkProps[] = [
    {
      type: "hash",
      text: "HOME",
      hash: mobileHashes.home,
      onClick: onClickHashLink,
      isCurrent: pathname === "/",
    },
    {
      type: "hash",
      text: "SERVICES",
      hash: { ...mobileHashes.services, block: "center" },
      onClick: onClickHashLink,
    },
    {
      type: "external",
      text: "KRUKO:IO",
      href: "https://www.kruko.io/",
    },
    {
      type: "hash",
      text: "ABOUT",
      hash: mobileHashes.about,
      onClick: onClickHashLink,
    },
    {
      type: "hash",
      text: "CONTACT US",
      hash: mobileHashes["contact-us"],
      onClick: onClickHashLink,
    },
    {
      type: "button",
      text: "INDEX+",
      onClick: onClickIndexPlus,
    },
  ];

  const jsx = mobileLinks.map((link) => {
    switch (link.type) {
      case "hash":
        return <MobileLinkHash key={link.text} {...link} />;
      case "external":
        link.isCurrent = link.href === pathname;
        return <MobileLinkExternal key={link.text} {...link} />;
      case "button":
        return <MobileLinkButton key={link.text} {...link} />;
      default:
        return null;
    }
  });

  return <>{jsx}</>;
};

export default MobileLinks;
