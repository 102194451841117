import caseStudyMock from "assets/images/case-study-mock.jpg";
import caseStudyMock1 from "assets/images/case-study-mock-1.jpeg";

export type HighlightedProject = {
  id: number;
  imageSrc: string;
  title: string;
  description: string;
  icon: "spiral" | "ellipsis";
  likes: {
    platform: "Behance" | "Instagram" | "Dribble";
    likes: number;
  }[];
  href: string;
};

export const highlightedProjects: HighlightedProject[] = [
  {
    id: 0,
    imageSrc: caseStudyMock1,
    title: "Darkness never looked so good",
    description:
      "We help you push the boundaries of your imagination by creating unique designs tailored to your needs.",
    icon: "spiral",
    href: "https://www.behance.net/gallery/201253749/ULTRABOT-Design-Website",
    likes: [
      {
        platform: "Behance" as const,
        likes: 25000,
      },
      {
        platform: "Instagram" as const,
        likes: 48000,
      },
      {
        platform: "Dribble" as const,
        likes: 72000,
      },
    ],
  },
  {
    id: 1,
    imageSrc: caseStudyMock,
    title: "Perfection & creativity in every step",
    description:
      "We help you push the boundaries of your imagination by creating unique designs tailored to your needs.",
    icon: "ellipsis",
    href: "https://www.behance.net/gallery/200863177/ELIPSE-DYNAMICS",
    likes: [
      {
        platform: "Behance" as const,
        likes: 21000,
      },
      {
        platform: "Instagram" as const,
        likes: 122000,
      },
      {
        platform: "Dribble" as const,
        likes: 86000,
      },
    ],
  },
];
