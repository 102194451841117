import styled from "styled-components";
import { media } from "styles/variables";
import VideoComponent from "components/video/Video";

export const Wrapper = styled.div`
  position: relative;

  ${media.greaterThan("md")`
    display: flex;
    justify-content: center;
    padding-bottom: 150px;
  `}
`;

export const Container = styled.div`
  width: 100%;

  ${media.greaterThan("md")`  
    max-width: 1920px;
    padding: 0 50px;
    display: flex;
    flex-direction: column-reverse;
    position: relative;
    height: 500px;
  `}
`;

export const InfoWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 0 42px;
  justify-content: center;
  align-items: center;
  z-index: 20;

  ${media.greaterThan("md")`
    justify-content: center;
    align-items: flex-start;
    height: 100%;
    padding: 0 0 50px 0;
  `}
`;

export const ButtonWrapper = styled.div`
  padding-top: 34px;

  ${media.greaterThan("md")`
    padding-top: 20px;
  `}
`;

export const VideoSection = styled.div`
  width: 100%;
  height: 300px;
  position: relative;

  ${media.greaterThan("md")`
    height: 500px;
    position: absolute;
    bottom: -100px;
    left: 0;
    right: 0;
  `}
`;

export const Video = styled(VideoComponent)`
  mix-blend-mode: exclusion;
  width: min(100vw, 400px);
  height: 200px;
  transform: scale(1.5);
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0 auto;

  ${media.greaterThan("md")`
    width: 100%;
    height: 500px;
  `}
`;

export const Legals = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;

  ${media.greaterThan("md")`
    justify-content: space-between;
    padding: 0 50px;
    width: 100%;
    z-index: 20;
  `}
`;

export const TermsAndPrivacy = styled.div`
  display: none;

  ${media.greaterThan("md")`
    display: flex;
    gap: 45px;
  `}
`;

export const LegalsLink = styled.a`
  pointer-events: all;
`;
