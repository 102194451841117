// Source: Production code from Couchbase

import { useEffect, useRef } from "react";

export const useRunOnce = (fn: () => void, enabled = true) => {
  const triggeredRef = useRef(false);

  useEffect(() => {
    if (!enabled || triggeredRef.current) {
      return;
    }

    fn();
    triggeredRef.current = true;
  }, [enabled, fn]);
};
