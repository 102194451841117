import { SVGProps } from "react";

export const KnowdeIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="119"
      height="40"
      viewBox="0 0 119 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_442_651)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M44.6494 26.7792V13.1799C44.6494 12.8848 44.7458 12.6406 44.9369 12.4455C45.1297 12.2488 45.3689 12.1504 45.6581 12.1504C45.9231 12.1504 46.1625 12.2488 46.3552 12.4455C46.5464 12.6406 46.6428 12.8864 46.6428 13.1799V20.7991L52.3819 17.0026C52.549 16.9026 52.7177 16.8551 52.9104 16.8551C53.055 16.8551 53.1755 16.8797 53.2944 16.9289C53.4148 16.9764 53.5112 17.0501 53.606 17.1501C53.7023 17.2485 53.7731 17.3452 53.8229 17.4682C53.871 17.5895 53.8935 17.7124 53.8935 17.8583C53.8935 18.2272 53.773 18.496 53.5096 18.6681L49.4987 21.3385L53.6285 26.0678C53.8212 26.2891 53.916 26.5104 53.916 26.7792C53.916 26.9268 53.8919 27.0498 53.8437 27.171C53.7955 27.294 53.7232 27.4153 53.6285 27.4891C53.5321 27.5874 53.4357 27.6595 53.3169 27.7103C53.1964 27.7579 53.0518 27.7842 52.9329 27.7842C52.6679 27.7842 52.4527 27.6858 52.2615 27.5137L47.8443 22.4418L46.6685 23.2269V26.7547C46.6685 27.0235 46.572 27.2677 46.3793 27.4891C46.1866 27.6858 45.9472 27.7842 45.6822 27.7842C45.3947 27.7842 45.1537 27.6858 44.9626 27.4891C44.7441 27.2956 44.6494 27.0498 44.6494 26.7792Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M57.3983 17.8615C58.2385 17.2009 59.2472 16.8583 60.4005 16.8583C61.0735 16.8583 61.6984 16.9796 62.3216 17.2501C62.9207 17.5189 63.4492 17.8877 63.9054 18.327C64.36 18.7926 64.7214 19.332 64.9848 19.945C65.2498 20.5564 65.3928 21.2171 65.3928 21.9286V26.7562C65.3928 27.0513 65.2981 27.2955 65.1052 27.4906C64.9125 27.6873 64.6732 27.7857 64.4082 27.7857C64.1206 27.7857 63.8797 27.6873 63.6886 27.4906C63.4974 27.2955 63.3994 27.0496 63.3994 26.7562V21.9286C63.3994 21.5122 63.3271 21.1203 63.1601 20.7532C62.993 20.3843 62.7761 20.0664 62.5128 19.7713C62.2493 19.4778 61.912 19.2811 61.5522 19.1107C61.1907 18.9402 60.8069 18.8664 60.3989 18.8664C59.9893 18.8664 59.607 18.9402 59.2456 19.1107C58.8858 19.2811 58.5741 19.4795 58.285 19.7713C58.02 20.0418 57.8047 20.3843 57.6361 20.7532C57.4691 21.1203 57.3968 21.5122 57.3968 21.9286V26.7562C57.3968 27.0513 57.3004 27.2955 57.1093 27.4906C56.9165 27.6873 56.6771 27.7857 56.4121 27.7857C56.1229 27.7857 55.8837 27.6873 55.6909 27.4906C55.4981 27.2955 55.4033 27.0496 55.4033 26.7562V17.8598C55.4033 17.591 55.4998 17.3468 55.6909 17.1255C55.8837 16.9304 56.1229 16.832 56.4121 16.832C56.6771 16.832 56.9165 16.9304 57.1093 17.1255C57.3035 17.3468 57.3983 17.5927 57.3983 17.8615Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M77.6403 22.3192C77.6403 23.8388 77.1103 25.1387 76.0774 26.1912C75.0221 27.2681 73.75 27.7846 72.285 27.7846C70.7959 27.7846 69.5479 27.2453 68.4926 26.1912C67.4357 25.1125 66.9297 23.8372 66.9297 22.3192C66.9297 20.8012 67.4582 19.5259 68.4926 18.4489C69.5479 17.3702 70.7976 16.8555 72.285 16.8555C73.75 16.8555 75.0221 17.3948 76.0774 18.4489C76.6059 18.9882 76.9897 19.5767 77.2532 20.2128C77.4958 20.875 77.6162 21.5618 77.6403 22.3192ZM72.2609 18.8899C71.7806 18.8899 71.3502 18.9899 70.9405 19.1603C70.5326 19.3308 70.1728 19.5767 69.8852 19.8947C69.5736 20.2128 69.3567 20.5553 69.1657 20.9718C68.9986 21.3897 68.9022 21.829 68.9022 22.2962C68.9022 22.7634 68.9986 23.2028 69.1657 23.6208C69.3326 24.0372 69.5736 24.4044 69.8852 24.7224C70.1744 25.0404 70.5326 25.2862 70.9405 25.4584C71.3502 25.6289 71.7806 25.7273 72.2609 25.7273C72.7187 25.7273 73.1733 25.6289 73.5813 25.4584C73.9893 25.2862 74.3506 25.0158 74.6623 24.7224C74.9739 24.4044 75.2164 24.0372 75.3819 23.6454C75.5506 23.2289 75.647 22.788 75.647 22.3208C75.647 21.8536 75.5506 21.4143 75.3819 20.9979C75.2148 20.5816 74.9739 20.2128 74.6623 19.8947C74.3506 19.5767 74.015 19.3308 73.5813 19.1603C73.1492 18.9882 72.7412 18.8899 72.2609 18.8899Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M86.0207 16.832C86.501 16.832 86.811 17.0763 86.9813 17.591L88.9265 23.5464L90.944 17.3959C90.9905 17.2238 91.0869 17.0779 91.254 16.9779C91.3985 16.8796 91.6154 16.832 91.8547 16.832C91.9993 16.832 92.1198 16.8566 92.2386 16.9042C92.3575 16.9533 92.4554 17.027 92.5502 17.1254C92.645 17.2238 92.7173 17.3221 92.7654 17.4435C92.8152 17.5663 92.8377 17.6877 92.8377 17.8353C92.8377 17.9582 92.8152 18.0795 92.7654 18.227L89.8597 27.1217C89.8131 27.3184 89.6927 27.466 89.5497 27.5872C89.4051 27.7102 89.1883 27.784 88.9248 27.784C88.708 27.784 88.4911 27.7102 88.3241 27.5872C88.1571 27.466 88.035 27.2938 87.9868 27.1217L86.0191 21.0712L84.0482 27.1217C84.0016 27.3184 83.8812 27.466 83.7125 27.5872C83.5454 27.7102 83.351 27.784 83.1133 27.784C82.8965 27.784 82.7037 27.7102 82.511 27.5872C82.3439 27.466 82.2235 27.3184 82.1512 27.1217L79.2696 18.2516C79.2229 18.1057 79.1973 17.9828 79.1973 17.8598C79.1973 17.7139 79.2229 17.591 79.2696 17.4696C79.3177 17.3467 79.3884 17.2238 79.4864 17.1517C79.5827 17.0517 79.6775 16.9796 79.798 16.9303C79.9185 16.8812 80.063 16.8566 80.1819 16.8566C80.4212 16.8566 80.6156 16.9042 80.7569 17.027C80.924 17.15 81.0219 17.2714 81.0686 17.4189L83.086 23.5448L85.0312 17.5893C85.2272 17.1008 85.5404 16.832 86.0207 16.832Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M99.8744 16.8317C101.146 16.8317 102.274 17.2218 103.235 18.0316V13.1565C103.235 12.8614 103.333 12.6171 103.524 12.4204C103.715 12.2253 103.958 12.127 104.246 12.127C104.532 12.127 104.772 12.2253 104.964 12.4204C105.156 12.6171 105.252 12.863 105.252 13.1565V22.3199C105.252 23.8395 104.726 25.1395 103.693 26.1919C102.636 27.269 101.362 27.7853 99.8989 27.7853C98.4096 27.7853 97.1615 27.246 96.1046 26.1919C95.0493 25.1133 94.5449 23.8379 94.5449 22.3199C94.5449 20.802 95.0718 19.5266 96.1046 18.4497C97.1117 17.3693 98.3839 16.8317 99.8744 16.8317ZM103.235 22.3183C103.235 21.8527 103.14 21.4118 102.972 20.9955C102.805 20.579 102.564 20.2102 102.252 19.8922C101.94 19.5742 101.604 19.3282 101.171 19.1578C100.762 18.9873 100.331 18.8873 99.8508 18.8873C99.3705 18.8873 98.9383 18.9873 98.5296 19.1578C98.1221 19.3282 97.7607 19.5742 97.4748 19.8922C97.1631 20.2102 96.9463 20.5528 96.7552 20.9692C96.5865 21.3872 96.4917 21.8266 96.4917 22.2938C96.4917 22.761 96.5865 23.2002 96.7552 23.6183C96.9222 24.0346 97.1631 24.4018 97.4748 24.7199C97.7864 25.0378 98.1221 25.2838 98.5296 25.4559C98.9383 25.6264 99.3705 25.7247 99.8508 25.7247C100.307 25.7247 100.762 25.6264 101.171 25.4559C101.579 25.2838 101.94 25.0133 102.252 24.7199C102.564 24.4018 102.805 24.0346 102.972 23.6428C103.14 23.2265 103.235 22.7855 103.235 22.3183Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M114.928 24.7224C115.215 24.7224 115.457 24.8224 115.647 25.0175C115.839 25.2142 115.937 25.4601 115.937 25.7518C115.937 26.0469 115.839 26.2912 115.647 26.4863C114.663 27.3698 113.485 27.8108 112.143 27.8108C110.652 27.8108 109.404 27.2715 108.349 26.2174C107.293 25.1404 106.787 23.8651 106.787 22.3471C106.787 20.8291 107.315 19.5538 108.349 18.4751C109.404 17.3982 110.653 16.8818 112.143 16.8818C113.606 16.8818 114.878 17.4212 115.935 18.4751C116.462 19.0145 116.848 19.603 117.111 20.239C117.374 20.8751 117.495 21.5865 117.495 22.3471C117.495 22.6406 117.399 22.8848 117.205 23.0815C117.015 23.2766 116.774 23.3766 116.486 23.3766H108.901C109.022 23.7438 109.166 24.0618 109.381 24.3568C109.598 24.6503 109.838 24.8962 110.127 25.1158C110.416 25.3372 110.726 25.4847 111.062 25.606C111.399 25.729 111.758 25.7781 112.144 25.7781C112.577 25.7781 112.961 25.7044 113.296 25.5814C113.657 25.4601 113.969 25.2634 114.281 24.9946C114.448 24.7962 114.689 24.7224 114.928 24.7224ZM115.36 21.2931C115.242 20.9259 115.096 20.6078 114.88 20.3128C114.663 20.0177 114.425 19.7734 114.137 19.5538C113.847 19.3325 113.535 19.185 113.199 19.062C112.863 18.9407 112.502 18.8916 112.144 18.8916C111.783 18.8916 111.423 18.9407 111.088 19.062C110.752 19.185 110.416 19.3571 110.127 19.5538C109.838 19.7734 109.598 20.0177 109.381 20.3128C109.166 20.6078 109.022 20.9259 108.901 21.2931H115.36Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.78959 24.6781V15.2687C6.78959 10.8328 11.4719 11.5934 11.4719 6.34941C11.4719 3.18725 8.95003 0.615234 5.85313 0.615234C2.75463 0.615234 0.234375 3.18725 0.234375 6.34941C0.234375 11.6918 4.91667 10.7116 4.91667 15.2687V24.6781C4.91667 29.114 0.234375 28.355 0.234375 33.5974C0.234375 36.7596 2.75463 39.3315 5.85313 39.3315C8.95003 39.3315 11.4719 36.7596 11.4719 33.5974C11.4719 28.255 6.78959 29.2107 6.78959 24.6781Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M21.8672 26.7864L20.6433 25.5355C19.2265 24.0897 19.2747 22.6947 19.3952 21.2489C19.4916 20.7341 19.4915 20.2194 19.4675 19.7292C19.4675 19.4588 19.4433 19.1637 19.3952 18.8949C19.2506 17.3998 19.106 15.9802 20.6191 14.4344L21.8431 13.1853C24.8453 10.3657 27.6065 14.0672 31.1595 10.4394C33.3682 8.18548 33.3682 4.55942 31.1595 2.30378C28.9509 0.0497828 25.3962 0.0497828 23.1876 2.30378C19.49 6.07903 23.4751 8.77235 20.33 11.9837L19.1044 13.2329C17.8082 14.4589 16.536 14.4589 15.2397 14.3606C13.3684 13.8475 11.2787 14.3377 9.78964 15.8327C8.66042 16.9834 8.13357 18.4784 8.13357 19.9734C8.10947 21.4668 8.66204 22.9618 9.78964 24.1126C11.2787 25.6339 13.3909 26.1241 15.2879 25.583C16.5601 25.4602 17.8082 25.4602 19.1302 26.7109L20.3541 27.9617C23.4285 31.0975 19.5863 33.9401 23.2116 37.6399C25.4203 39.8939 28.975 39.8939 31.1837 37.6399C33.3922 35.3859 33.3922 31.7582 31.1837 29.5042C27.5582 25.8306 24.9416 29.6764 21.8672 26.7864Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_442_651">
          <rect
            width="117.874"
            height="39.9572"
            fill="white"
            transform="translate(0.234375)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default KnowdeIcon;
