import KrukoLogo from "assets/krukoLogo";

import FlippableCard from "components/flippable-card/FlippableCard";
import Info from "components/info/Info";

import * as Styled from "./ServiceCard.styles";
import Icon from "./Icon";
import { IconNames } from "./ServiceCard.types";

import waterDropVideo from "assets/video/water-drop.mp4";

export type ServiceCardProps = {
  front: {
    title?: string;
    description?: string;
  };
  icon?: IconNames;
  isLogo?: boolean;
  isShape?: boolean;
};

const Front = ({ front, icon }: ServiceCardProps) => (
  <Styled.FrontCard>
    <Styled.IconWrapper>
      <Icon icon={icon} className="backlight" />
    </Styled.IconWrapper>
    <Styled.InfoWrapper>
      {!!front.title && !!front.description && (
        <Info title={front.title} description={front.description} centerText />
      )}
    </Styled.InfoWrapper>
  </Styled.FrontCard>
);

const Logo = () => (
  <Styled.LogoCard>
    <KrukoLogo fill="black" />
  </Styled.LogoCard>
);

const Shape = () => (
  <Styled.VideoWrapper>
    <Styled.Video src={waterDropVideo} mobileSrc={waterDropVideo} />
  </Styled.VideoWrapper>
);

export const ServiceCard = ({
  front,
  isLogo,
  isShape,
  icon,
}: ServiceCardProps) => {
  return (
    <Styled.Wrapper>
      {isLogo ? (
        <Logo />
      ) : isShape ? (
        <Shape />
      ) : (
        <FlippableCard front={<Front front={front} icon={icon} />} />
      )}
    </Styled.Wrapper>
  );
};

export default ServiceCard;
