import { Z_INDEXES } from "constants/zIndexes";
import { motion } from "framer-motion";
import styled from "styled-components";
import { colors } from "styles/variables";

export const MobileMenu = styled(motion.div)<{ $isIndexPlusOpen: boolean }>`
  z-index: ${Z_INDEXES.mobileMenu};

  overflow: auto;
  position: fixed;
  top: 0;
  width: 100vw;
  height: 100dvh;

  overflow: ${({ $isIndexPlusOpen }) => ($isIndexPlusOpen ? "hidden" : "auto")};

  padding-top: 72px;
`;

export const MobileMenuContent = styled.div`
  position: relative;
  z-index: ${Z_INDEXES.mobileMenuContent};
  display: flex;
  flex-direction: column;
`;

export const MobileMenuBackground = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: auto;
  background: ${colors.black};
  opacity: 0.12;
`;

export const MobileLinks = styled.div`
  display: flex;
  flex-direction: column;
  gap: 28px;
  padding: 72px 57px;
`;

export const HighlightedProject = styled.div`
  padding-block: 0 33px;
  padding-inline: 25px;
`;

export const Blur = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 100%;
  bottom: 0;
  width: 100vw;
  height: 100dvh;
  background: transparent;
  backdrop-filter: blur(80px);
  -webkit-backdrop-filter: blur(80px);
  z-index: ${Z_INDEXES.mobileMenuBlur};
`;
