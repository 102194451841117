import { SVGProps } from "react";

export const ContactCardIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="34"
      height="34"
      viewBox="0 0 34 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g filter="url(#filter0_b_521_450)">
        <circle cx="17" cy="17.375" r="16.5" fill="white" fillOpacity="0.1" />
      </g>
      <path
        d="M21.3164 11.9785H12.682C12.1095 11.9785 11.5605 12.2059 11.1557 12.6108C10.7509 13.0156 10.5234 13.5646 10.5234 14.1371V20.6129C10.5234 21.1854 10.7509 21.7344 11.1557 22.1392C11.5605 22.5441 12.1095 22.7715 12.682 22.7715H21.3164C21.8889 22.7715 22.4379 22.5441 22.8428 22.1392C23.2476 21.7344 23.475 21.1854 23.475 20.6129V14.1371C23.475 13.5646 23.2476 13.0156 22.8428 12.6108C22.4379 12.2059 21.8889 11.9785 21.3164 11.9785ZM22.3957 20.6129C22.3957 20.8991 22.282 21.1737 22.0796 21.3761C21.8772 21.5785 21.6027 21.6922 21.3164 21.6922H12.682C12.3958 21.6922 12.1213 21.5785 11.9189 21.3761C11.7164 21.1737 11.6027 20.8991 11.6027 20.6129V14.1371C11.6027 13.8509 11.7164 13.5763 11.9189 13.3739C12.1213 13.1715 12.3958 13.0578 12.682 13.0578H21.3164C21.6027 13.0578 21.8772 13.1715 22.0796 13.3739C22.282 13.5763 22.3957 13.8509 22.3957 14.1371V20.6129Z"
        fill="white"
      />
      <defs>
        <filter
          id="filter0_b_521_450"
          x="-24.5"
          y="-24.125"
          width="83"
          height="83"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="12.5" />
          <feComposite
            in2="SourceAlpha"
            operator="in"
            result="effect1_backgroundBlur_521_450"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_backgroundBlur_521_450"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};

export default ContactCardIcon;
