import styled from "styled-components";
import { border, colors } from "styles/variables";

export const ArtisticInnovationsMobile = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 108px;

  .video {
    border-top: ${border};
    border-bottom: ${border};
    height: auto;
    width: 100vw;
    object-fit: cover;
  }
`;

export const BottomWrapper = styled.div`
  padding-top: 55px;
  padding-inline: 6vw;
  text-align: center;
`;

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 55px;
  justify-content: center;
  align-items: center;
`;

export const Heading = styled.div`
  display: flex;
  justify-content: center;
  column-gap: 1rem;
  row-gap: 0rem;
  flex-wrap: wrap;
  flex-direction: column;
`;

export const SmallText = styled.div`
  color: ${colors.alto2};
  max-width: 77%;
`;
