import { Button } from "components/button/Button";
import * as Styled from "./IndexPlusButton.styles";
import Typography from "components/typography/Typography";

type IndexPlusButtonProps = {
  onClick?: () => void;
  isOpen: boolean;
};

export const IndexPlusButton = ({ onClick, isOpen }: IndexPlusButtonProps) => {
  return (
    <Styled.Wrapper $isOpen={isOpen} onClick={isOpen ? onClick : () => { }}>
      <Styled.ButtonWrapper>
        <Button
          ariaLabel={`${isOpen ? 'close' : 'open'} button`}
          variant="menu"
          isOpen={isOpen}
          onClick={isOpen ? () => { } : onClick}
        />
      </Styled.ButtonWrapper>
      <Styled.TextWrapper>
        <Typography color="white" variant="sm">
          Close
        </Typography>
      </Styled.TextWrapper>
    </Styled.Wrapper>
  );
};

export default IndexPlusButton;
