import { desktopHashes, HashKey, mobileHashes } from "constants/hashes";
import { useScreenContext } from "context/ScreenContext";
import { Hash } from "./useHashLink";

type UseHashes = {
  (): Record<HashKey, Hash>;
  <T extends HashKey>(id: T): Hash;
};

export const useHashes: UseHashes = <T extends HashKey>(id?: T) => {
  const { isDesktop } = useScreenContext();

  if (!id) {
    return isDesktop ? desktopHashes : mobileHashes;
  } else {
    return isDesktop ? desktopHashes[id] : mobileHashes[id];
  }
};
