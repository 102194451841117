import { Z_INDEXES } from "constants/zIndexes";
import styled, { css } from "styled-components";
import { colors, media } from "styles/variables";

export const Wrapper = styled.div`
  overflow: clip; // clip instead of hidden, since scroll-snap doesn't work with hidden
  position: relative;
  pointer-events: none;
`;

export const InfoWrapper = styled.div`
  max-width: 440px;
`;

export const IndexPlusWrapper = styled.div<{ $isOpen?: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  z-index: ${Z_INDEXES.indexPlus};
  transform: translateX(100%);
  transition: transform 0.4s ease-in-out;
  backdrop-filter: blur(100px);
  -webkit-backdrop-filter: blur(100px);

  background: hsla(0, 0%, 0%, 0.08);

  ${({ $isOpen }) =>
    $isOpen &&
    css`
      transform: translateX(0);
    `}
`;

export const IndexPlusButtonWrapper = styled.div<{ $isOpen: boolean }>`
  display: none;

  ${media.greaterThan("md")`
    position: fixed;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    width: 161px;
    height: 50px;
    border-radius: 100px 0 0 100px;
    background: ${colors.scorpion};
    display: flex;
    transform: translateX(100px);
    transition: transform 0.4s ease-in-out;
    transition-delay: 0.4s;
    z-index: 100;
  `}

  ${({ $isOpen }) =>
    $isOpen &&
    css`
      ${media.greaterThan("md")`
        transform: translateX(0);
      `}
    `}
`;
