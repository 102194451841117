import * as Styled from "./Contact.styles";

import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";

import "swiper/css";
import "swiper/css/pagination";

import { useScreenContext } from "context/ScreenContext";
import ContactCard from "components/contact-card/ContactCard";
import { contactCards } from "./Contact.mock";
import { hashIds } from "constants/hashes";

export const Contact = () => {
  const { isMobile } = useScreenContext();

  return (
    <Styled.Wrapper id={hashIds["contact-us"]}>
      <Styled.MobileWrapper>
        <Swiper
          spaceBetween={0}
          slidesPerView={isMobile ? 1 : 2}
          pagination={true}
          modules={[Pagination]}
        >
          {contactCards.map((item) => (
            <SwiperSlide key={item.id}>
              <Styled.ItemWrapper as="a" href={`mailto:${item.email}`}>
                <ContactCard
                  title={item.title}
                  caption={item.caption}
                  email={item.email}
                />
              </Styled.ItemWrapper>
            </SwiperSlide>
          ))}
          <Styled.PaginationWrapper />
        </Swiper>
      </Styled.MobileWrapper>
      <Styled.DesktopContainer>
        {contactCards.map((item) => (
          <Styled.ItemWrapper
            key={item.id}
            as="a"
            href={`mailto:${item.email}`}
          >
            <ContactCard
              title={item.title}
              caption={item.caption}
              email={item.email}
            />
          </Styled.ItemWrapper>
        ))}
      </Styled.DesktopContainer>
    </Styled.Wrapper>
  );
};

export default Contact;
