import { useScreenDetector } from "hooks/useScreenDetection";
import { createContext, useContext } from "react";

type ScreenContextType = {
  isSmallMobile: boolean;
  isMediumMobile: boolean;
  isMobile: boolean;
  isTablet: boolean;
  isDesktop: boolean;
  isDesktopxmd: boolean;
  isDesktopxl: boolean;
  isDesktoplg: boolean;
  isDesktopxlInclusive: boolean;
  initialDimensions: [number, number];
  dimensions: [number, number];
};

const ScreenContext = createContext<ScreenContextType>({
  isSmallMobile: false,
  isMediumMobile: false,
  isMobile: false,
  isTablet: false,
  isDesktop: false,
  isDesktopxmd: false,
  isDesktoplg: false,
  isDesktopxl: false,
  isDesktopxlInclusive: false,
  initialDimensions: [0, 0],
  dimensions: [0, 0],
});

const ScreenContextProvider = ({ children }: { children: React.ReactNode }) => {
  const screenDetector = useScreenDetector();
  return (
    <ScreenContext.Provider value={screenDetector}>
      {children}
    </ScreenContext.Provider>
  );
};

export const useScreenContext = () => {
  return useContext(ScreenContext);
};

export default ScreenContextProvider;
