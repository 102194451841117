import { rgba } from "polished";
import styled, { css } from "styled-components";

import { colors, border } from "styles/variables";

export const Button = styled.div`
  background-color: ${rgba(colors.tundora, 0.25)};
  display: flex;
  padding: 2px;
  align-items: center;
  border-radius: 30px;
  width: max-content;
  text-decoration: none;
  font-weight: 500;
  cursor: pointer;
  transition: 0.2s background-color ease-in-out;
  height: max-content;
  pointer-events: all;

  & > * > svg {
    transition: 0.2s transform ease-in-out;
  }

  &:hover {
    background-color: ${colors.hitPink};

    & > * > svg {
      transform: scale(1.4);
    }
  }
`;

export const ButtonCTA = styled.span`
  padding: 0 24px 0 32px;

  ${Button}:hover & {
    * {
      color: ${colors.white};
    }
  }
`;

export const AnchorButton = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

export const MenuButtonWrapper = styled.div`
  padding: 1.5px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: max-content;
  cursor: pointer;
  height: max-content;
`;

export const LinkButtonWrapper = styled.div<{
  $color?: "transparent" | "orange";
}>`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
  padding: 18px;
  border-radius: 100px;
  transition: border-radius 0.3s ease;

  & > svg {
    transition: transform 0.3s ease;
  }

  ${({ $color }) =>
    $color === "transparent"
      ? css`
          border: 1px solid ${rgba(colors.woodsmoke, 0.1)};
        `
      : css`
          background-color: ${colors.hitPink};
        `}

  &:hover {
    border-radius: 10px;

    & > svg {
      transform: scale(1.4);
    }
  }
`;

export const LinkButtonCTA = styled.span`
  text-align: center;
  padding-bottom: 25px;
`;

export const CommonPseudoElementStyles = `
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: transform 0.6s ease, border-radius 0.6s ease;
`;

export const ArrowButton = styled.div<{
  $disabled?: boolean;
  $direction?: "left" | "right";
}>`
  position: relative;
  width: 102px;
  height: 102px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  border: ${border};
  border-radius: 50%;
  overflow: hidden;
  pointer-events: all;

  & > svg > * {
    color: ${colors.hitPink};
    transition: color 0.6s ease;
    z-index: 1;
  }

  &::after {
    ${CommonPseudoElementStyles};
    background: ${colors.hitPink};
    z-index: 0;

    ${({ $direction }) =>
      $direction === "right"
        ? css`
            transform: translateY(-100%) scaleY(0.5);
            border-radius: 0 0 50% 50%;
          `
        : css`
            transform: translateY(100%) scaleY(0.5);
            border-radius: 50% 50% 0 0;
          `}
  }

  ${({ $disabled }) =>
    $disabled
      ? css`
          background: ${colors.woodsmoke};
          border: none;

          & > svg > * {
            color: ${colors.white};
          }
        `
      : css`
          &:hover {
            cursor: pointer;

            &::after {
              border-radius: 0;
              transform: translateY(0) scaleY(1);
            }

            & > svg {
              z-index: 1;
            }

            & > svg > * {
              color: ${colors.white};
            }
          }
        `};

  ${({ $direction }) =>
    $direction === "right" &&
    css`
      transform: rotate(180deg);
    `};
`;

export const ArrowButtonHighlight = styled.button<{
  $disabled?: boolean;
  $direction?: "left" | "right";
}>`
  position: relative;
  width: 51px;
  height: 51px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  border-radius: 50%;
  overflow: hidden;

  & > svg > * {
    fill: ${colors.white};
    transition: fill 0.6s ease;
  }

  ${({ $disabled }) =>
    $disabled
      ? css`
          & > svg > * {
            opacity: 0.8;
          }
        `
      : css`
          &:hover {
            cursor: pointer;

            & > svg > * {
              fill: ${colors.hitPink};
            }
          }
        `};

  ${({ $direction }) =>
    $direction === "right" &&
    css`
      transform: rotate(180deg);
    `};
`;

export const AnimationLinkButton = styled.div<{
  $color?: "transparent" | "orange";
}>`
  position: relative;
  margin: auto;
  width: 100%;
  max-width: 120px;
  height: 100%;
  max-height: 190px;
  cursor: pointer;
  background: ${({ $color }) =>
    $color === "orange" ? colors.hitPink : "transparent"};
  transform: translate3d(0px, 0%, 0px);
  overflow: hidden;
  border-radius: 100px;
  font-size: 15px;
  font-weight: 600;

  &::before {
    ${CommonPseudoElementStyles};
    background: ${({ $color }) =>
      $color === "orange" ? colors.woodsmoke : colors.hitPink};
    border-radius: 50% 50% 0 0;
    transform: translateY(100%) scaleY(0.5);
  }

  &::after {
    ${CommonPseudoElementStyles};
    background: ${({ $color }) =>
      $color === "orange" ? colors.hitPink : "transparent"};
    border-radius: 0;
    transform: translateY(0) scaleY(1);
  }

  &:hover {
    &::before {
      border-radius: 0;
      transform: translateY(0) scaleY(1);
    }
    &::after {
      border-radius: 0 0 50% 50%;
      transform: translateY(-100%) scaleY(0.5);
    }
  }

  ${({ $color }) =>
    $color === "transparent" &&
    css`
      border: 1px solid ${rgba(colors.woodsmoke, 0.1)};
    `}
`;

export const AnimationLinkButtonSpan = styled.span`
  position: absolute;
  z-index: 1;
  top: 50%;
  transform: translate(0, -50%);
  width: 100%;
  text-align: center;
  transition: transform 0.5s ease;
  padding: 0 18px;
  line-height: 18px;
  display: flex;
  flex-direction: column;
  gap: 20px;

  &:first-child {
    transform: translateY(190px);
  }

  &:last-child {
    transform: translateY(-50%);
  }

  ${AnimationLinkButton}:hover & {
    &:first-child {
      transform: translateY(-50%);
    }
    &:last-child {
      transform: translateY(-190px);
    }
  }
`;

export const AnimationLinkButtonP = styled.p`
  color: ${colors.white};
`;
