import { SVGProps } from "react";

export const CaseStudyIconEllipsis = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="63"
      height="65"
      viewBox="0 0 63 65"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M42.1448 41.8336C36.9812 47.6987 31.0882 51.8368 25.7956 53.8284C20.4337 55.8461 16.0886 55.5422 13.5091 53.2711C10.9295 51 10.0776 46.7285 11.3997 41.1542C12.7047 35.6519 16.0629 29.2821 21.2266 23.4171C26.3902 17.552 32.2832 13.4139 37.5759 11.4223C42.9377 9.40467 47.2828 9.70856 49.8623 11.9796C52.4419 14.2507 53.2938 18.5222 51.9717 24.0965C50.6667 29.5988 47.3085 35.9686 42.1448 41.8336Z"
        stroke="white"
        strokeWidth="3"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M34.9126 10.9174C36.0448 10.3899 37.1628 9.94766 38.2562 9.5947C36.1681 9.00001 33.9636 8.68164 31.6847 8.68164C18.4611 8.68164 7.74121 19.4015 7.74121 32.6251C7.74121 35.9767 8.42982 39.1673 9.67326 42.0634C9.88442 40.9355 10.1808 39.7719 10.5598 38.5835C10.0265 36.6888 9.74121 34.6903 9.74121 32.6251C9.74121 20.5061 19.5657 10.6816 31.6847 10.6816C32.7813 10.6816 33.8591 10.7621 34.9126 10.9174ZM28.4559 54.3328C27.3235 54.8602 26.2053 55.3024 25.1118 55.6552C27.2003 56.2501 29.4053 56.5687 31.6847 56.5687C44.9084 56.5687 55.6282 45.8488 55.6282 32.6251C55.6282 29.2747 54.9401 26.085 53.6974 23.1896C53.486 24.3175 53.1895 25.481 52.8103 26.6693C53.3432 28.5632 53.6282 30.5609 53.6282 32.6251C53.6282 44.7442 43.8038 54.5687 31.6847 54.5687C30.5878 54.5687 29.5097 54.4882 28.4559 54.3328Z"
        fill="white"
      />
      <path
        d="M16.4643 47.4203C14.9107 34.1577 21.063 23.7123 24.1499 19.7295L19.6283 23.5552L15.751 28.6772L12.6873 33.8949L10.7247 40.0221L9.95898 45.4314L10.3899 49.7396L12.0177 53.1383L15.9908 56.0583L21.664 56.1923L25.6767 54.4353C29.0008 52.1796 18.0178 60.6829 16.4643 47.4203Z"
        fill="white"
      />
    </svg>
  );
};

export default CaseStudyIconEllipsis;
