import { CommonPseudoElementStyles } from "components/button/Button.styles";
import styled from "styled-components";
import { colors, media } from "styles/variables";

export const ButtonVertical = styled.button`
  border-radius: 5555px;
  display: flex;
  flex-direction: column;
  padding: 50px 15px;
  gap: 21px;
  font-size: 13px;

  width: 108px;
  background-color: ${colors.scorpionSolid};

  align-items: center;
  justify-content: center;

  ${media.greaterThan("md")`
    padding: 63px 18px;
    width: 120px;
    gap: 24px;
  `}

  overflow: hidden;
  position: relative;
  &:hover *,
  &:hover {
    color: ${colors.white};
    fill: ${colors.white};
  }

  transform: translate3d(0px, 0%, 0px);

  &::after {
    ${CommonPseudoElementStyles};
    background: ${colors.hitPink};
    z-index: -1;
    transform: translateY(100%) scaleY(0.5);
    border-radius: 5555px;
  }

  &:hover {
    cursor: pointer;

    &::after {
      border-radius: 0;
      transform: translateY(0) scaleY(1);
    }
  }
`;
