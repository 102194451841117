import styled from "styled-components";
import { colors, media } from "styles/variables";
import Video from "components/video/Video";

import { ReactComponent as KrukoLogoComponent } from "assets/logos/kruko-logo.svg";

export const Wrapper = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  position: relative;
`;

export const VideoWrapper = styled.div`
  position: absolute;
  top: 0;
  left: -40%;
  width: 180%;
  height: 100%;

  ${media.greaterThan("xSm")`
    left: -20%;
    width: 140%;
  `}

  ${media.greaterThan("md")`
    left: 10%;
    width: 80%;
  `}
`;

export const HeroVideo = styled(Video)`
  position: absolute;
  top: 0;
  left: 0;
  mix-blend-mode: exclusion;
  z-index: 2;
`;

export const ImagineMoreWrapper = styled.div`
  position: absolute;
  top: 150px;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  z-index: 1;

  ${media.greaterThan("md")`
    top: 0;
    justify-content: center;
  `}
`;

export const SmallVideoWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  height: 145px;
  width: 320px;
  z-index: 20;
  display: flex;
  pointer-events: all;

  ${media.lessThan("md")`
    display: none;
  `}
`;

export const SmallVideoButton = styled.div`
  min-width: 55px;
  margin-right: 15px;
  border: 1px solid ${colors.woodsmoke};
  border-radius: 35px;
  pointer-events: all;
`;

export const SmallVideoImageWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`;

export const SmallVideoImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const InfoDesktopWrapper = styled.div`
  ${media.lessThan("md")`
    display: none;
  `}
`;

export const InfoMobileWrapper = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  z-index: 10;
  pointer-events: all;

  ${media.greaterThan("md")`
    display: none;
  `}
`;

export const InfoMobileButtonWrapper = styled.div`
  margin-top: 30px;
  pointer-events: all;
`;

export const KrukoLogo = styled(KrukoLogoComponent)`
  position: absolute;
  left: 50%;
  top: 50%;

  transform: translate(-50%, -50%);

  ${media.lessThan("sm")`
    transform: translate(-50%, -50%) scale(0.6);
  `}

  & path {
    ${media.greaterThan("sm")`
      fill-opacity: 0.35;

    `}
  }
`;
