import * as Styled from "./Icon.styles";

import ai from "assets/images/services/ai.png";
import arSocials from "assets/images/services/arSocials.png";
import branding from "assets/images/services/branding.png";
import cloud from "assets/images/services/cloud.png";
import creativeCoding from "assets/images/services/creativeCoding.png";
import creativePitching from "assets/images/services/creativePitching.png";
import frontend from "assets/images/services/frontend.png";
import genAi from "assets/images/services/genAi.png";
import productDesign from "assets/images/services/productDesign.png";
import strategy from "assets/images/services/strategy.png";
import uiUx from "assets/images/services/uiUx.png";
import webAr from "assets/images/services/webAr.png";
import { IconNames } from "./ServiceCard.types";

const icons: Record<IconNames, string> = {
  ai,
  arSocials,
  branding,
  cloud,
  creativeCoding,
  creativePitching,
  frontend,
  genAi,
  productDesign,
  strategy,
  uiUx,
  webAr,
};

export const Icon = ({
  icon,
  className,
}: {
  icon?: IconNames;
  className?: string;
}) => {
  if (!icon) return null;
  return (
    <Styled.Image
      style={{
        backgroundImage: `url(${icons[icon]})`,
      }}
      className={className}
    />
  );
};

export default Icon;
