import { colors } from "styles/variables";
import * as Styled from "./Button.styles";

import { ArrowButtonIcon } from "assets/arrowButtonIcon";
import { LinkButtonArrow } from "assets/linkButtonArrow";
import IconCircle from "./icon-circle/IconCircle";
import { Typography } from "components/typography/Typography";
import { ButtonCtaHashProps, ButtonProps } from "./Button.types";
import { MenuIcon } from "./menu-icon/MenuIcon";
import ArrowButtonIconLong from "assets/arrowButtonIconLong";
import { useHashLink } from "hooks/useHashLink";

export const CtaButton = ({
  cta,
  arrowColor,
  ariaLabel,
  onClick,
}: Partial<ButtonProps>) => (
  <Styled.Button aria-label={ariaLabel} onClick={onClick}>
    <Styled.ButtonCTA>
      <Typography variant="sm" weight="500" color="hitPink">
        {cta}
      </Typography>
    </Styled.ButtonCTA>
    <IconCircle icon={<LinkButtonArrow color={arrowColor} />} />
  </Styled.Button>
);

export const CtaHashButton = ({
  cta,
  hash,
  arrowColor,
  onClick,
  ariaLabel,
}: Omit<ButtonCtaHashProps, "variant">) => {
  const { onClickHashLink } = useHashLink();

  return (
    <Styled.Button
      aria-label={ariaLabel}
      as="a"
      href={`${hash.href}#${hash.hash}`}
      onClick={(e) => {
        e.preventDefault();
        onClickHashLink(hash);
        if (onClick) {
          onClick();
        }
      }}
    >
      <Styled.ButtonCTA>
        <Typography variant="sm" weight="500" color="hitPink">
          {cta}
        </Typography>
      </Styled.ButtonCTA>
      <IconCircle icon={<LinkButtonArrow color={arrowColor} />} />
    </Styled.Button>
  );
};

export const Button = ({
  cta,
  arrowColor = colors.white,
  variant,
  isOpen,
  color = "transparent",
  href,
  hash,
  disabled,
  direction,
  ariaLabel,
  onClick,
}: ButtonProps) => {
  return (
    <>
      {variant === "ctaHash" ? (
        <CtaHashButton
          aria-label={ariaLabel}
          cta={cta}
          hash={hash}
          arrowColor={arrowColor}
          onClick={onClick}
        />
      ) : variant === "cta" ? (
        <>
          {!!href ? (
            <a href={href} target="_blank" rel="noreferrer">
              <CtaButton
                cta={cta}
                arrowColor={arrowColor}
                aria-label={ariaLabel}
                onClick={onClick}
              />
            </a>
          ) : (
            <CtaButton
              cta={cta}
              arrowColor={arrowColor}
              aria-label={ariaLabel}
              onClick={onClick}
            />
          )}
        </>
      ) : variant === "menu" ? (
        <Styled.MenuButtonWrapper onClick={onClick}>
          <IconCircle icon={<MenuIcon isOpen={isOpen} />} />
        </Styled.MenuButtonWrapper>
      ) : variant === "link" ? (
        <>
          {!onClick ? (
            <a href={href}>
              <Styled.AnimationLinkButton $color={color}>
                <Styled.AnimationLinkButtonSpan>
                  {cta && <p>{cta}</p>}
                  <p>
                    <LinkButtonArrow color={colors.white} />
                  </p>
                </Styled.AnimationLinkButtonSpan>
                <Styled.AnimationLinkButtonSpan>
                  {cta && (
                    <Styled.AnimationLinkButtonP>
                      {cta}
                    </Styled.AnimationLinkButtonP>
                  )}
                  <Styled.AnimationLinkButtonP>
                    <LinkButtonArrow
                      color={color === "orange" ? colors.white : colors.hitPink}
                    />
                  </Styled.AnimationLinkButtonP>
                </Styled.AnimationLinkButtonSpan>
              </Styled.AnimationLinkButton>
            </a>
          ) : (
            <Styled.AnimationLinkButton
              aria-label={ariaLabel}
              $color={color}
              onClick={onClick}
            >
              <Styled.AnimationLinkButtonSpan>
                {cta && <p>{cta}</p>}
                <p>
                  <LinkButtonArrow color={colors.white} />
                </p>
              </Styled.AnimationLinkButtonSpan>
              <Styled.AnimationLinkButtonSpan>
                {cta && (
                  <Styled.AnimationLinkButtonP>
                    {cta}
                  </Styled.AnimationLinkButtonP>
                )}
                <Styled.AnimationLinkButtonP>
                  <LinkButtonArrow
                    color={color === "orange" ? colors.white : colors.hitPink}
                  />
                </Styled.AnimationLinkButtonP>
              </Styled.AnimationLinkButtonSpan>
            </Styled.AnimationLinkButton>
          )}
        </>
      ) : variant === "arrow" ? (
        <Styled.ArrowButton
          $disabled={disabled}
          $direction={direction}
          onClick={onClick}
          aria-label={ariaLabel}
        >
          <ArrowButtonIcon />
        </Styled.ArrowButton>
      ) : (
        variant === "arrowHighlight" && (
          <Styled.ArrowButtonHighlight
            $disabled={disabled}
            $direction={direction}
            onClick={onClick}
            aria-label={ariaLabel}
          >
            <ArrowButtonIconLong />
          </Styled.ArrowButtonHighlight>
        )
      )}
    </>
  );
};

export default Button;
