import Button from "components/button/Button";
import * as Styled from "./CookieBanner.styles";
import { useState } from "react";
import { ConsentMode, setConsentMode } from "./CookieBanner.utils";
import { Switch } from "./Switch";
import { useScreenContext } from "context/ScreenContext";
import { motion, AnimatePresence } from "framer-motion";

type CookieBannerProps = {
  isLoadingPage?: boolean;
  showCookieBanner?: boolean;
  setShowCookieBanner: (showCookieBanner: boolean) => void;
};

export function CookieBanner({
  isLoadingPage,
  setShowCookieBanner,
}: CookieBannerProps) {
  const { isMobile } = useScreenContext();

  const [showCookieSettings, setShowCookieSettings] = useState(false);
  const [cookieSettings, setCookieSettings] = useState<ConsentMode>({
    necessary: true,
    marketing: true,
    analytics: true,
    preferences: true,
  });

  const handleCookieRejectAll = () => {
    setConsentMode({
      necessary: true,
      marketing: false,
      analytics: false,
      preferences: false,
    });
    setShowCookieBanner(false);
  };

  const handleCookieAcceptAll = () => {
    setConsentMode({
      necessary: true,
      marketing: true,
      analytics: true,
      preferences: true,
    });
    setShowCookieBanner(false);
  };

  const settingsVariants = {
    hidden: {
      opacity: 0,
      gridTemplateRows: "0fr",
    },
    visible: {
      opacity: 1,
      gridTemplateRows: "1fr",
      transition: {
        duration: 0.4,
        staggerChildren: 0.1,
      },
    },
  };

  const settingItemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 },
  };

  return !isLoadingPage ? (
    <Styled.Wrapper
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <div className="heading">
        <CookieSvg />
        Cookies
      </div>
      <div className="description">
        We use cookies to improve your experience and for marketing.{" "}
        {!isMobile && <br />}
        Read our 
        <a
          href="https://kruko.io/docs/en/cookie-policy.pdf"
          target="_blank"
          rel="noopener noreferrer"
        >
          Cookie Policy
        </a>
         to understand our analytics better.
      </div>
      <Styled.SettingsWrapper>
        <AnimatePresence>
          {showCookieSettings && (
            <Styled.SettingsContent
              variants={settingsVariants}
              initial="hidden"
              animate="visible"
              exit="hidden"
            >
              <Styled.SettingsInner>
                <Styled.Settings>
                  <div className="settings-content">
                    <motion.div
                      className="spacer"
                      variants={settingItemVariants}
                    />
                    <motion.div
                      className="setting-wrapper"
                      variants={settingItemVariants}
                    >
                      <span className="setting-text">Necessary</span>
                      <Switch isOn={cookieSettings.necessary} readonly />
                    </motion.div>
                    <motion.div
                      className="setting-wrapper"
                      variants={settingItemVariants}
                    >
                      <span className="setting-text">Marketing</span>
                      <Switch
                        isOn={cookieSettings.marketing}
                        toggleSwitch={() => {
                          setCookieSettings({
                            ...cookieSettings,
                            marketing: !cookieSettings.marketing,
                          });
                        }}
                      />
                    </motion.div>
                    <motion.div
                      className="setting-wrapper"
                      variants={settingItemVariants}
                    >
                      <span className="setting-text">Analytics</span>
                      <Switch
                        isOn={cookieSettings.analytics}
                        toggleSwitch={() => {
                          setCookieSettings({
                            ...cookieSettings,
                            analytics: !cookieSettings.analytics,
                          });
                        }}
                      />
                    </motion.div>
                    <motion.div
                      className="setting-wrapper"
                      variants={settingItemVariants}
                    >
                      <span className="setting-text">Preferences</span>
                      <Switch
                        isOn={cookieSettings.preferences}
                        toggleSwitch={() => {
                          setCookieSettings({
                            ...cookieSettings,
                            preferences: !cookieSettings.preferences,
                          });
                        }}
                      />
                    </motion.div>
                    <motion.div
                      className="spacer"
                      variants={settingItemVariants}
                    />
                  </div>
                </Styled.Settings>
              </Styled.SettingsInner>
            </Styled.SettingsContent>
          )}
        </AnimatePresence>
      </Styled.SettingsWrapper>
      <Styled.Buttons>
        <div className="left">
          <Button
            aria-label="reject all cookies"
            variant="cta"
            cta="Reject all"
            onClick={handleCookieRejectAll}
          />
          <Button
            aria-label="accept all cookies"
            variant="cta"
            cta="Accept all"
            onClick={handleCookieAcceptAll}
          />
        </div>
        <button
          aria-label="cookie settings"
          aria-expanded={showCookieSettings}
          className="settings-button"
          onClick={() => {
            if (showCookieSettings) {
              setConsentMode(cookieSettings);
              setShowCookieBanner(false);
            } else {
              setShowCookieSettings(true);
            }
          }}
        >
          {showCookieSettings ? "Save Settings" : "Settings"}
        </button>
      </Styled.Buttons>
    </Styled.Wrapper>
  ) : null;
}

const CookieSvg = () => {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M27.4416 12.9218C25.6406 12.275 24.5329 10.4421 24.672 8.99485C24.672 8.7569 24.5295 8.5358 24.3492 8.37961C24.169 8.22419 23.8894 8.15693 23.6555 8.19455C20.9109 8.59721 18.3978 6.58937 18.1488 3.76696C18.1264 3.51222 17.987 3.28308 17.7707 3.14609C17.5552 3.00992 17.2868 2.98028 17.0489 3.0692C14.8243 3.88388 12.7223 2.41082 11.8835 0.491406C11.7265 0.132493 11.3372 -0.0653658 10.9535 0.0195636C4.63854 1.42869 0 7.52808 0 14C0 21.6896 6.31039 28 14 28C21.6896 28 28 21.6896 28 14C27.9772 13.5115 27.9738 13.1124 27.4416 12.9218ZM5.75586 14.8821C5.30283 14.8821 4.93555 14.5148 4.93555 14.0618C4.93555 13.6086 5.30283 13.2415 5.75586 13.2415C6.20889 13.2415 6.57617 13.6086 6.57617 14.0618C6.57617 14.5148 6.20884 14.8821 5.75586 14.8821ZM9.07813 21.3828C7.72111 21.3828 6.61719 20.2789 6.61719 18.9219C6.61719 17.5649 7.72111 16.461 9.07813 16.461C10.4351 16.461 11.5391 17.5649 11.5391 18.9219C11.5391 20.2789 10.4352 21.3828 9.07813 21.3828ZM9.89844 11.5391C8.99402 11.5391 8.25781 10.8029 8.25781 9.8985C8.25781 8.99408 8.99402 8.25788 9.89844 8.25788C10.8029 8.25788 11.5391 8.99408 11.5391 9.8985C11.5391 10.8029 10.8029 11.5391 9.89844 11.5391ZM16.4609 23.0235C15.5565 23.0235 14.8203 22.2873 14.8203 21.3828C14.8203 20.4784 15.5565 19.7422 16.4609 19.7422C17.3654 19.7422 18.1016 20.4784 18.1016 21.3828C18.1016 22.2873 17.3654 23.0235 16.4609 23.0235ZM17.2813 16.461C15.9242 16.461 14.8203 15.3571 14.8203 14C14.8203 12.643 15.9242 11.5391 17.2813 11.5391C18.6383 11.5391 19.7422 12.643 19.7422 14C19.7422 15.3571 18.6383 16.461 17.2813 16.461ZM22.1621 18.1633C21.7091 18.1633 21.3418 17.7961 21.3418 17.343C21.3418 16.8899 21.7091 16.5227 22.1621 16.5227C22.6151 16.5227 22.9824 16.8899 22.9824 17.343C22.9824 17.7961 22.6151 18.1633 22.1621 18.1633Z"
        fill="#FFA775"
      />
    </svg>
  );
};
