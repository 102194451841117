import { IconNames } from "components/service-card/ServiceCard.types";

type ServiceCard = {
  id: number;
  title?: string;
  description?: string;
  topPlus?: boolean;
  bottomPlus?: boolean;
  isLogo?: boolean;
  isShape?: boolean;
  icon?: IconNames;
};

/**
 * 12 cards, ids from 0 to 11
 */
const cards: Record<string, ServiceCard> = {
  frontend: {
    id: 0,
    title: "Frontend Development",
    description:
      "Create user interfaces for web applications using HTML, CSS and JavaScript.",
    icon: "frontend",
  },
  creativeCoding: {
    id: 1,
    title: "Creative Coding",
    description:
      "Artistic programming that combines code with visual and interactive elements.",
    icon: "creativeCoding",
  },
  creativePitching: {
    id: 2,
    title: "Creative pitching",
    description:
      "Prepare and present innovative ideas in an engaging and compelling manner.",
    icon: "creativePitching",
  },
  productDesign: {
    id: 3,
    title: "Product Design",
    description:
      "Product design from concept to completion, with a focus on functionality and aesthetics.",
    icon: "productDesign",
  },
  uxUi2d3d: {
    id: 4,
    title: "UX - UI - 2D - 3D",
    description:
      "Creating user interfaces and designing user experiences in two and three dimensions.",
    icon: "uiUx",
  },
  strategy: {
    id: 5,
    title: "Strategy",
    description:
      "Develop long-term plans for operations and business development.",
    icon: "strategy",
  },
  webAr: {
    id: 6,
    title: "WebAR",
    description: "Creating augmented reality accessible through web browsers.",
    icon: "webAr",
  },
  arSocialFilters: {
    id: 7,
    title: "AR Social filters",
    description:
      "Designing interactive AR filters for social media, such as Meta and Snapchat.",
    icon: "arSocials",
  },
  genAi: {
    id: 8,
    title: "GenAI",
    description:
      "Generative artificial intelligence that creates content, images or music based on input.",
    icon: "genAi",
  },
  scalableCloudSolutions: {
    id: 9,
    title: "Scalable cloud solutions",
    description:
      "Implementation of flexible and scalable cloud solutions on AWS, Google Cloud and Azure platforms.",
    icon: "cloud",
  },
  ai: {
    id: 10,
    title: "AI",
    description:
      "(ML / LLMs / Computer Vision): Application of artificial intelligence to machine learning, large language models and image analysis.",
    icon: "ai",
  },
  branding: {
    id: 11,
    title: "Branding",
    description:
      "Building a consistent brand identity by designing logos, visuals, and defining brand communications and values.",
    icon: "branding",
  },
} as const;

export const ServicesCardsXl: ServiceCard[] = [
  { ...cards.frontend, topPlus: true },
  cards.creativeCoding,
  cards.creativePitching,
  cards.productDesign,
  cards.uxUi2d3d,
  cards.genAi,
  cards.webAr,
  cards.strategy,
  { ...cards.arSocialFilters, topPlus: true },
  { id: 12, bottomPlus: true, isLogo: true },
  { id: 13, isShape: true },
  cards.scalableCloudSolutions,
  cards.ai,
  cards.branding,
  { id: 14 },
  { id: 15 },
];

export const ServicesCardsLg: ServiceCard[] = [
  { ...cards.frontend, topPlus: true },
  cards.creativeCoding,
  cards.creativePitching,
  cards.productDesign,
  cards.uxUi2d3d,
  cards.strategy,
  { ...cards.webAr, topPlus: true },
  cards.arSocialFilters,
  { ...cards.genAi, bottomPlus: true },
  cards.scalableCloudSolutions,
  cards.ai,
  cards.branding,
  { id: 12 },
  { id: 13 },
  { id: 14 },
];

export const ServicesCardsXmd: ServiceCard[] = [
  { ...cards.frontend, topPlus: true },
  cards.creativeCoding,
  cards.creativePitching,
  cards.productDesign,
  cards.uxUi2d3d,
  { ...cards.strategy, topPlus: true },
  cards.webAr,
  { ...cards.arSocialFilters, bottomPlus: true },
  cards.genAi,
  cards.scalableCloudSolutions,
  cards.ai,
  cards.branding,
];

export const ServicesCardsMd: ServiceCard[] = [
  { ...cards.frontend, topPlus: true },
  cards.creativeCoding,
  cards.creativePitching,
  cards.productDesign,
  cards.uxUi2d3d,
  { ...cards.strategy, topPlus: true },
  { ...cards.webAr, bottomPlus: true },
  cards.arSocialFilters,
  cards.genAi,
  cards.scalableCloudSolutions,
  cards.ai,
  cards.branding,
];

export const ServicesCardsMobile: ServiceCard[] = Object.values(cards);
