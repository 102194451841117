import styled, { css, keyframes } from "styled-components";
import { colors, media, border } from "styles/variables";

const accentWidth = `300px`;

const firstRowAccentAnimation = keyframes`
  0% { transform: ${`translateX(-${accentWidth})`}; opacity: 1; }
  50% { transform: ${`translateX(calc(100vw + (${accentWidth}/2)))`}; opacity: 1; }
  51% { transform: ${`translateX(calc(100vw + (${accentWidth}/2)))`}; opacity: 0; }
  100% { transform: ${`translateX(-${accentWidth})`}; opacity: 0; }
`;

const secondRowAccentAnimation = keyframes`
  0% { transform: ${`translateX(-${accentWidth})`}; opacity: 0; }
  50% { transform: ${`translateX(100vw)`}; opacity: 0; }
  51% { transform: ${`translateX(100vw)`}; opacity: 1; }
  100% { transform: ${`translateX(-${accentWidth})`}; opacity: 1; }
`;

export const ScrollPadding = styled.div`
  padding-top: 50px;
`;

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  margin-bottom: 50px;
  pointer-events: all;

  border-block: ${border};

  ${media.greaterThan("md")`
    pointer-events: none;
    border: none;
    margin-bottom: 90px;
  `}

  ${media.greaterThan("sm")`
    & .swiper-slide:not(.swiper-slide-next) {
      border-inline: ${border};
    }
    & .swiper-slide-active {
      border-left: 0 !important; // needed due to the :not specificity above 
    }
  `}
`;

export const ItemWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

export const PaginationWrapper = styled.div`
  height: 55px;
  border-top: ${border};
`;

export const Rows = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const ContentRowWrapper = styled.div<{
  $hasAccent?: boolean;
  $rowNumber?: number;
}>`
  width: 100vw;
  border-top: ${border};
  display: flex;
  justify-content: center;
  padding: 0 50px;
  position: relative;

  &:last-child {
    border-bottom: ${border};
  }

  ${({ $hasAccent, $rowNumber }) =>
    $hasAccent &&
    css`
      ${media.greaterThan("xmd")`
        &::after {
          content: "";
          position: absolute;
          top: -1px;
          left: 0;
          width: ${accentWidth};
          height: 2px;
          background: ${colors.hitPink};
          background: linear-gradient(
            90deg,
            rgba(255, 255, 255, 0) 0%,
            rgba(255, 255, 255, 0) 5%,
            rgba(255, 167, 117, 1) 50%,
            rgba(255, 255, 255, 0) 95%,
            rgba(255, 255, 255, 0) 100%
          );
          animation-name: ${
            $rowNumber === 1
              ? firstRowAccentAnimation
              : secondRowAccentAnimation
          };
          animation-duration: 20s;
          animation-iteration-count: infinite;
          animation-timing-function: linear;
        }
      `}
    `}
`;

export const ContentRow = styled.div`
  max-width: 1920px;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  border-right: ${border};

  ${media.greaterThan("xmd")`
    grid-template-columns: repeat(3, 1fr);
  `}

  ${media.greaterThan("lg")`
    grid-template-columns: repeat(4, 1fr);
  `}

  ${media.greaterThan("xl")`
    grid-template-columns: repeat(5, 1fr);
  `}
`;

export const ContentItem = styled.div`
  position: relative;
  border-left: ${border};
`;

const plus = `
  content: "";
  position: absolute;
  top: -1px;
  left: 1px;
  background: ${colors.alto};
  width: 4px;
  height: 14px;
`;

export const Plus = styled.div<{ $isBottom?: boolean; $isTopCenter?: boolean }>`
  position: absolute;
  z-index: 10;
  top: ${({ $isBottom }) => ($isBottom ? "auto" : 0)};
  right: 0;
  pointer-events: none;

  ${({ $isTopCenter }) =>
    $isTopCenter &&
    css`
      right: calc(50%);
      transform: translateX(-2px);
    `}

  &::after {
    ${plus};
    transform: translateX(-50%) translateY(-50%);
  }

  &::before {
    ${plus};
    transform: translateX(-50%) translateY(-50%) rotate(90deg);
  }

  ${({ $isBottom }) =>
    $isBottom &&
    css`
      bottom: 0;
      left: 0;

      &::after {
        top: 0;
        left: 0;
      }

      &::before {
        top: 0;
        left: 0;
      }
    `}
`;
