import { SVGProps } from "react";

export const LinkButtonArrow = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M1.03765 10.5259C0.803335 10.7602 0.803335 11.1401 1.03765 11.3745C1.27196 11.6088 1.65186 11.6088 1.88618 11.3745L1.03765 10.5259ZM11.9614 1.0507C11.9614 0.71933 11.6928 0.4507 11.3614 0.450701L5.96141 0.4507C5.63004 0.450701 5.36141 0.71933 5.36141 1.0507C5.36141 1.38207 5.63004 1.6507 5.96141 1.6507H10.7614V6.4507C10.7614 6.78207 11.03 7.0507 11.3614 7.0507C11.6928 7.0507 11.9614 6.78207 11.9614 6.4507L11.9614 1.0507ZM1.88618 11.3745L11.7857 1.47496L10.9371 0.626436L1.03765 10.5259L1.88618 11.3745Z"
        fill={props.color}
      />
    </svg>
  );
};

export default LinkButtonArrow;
