type CalcProportionalValue = {
  currentWidth: number;
  minWidth: number;
  maxWidth: number;
  valueAtMinWidth: number;
  valueAtMaxWidth: number;
};

/**
 * @example
 * Our task is to situate a realtively positioned heading out of screen view. Additionally, this should work at any given screen width range, e.g. 1101px to 2560px
 *
 * For example:
 *
 * - At 1101px, we test and see that the center is at -21vw
 * - At 2560px, we test and see that the center is at 0.7vw.
 *
 * Let's calculate the rate of change per change in width:
 *
 * (-21 - 0.7) / (1101 - 2560) = 0.148
 *
 *
 * This function is a utility for this task:
 *
 * minWidth: 1101,
 * maxWidth: 2560,
 * valueAtMinWidth: -21,
 * valueAtMaxWidth: 0.7,
 * currentWidth: innerWidth,
 *
 * Using this approach, the value at 1101px will be -21, and at 2560px it will be 0.7, and it will be proportional at any width in between.
 *
 */
export const calcProportionalValue = ({
  currentWidth,
  minWidth,
  maxWidth,
  valueAtMinWidth,
  valueAtMaxWidth,
}: CalcProportionalValue) => {
  const rateOfChangePerChangeInWidth =
    (valueAtMinWidth - valueAtMaxWidth) / (minWidth - maxWidth);

  return (
    valueAtMinWidth - (minWidth - currentWidth) * rateOfChangePerChangeInWidth
  );
};
