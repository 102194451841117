import * as Styled from "./ArtisticInnovationsDesktop.styles";
import Video from "components/video/Video";
import { useEffect, useRef, useState } from "react";
import Typography from "components/typography/Typography";
import {
  useScroll,
  useTransform,
  cubicBezier,
  useInView,
  useMotionValueEvent,
} from "framer-motion";
import { debounce } from "utils/debounce";
import {
  calcDescriptionXOutMax,
  calcHeadingsXOutMax,
  calcHeadingTopXOutMax,
  calcHeadingWrapperOutMax,
  calcVidXOutMax,
  calcVidYOutMax,
} from "./ArtisticInnovationsDesktop.utils";
import * as Constants from "./ArtisticInnovationsDesktop.constants";
import artisticInnovationsVideo from "assets/video/artistic-innovations.mp4";
import { useHashes } from "hooks/useHashes";

const ArtisticInnovationsDesktop = () => {
  const containerRef = useRef<HTMLDivElement>(null);
  const videoRef = useRef<HTMLVideoElement>(null);
  const headingWrapperRef = useRef<HTMLDivElement>(null);
  const headingTopRef = useRef<HTMLDivElement>(null);
  const descriptionRef = useRef<HTMLDivElement>(null);
  const headingBottomRef = useRef<HTMLDivElement>(null);
  const fullscreenRef = useRef<HTMLDivElement>(null);

  const hashes = useHashes();

  const isContainerInView = useInView(containerRef, {
    margin: "100px",
  });

  const [dimensions, setDimensions] = useState({
    screenWidth: window.innerWidth,
    screenHeight: window.innerHeight,
    neededVideoScale: 1,
  });

  const [originalVideoDims, setOriginalVideoDims] = useState([0, 0]);

  const { scrollYProgress } = useScroll({
    target: containerRef,
    offset: ["start end", "end end"],
  });

  const { scrollYProgress: scrollYProgressFullscreen } = useScroll({
    target: fullscreenRef,
    offset: ["start end", "end end"],
  });

  const headingWrapperX = useTransform(
    scrollYProgress,
    [0, Constants.headingWrapperXInMin, Constants.headingWrapperXInMax],
    [
      Styled.baseHeadingWrapperTranslateX,
      Styled.baseHeadingWrapperTranslateX,
      calcHeadingWrapperOutMax(dimensions.screenWidth),
    ],
    {
      ease: cubicBezier(0.37, 0, 0.63, 1),
    }
  );

  const headingTopX = useTransform(
    scrollYProgress,
    [Constants.headingTopXInMin, Constants.headingTopXInMax],
    [5.5, calcHeadingTopXOutMax(dimensions.screenWidth)]
  );

  const descriptionX = useTransform(
    scrollYProgress,
    [Constants.descriptionXInMin, Constants.descriptionXInMax],
    [0, calcDescriptionXOutMax(dimensions.screenWidth)],
    {
      ease: cubicBezier(0.32, 0, 0.67, 0),
    }
  );

  const vidX = useTransform(
    scrollYProgress,
    [0, Constants.vidXInMin, Constants.vidXInMax],
    [0, 0, calcVidXOutMax(dimensions.screenWidth)],
    {
      ease: cubicBezier(0.12, 0, 0.39, 0),
    }
  );

  const vidScale = useTransform(
    scrollYProgress,
    [0, Constants.vidScaleInMin, Constants.vidScaleInMax],
    [1, 1, dimensions.neededVideoScale],
    {
      ease: cubicBezier(0.12, 0, 0.39, 0),
    }
  );

  const vidBorderRadius = useTransform(
    scrollYProgress,
    [Constants.vidScaleInMin, Constants.vidScaleInMax],
    [
      Styled.vidBorderRadius,
      originalVideoDims[1] < dimensions.screenHeight &&
        originalVideoDims[0] < dimensions.screenWidth
        ? 12
        : 0,
    ]
  );

  const vidY = useTransform(
    scrollYProgress,
    [0, Constants.vidYInMin, Constants.vidYInMax],
    [0, 0, calcVidYOutMax(dimensions.screenWidth)]
  );

  const wrapperStickyY = useTransform(
    scrollYProgressFullscreen,
    [0, 1],
    [0, 20],
    {
      ease: cubicBezier(0.37, 0, 0.63, 1),
    }
  );

  const headingsX = useTransform(
    scrollYProgress,
    [0, Constants.headingsXInMin, Constants.headingsXInMax],
    [0, 0, calcHeadingsXOutMax(dimensions.screenWidth)],
    {
      ease: cubicBezier(0.12, 0, 0.39, 0),
    }
  );

  // References are stable, no need for useCallback.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleParallaxProgressChange = () => {
    try {
      requestAnimationFrame(() => {
        videoRef.current!.style.transform = `
        translateX(${vidX.get()}vw)
        translateY(calc(${vidY.get()}vw + ${wrapperStickyY.get()}vh))
        scale(${vidScale.get()})
        `;
      });
    } catch { }
  };

  // References are stable, no need for useCallback.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleProgressChange = () => {
    try {
      requestAnimationFrame(() => {
        headingWrapperRef.current!.style.transform = `translateX(${headingWrapperX.get()}vw)
        `;

        headingTopRef.current!.style.transform = `translateX(calc(${headingTopX.get()}vw + ${headingsX.get()}vw))`;
        headingBottomRef.current!.style.transform = `translateX(${headingsX.get()}vw)`;

        descriptionRef.current!.style.transform = `translateX(${descriptionX.get()}vw)`;

        videoRef.current!.style.transform = `
        translateX(${vidX.get()}vw)
        translateY(${vidY.get()}vw)
        scale(${vidScale.get()})
        `;

        videoRef.current!.style.borderRadius = `${vidBorderRadius.get()}px`;
      });
    } catch { }
  };

  useEffect(() => {
    const calcVidScale = () => {
      try {
        const isViewportBiggerThanVideo =
          window.innerWidth > originalVideoDims[0];

        const initialVidHeight =
          ((Styled.initialVidWidth / 100) * window.innerWidth) /
          (isViewportBiggerThanVideo ? 1.778 : 1.79); // assume all videos will have 16:9 AR for now, so ~1.778. If this won't be the case, we'll just calculate it.

        // Fit the video to cover the whole viewport on any screen size.
        let neededVideoScale: number;

        if (
          // NOTE: If we decide we want to contain the video instead of cover,
          // just switch the "<" below to ">".
          window.innerWidth / window.innerHeight <
          videoRef.current!.clientWidth / initialVidHeight ||
          isViewportBiggerThanVideo
        ) {
          neededVideoScale =
            Math.min(originalVideoDims[1], window.innerHeight) /
            initialVidHeight;
        } else {
          neededVideoScale =
            Math.min(originalVideoDims[0], window.innerWidth) /
            videoRef.current!.clientWidth;
        }

        neededVideoScale *= isViewportBiggerThanVideo ? 1 : 1.006; // 0.6% of padding since no calculation is perfect;)
        setDimensions({
          screenWidth: window.innerWidth,
          screenHeight: window.innerHeight,
          neededVideoScale,
        });
      } catch { }
    };

    if (!isContainerInView || !originalVideoDims[0]) {
      return;
    }

    calcVidScale();
    const cb = debounce(calcVidScale, 300);
    window.addEventListener("resize", cb);

    return () => {
      window.removeEventListener("resize", cb);
    };
  }, [isContainerInView, originalVideoDims, scrollYProgress]);

  useEffect(() => {
    if (!isContainerInView) {
      return;
    }

    handleProgressChange();
    handleParallaxProgressChange();
  }, [
    isContainerInView,
    dimensions.neededVideoScale,
    handleProgressChange,
    handleParallaxProgressChange,
  ]);

  useMotionValueEvent(scrollYProgress, "change", handleProgressChange);
  useMotionValueEvent(wrapperStickyY, "change", handleParallaxProgressChange);

  const headingOffsetTop = headingWrapperRef.current?.offsetTop || 0;

  return (
    <>
      <Styled.ArtisticInnovationsDesktop
        ref={containerRef}
        $headingOffsetTop={headingOffsetTop}
        id={hashes["innovative-solutions"].hash}
      >
        <Styled.Wrapper>
          <Styled.HeadingWrapper ref={headingWrapperRef}>
            <Styled.HeadingTop
              ref={headingTopRef}
              style={{
                opacity: originalVideoDims[0] ? 1 : 0,
              }}
            >
              <Typography
                weight="700"
                variant="leadSm"
                color="ebonyClay"
                letterSpacing={-3}
                as="span"
              >
                Artistic
              </Typography>
              <Typography
                weight="700"
                variant="leadSm"
                color="ebonyClay"
                letterSpacing={-3}
                as="span"
              >
                Innovations
              </Typography>
            </Styled.HeadingTop>
            <Styled.HeadingBottom
              style={{
                opacity: originalVideoDims[0] ? 1 : 0,
              }}
            >
              <Styled.HeadingBottomInner ref={headingBottomRef}>
                <Typography
                  weight="700"
                  variant="leadSm"
                  color="ebonyClay"
                  letterSpacing={-3}
                  as="span"
                >
                  at
                </Typography>
                <Typography
                  weight="700"
                  variant="leadSm"
                  color="ebonyClay"
                  letterSpacing={-3}
                  as="span"
                >
                  a
                </Typography>
                <Typography
                  weight="700"
                  variant="leadSm"
                  color="hitPink"
                  letterSpacing={-3}
                  as="span"
                >
                  New
                </Typography>
                <Typography
                  weight="700"
                  variant="leadSm"
                  color="white"
                  letterSpacing={-3}
                  as="span"
                >
                  Level
                </Typography>
              </Styled.HeadingBottomInner>
              <Video
                src={artisticInnovationsVideo}
                mobileSrc={artisticInnovationsVideo}
                ref={videoRef}
                className="video"
                onLoadedMetadata={(e) => {
                  setOriginalVideoDims([
                    e.currentTarget.videoWidth,
                    e.currentTarget.videoHeight,
                  ]);
                }}
              />
            </Styled.HeadingBottom>
            <Styled.SmallText
              style={{
                opacity: originalVideoDims[0] ? 1 : 0,
              }}
              ref={descriptionRef}
            >
              We believe that the key to successful implementation is open and
              transparent communication with the client.
            </Styled.SmallText>
          </Styled.HeadingWrapper>
        </Styled.Wrapper>
        <Styled.FullScreenWrapper ref={fullscreenRef} />
      </Styled.ArtisticInnovationsDesktop>
    </>
  );
};

export default ArtisticInnovationsDesktop;
