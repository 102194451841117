import { motion } from "framer-motion";
import styled from "styled-components";
import { media } from "styles/variables";

export const Wrapper = styled(motion.div)`
  position: fixed;
  z-index: 2137000;

  padding: 30px 27px;
  bottom: 24px;

  left: 50%;
  transform: translateX(-50%);
  width: calc(100% - 50px);

  display: flex;
  flex-direction: column;

  max-width: 732px;

  border-radius: 16px;
  background: #2e2e2e40;

  backdrop-filter: blur(15px);
  -webkit-backdrop-filter: blur(15px);

  & > .heading {
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 13px;
    font-size: 30px;
    line-height: 20px;
    font-weight: 600;

    ${media.greaterThan("md")`
      justify-content: flex-start;
    `}
  }

  & > .description {
    color: white;
    font-size: 15px;
    line-height: 20px;
    font-weight: 400;
    padding-block: 28px 14px;

    text-align: center;

    & > a {
      color: white;
      text-decoration: underline;
    }

    ${media.greaterThan("md")`
      text-align: left;
    `}
  }

  ${media.greaterThan("md")`
    padding: 40px 44px; 
    right: 34px;
    bottom: 44px;
    left: unset;
    transform: none;
  `}
`;

export const SettingsWrapper = styled.div`
  overflow: hidden;
`;

export const SettingsContent = styled(motion.div)`
  display: grid;
  overflow: hidden;
`;

export const SettingsInner = styled.div`
  min-height: 0;
`;

export const Settings = styled(motion.div)<{ $isOpen?: boolean }>`
  display: grid;

  /* grid-template-rows: ${({ $isOpen }) => ($isOpen ? "1fr" : "0fr")}; */
  /* transition: grid-template-rows 0.3s ease-in-out; */

  .settings-content {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    gap: 16px;
    /* padding-top: 8px; */
    /* margin-bottom: 36px; */
    /* padding-bottom: 36px; */
  }

  .setting-wrapper {
    display: flex;
    gap: 8px;
    align-items: center;
    justify-content: space-between;
  }

  .spacer {
    opacity: 0;
  }

  .setting-text {
    color: white;
    min-width: 100px;
    display: inline-block;
  }

  ${media.greaterThan("md")`
    .settings-content {
      padding-inline: 34px;
    }
  `}
`;

export const Buttons = styled.div`
  display: flex;
  flex-wrap: wrap;
  row-gap: 28px;
  column-gap: 8px;
  flex-direction: column;
  padding-top: 14px;

  & > .left {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    justify-content: center;

    & > div {
      width: 182px;
      justify-content: space-between;
    }
  }

  ${media.greaterThan("md")`
    flex-direction: row;
    justify-content: space-between;
  `}

  & > .settings-button {
    border-radius: 40px;
    display: flex;
    align-items: center;
    justify-content: center;

    padding-inline: 24px;

    padding-block: 18px;
    min-width: 182px;

    align-self: center;

    cursor: pointer;

    font-size: 15px;

    &:hover {
      color: white;
      background: #424242;
    }

    transition: 0.2s background-color ease-in-out, color 0.6s ease;

    background: #42424240;
    color: #777777;

    ${media.greaterThan("md")`
      width: 149px;
    `}
  }
`;
