import { isMobileBrowser } from "constants/browser";
import { MOBILE_BAR_INCREASE } from "constants/constants";
import { useScreenContext } from "context/ScreenContext";
import { calcProportionalValue } from "utils/calcProportionalValue";

export const calcCamZoomY = (screenHeight: number) => {
  return calcProportionalValue({
    minWidth: 568,
    maxWidth: 1440,
    valueAtMaxWidth: 0.97,
    valueAtMinWidth: 1,
    currentWidth: screenHeight,
  });
};

const calcCamZoomX = (screenWidth: number) => {
  return calcProportionalValue({
    minWidth: 320,
    maxWidth: 2560,
    valueAtMaxWidth: 9.3,
    valueAtMinWidth: 1,
    currentWidth: screenWidth,
  });
};

export const calcX = (desiredX: number, screenWidth: number) => {
  return desiredX * calcCamZoomX(screenWidth);
};

export const calcY = (desiredY: number, screenHeight: number) => {
  return desiredY * calcCamZoomY(screenHeight);
};

export const useCalcPosition = () => {
  const {
    dimensions: [screenWidth, screenHeight],
  } = useScreenContext();
  return {
    calcX: (desiredX?: number) => calcX(desiredX || 0, screenWidth),
    calcY: (desiredY?: number) =>
      calcY(-(desiredY || 0), screenHeight) +
      (isMobileBrowser ? MOBILE_BAR_INCREASE : 0),
  };
};

/**
 * Calculate the light intensity based on how much more screen does the light
 * have to cover.
 *
 * @param customMultiplier - Decide how much more pronounced the light intensity
 * should be with respect to rising screen width. The default 1.3 is an
 * arbitrary value that just looks good.
 */
export const calcLightIntensity = ({
  intensityAtMinimum,
  minWidth,
  screenWidth,
  customMultiplier = 1.3,
}: {
  intensityAtMinimum: number;
  minWidth: number;
  screenWidth: number;
  customMultiplier?: number;
}) => {
  const distanceMultiplier = 1 + (screenWidth - minWidth) / minWidth;
  const intensityMultiplier = distanceMultiplier ** customMultiplier;

  return intensityAtMinimum * intensityMultiplier;
};
