import UnstyledVideo from "components/video/Video";
import { motion } from "framer-motion";
import styled, { css } from "styled-components";
import { colors, media } from "styles/variables";

export const Wrapper = styled.div`
  position: relative;
  padding-bottom: 80px;

  ${media.greaterThan("md")`
    display: none;
  `}

  mix-blend-mode: exclusion;
`;

export const TitleWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 50px;
  text-align: center;
`;

export const NameWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 11px;
  padding-bottom: 64px;
  text-align: center;
`;

export const ImageWrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`;

export const Cutout = styled.div`
  clip-path: polygon(0 100%, 100% 100%, 100% 0, 50% 100%, 0 0);
  height: 220px;
  background: ${colors.ebonyClay};
  position: absolute;
  width: 100%;
  left: 0;
  bottom: -1px;

  &::after {
    content: "";
    clip-path: polygon(0 100%, 100% 100%, 100% 0, 50% 100%, 0 0);
    position: absolute;
    top: 1px;
    left: 0;
    width: 100%;
    height: 100%;
    background: ${colors.black};
  }
`;

export const Image = styled.img`
  width: 287px;
  height: 372px;

  ${media.greaterThan("md")`
    height: 502px;
    width: 388px;
  `}
`;

export const LinkedInWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 160px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid ${colors.ebonyClay};
  pointer-events: all;
`;

export const LinkedInBorder = styled.div`
  clip-path: polygon(50% 0%, 100% 45%, 100% 100%, 0 100%, 0 45%);
  height: 100%;
  width: 150px;
  background: ${colors.ebonyClay};
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin: 0 auto;

  &::after {
    content: "";
    clip-path: polygon(50% 0%, 100% 45%, 100% 100%, 0 100%, 0 45%);
    position: absolute;
    top: 1px;
    left: 1px;
    width: calc(100% - 2px);
    height: 100%;
    background: ${colors.black};
  }
`;

export const LinkedInCircle = styled.div`
  width: 65px;
  height: 65px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${colors.hitPink};
  border-radius: 50%;
  transition: background 0.3s ease;

  & > svg > path {
    transition: fill 0.3s ease;
  }

  &:hover {
    background: ${colors.white};

    & > svg > path {
      fill: ${colors.hitPink};
    }
  }
`;

export const DesktopWrapper = styled.div`
  display: none;

  ${media.greaterThan("md")`
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    position: relative;
    padding-bottom: 200px;
    flex-direction: column;
  `}
`;

export const Shape = styled.div`
  position: relative;
  width: 1200px;
  mix-blend-mode: exclusion;
  height: 550px;
  background: ${colors.ebonyClay};
  clip-path: polygon(20% 100%, 80% 100%, 100% 50%, 80% 0, 20% 0, 0 50%);

  &:after {
    content: "";
    clip-path: polygon(20% 100%, 80% 100%, 100% 50%, 80% 0, 20% 0, 0 50%);
    background: ${colors.black};
    position: absolute;
    inset: 1px;
  }
`;

export const Video = styled(UnstyledVideo)`
  display: none;

  position: absolute;
  top: 52%;
  left: 75.5%;
  width: 382px;
  object-fit: cover;
  z-index: 30;
  mix-blend-mode: difference;

  ${media.greaterThan("xmd")`
    display: block;
  `}
`;

export const Container = styled(motion.div)`
  position: relative;
`;

const decoration = css`
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto 0;
  width: 100%;
  height: 90px;
  background: ${colors.ebonyClay};
  mix-blend-mode: exclusion;
`;

const decorationAfter = css`
  top: 0;
  bottom: 0;
  background: ${colors.ebonyClay};
  width: 41px;
  position: absolute;
  z-index: 10;
`;

const decorationFillerAfter = css`
  top: 0;
  bottom: 0;
  background: ${colors.black};
  width: 41px;
  position: absolute;
  z-index: 20;
`;

export const DecorationLeft = styled.div`
  left: 0;
  transform: translateX(-100%) translateX(-41px);
  ${decoration};

  &:after {
    content: "";
    right: 1px;
    clip-path: polygon(0 0, 0% 100%, 100% 50%);
    transform: translateX(100%);
    ${decorationAfter};
  }
`;

export const DecorationFillerLeft = styled.div`
  position: absolute;
  inset: 1px;
  background: ${colors.black};

  &:after {
    content: "";
    ${decorationFillerAfter};
    right: 1px;
    transform: translateX(100%);
    clip-path: polygon(0 0, 0% 100%, 100% 50%);
  }
`;

export const DecorationRight = styled.div`
  right: 0;
  transform: translateX(100%) translateX(41px);
  ${decoration};

  &:after {
    content: "";
    left: 1px;
    clip-path: polygon(100% 0, 100% 100%, 0 50%);
    transform: translateX(-100%);
    ${decorationAfter};
  }
`;

export const DecorationFillerRight = styled.div`
  position: absolute;
  inset: 1px;
  background: ${colors.black};

  &:after {
    content: "";
    ${decorationFillerAfter};
    left: 1px;
    transform: translateX(-100%);
    clip-path: polygon(100% 0, 100% 100%, 0 50%);
  }
`;

export const SectionTitle = styled.div`
  padding-bottom: 90px;
`;

export const Content = styled.div`
  position: relative;
  z-index: 30;
  display: flex;
  height: 100%;
`;

export const Info = styled(motion.div)`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  padding-left: 180px;
`;

export const ImageWrapperDesktop = styled(motion.div)`
  display: flex;
  flex: 1;
  padding-right: 100px;
  height: 100%;
  align-items: flex-end;
`;

export const Email = styled.a`
  text-decoration: none;
  pointer-events: all;
`;

export const EmailWrapper = styled.div<{ $isPaddingBottom?: boolean }>`
  display: flex;
  align-items: center;
  gap: 18px;

  ${({ $isPaddingBottom }) =>
    $isPaddingBottom &&
    css`
      padding-bottom: 5px;
    `}
`;

export const PositionWrapper = styled.div`
  padding-bottom: 12px;
`;

export const NameWrapperDesktop = styled.div`
  padding-bottom: 39px;
`;
