import styled, { css } from "styled-components";
import { colors } from "styles/variables";

export const IconLine = styled.div<{
  $variant?: "lt" | "rt" | "lb" | "rb";
  $isOpen?: boolean;
}>`
  position: relative;
  height: 1.5px;
  width: ${({ $isOpen }) => ($isOpen ? "0px" : "5px")};
  background-color: ${colors.white};
  border-radius: 4px;

  transition: width 0.2s ease-in-out, transform 0.4s ease-in-out 0.3s;

  &::after {
    content: "";
    height: 1.5px;
    width: 7px;
    background-color: ${colors.white};
    border-radius: 4px;
    position: absolute;
    transition: background-color 0.2s ease-in-out;

    ${({ $variant }) =>
      $variant === "lt"
        ? css`
            left: 0;
            top: 0;
            transform: translateX(-6px) translateY(-2px) rotate(45deg);
          `
        : $variant === "rt"
        ? css`
            right: 0;
            top: 0;
            transform: translateX(6px) translateY(-2px) rotate(-45deg);
          `
        : $variant === "lb"
        ? css`
            left: 0;
            top: 0;
            transform: translateX(-6px) translateY(2px) rotate(-45deg);
          `
        : css`
            right: 0;
            top: 0;
            transform: translateX(6px) translateY(2px) rotate(45deg);
          `}
  }
`;

export const IconRow = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  flex-direction: row;
`;

export const Icon = styled.div<{ $isOpen?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  gap: 4px;
  flex-direction: column;
`;
