import styled from "styled-components";
import { colors, media } from "styles/variables";

export const Wrapper = styled.div`
  position: relative;
  z-index: 10;

  // Remove comment once we get footer back in
  /* ${media.greaterThan("md")`
    display: flex;
    align-items: center;
    justify-content: center;
    border-top: 1px solid ${colors.ebonyClay};
    margin-top: 40px;
  `} */
`;

export const Content = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-column-gap: 0;
  grid-row-gap: 0;
  padding-bottom: 30px;

  ${media.greaterThan("md")`
    width: 100%;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(1, 1fr);
    max-width: 1920px;
    padding: 0 50px
  `}
`;

export const Column = styled.div`
  position: relative;
  border-top: 1px solid ${colors.ebonyClay};
  padding-bottom: 70px;

  ${media.greaterThan("md")`
    display: flex;
    justify-content: center;
    border-top: none;
  `}

  &:first-of-type {
    ${media.greaterThan("md")`
      justify-content: flex-start;
    `}
  }

  &:last-of-type {
    ${media.greaterThan("md")`
      justify-content: flex-end;
    `}
  }
`;

export const ColumnBody = styled.div`
  padding-top: 43px;
  padding-left: 25px;

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: auto;
    height: 2px;
    width: 54px;
    background: ${colors.hitPink};
  }

  ${media.greaterThan("md")`
    padding-left: 0;
  `}
`;

export const ColumnTitleWrapper = styled.div`
  padding-bottom: 21px;
`;

export const ColumnLinksWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const ColumnLink = styled.div`
  pointer-events: all;
`;
