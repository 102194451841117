export const sizes = {
  lead: "170px",
  leadSm: "145px",
  leadSmallDesktop: "110px",
  leadMobile: "90px",
  xxl: "70px",
  xl: "55px",
  lg: "35px",
  xMd: "30px",
  mobileMenuLink: "26px",
  title: "25px",
  md: "22px",
  captionXxl: "20px",
  footerTitle: "20px",
  footerTitleSm: "16px",
  captionXl: "19px",
  caption: "17px",
  sm: "15px",
  xSm: "14px",
  xxSm: "13px",
  innovativeSolutionsMobile: "clamp(30px, 11vw, 55px)",
  transformingVisionsResponsive: "clamp(40px, 10vw, 145px)",
  heroResponsive: "clamp(40px, 23vw, 145px)",
};
