import Video from "components/video/Video";
import * as Styled from "./ArtisticInnovationsMobile.styles";
import Typography from "components/typography/Typography";
import artisticInnovationsVideo from "assets/video/artistic-innovations.mp4";

const ArtisticInnovationsMobile = () => {
  return (
    <Styled.ArtisticInnovationsMobile>
      <Video
        src={artisticInnovationsVideo}
        mobileSrc={artisticInnovationsVideo}
        className="video"
      />
      <Styled.BottomWrapper>
        <Styled.TextWrapper>
          <Styled.Heading>
            <Typography
              weight="700"
              variant="xl"
              color="ebonyClay"
              letterSpacing={-3}
            >
              Artistic
            </Typography>
            <Typography
              weight="700"
              variant="xl"
              color="white"
              letterSpacing={-3}
            >
              Innovations at
            </Typography>
            <div>
              <Typography
                weight="700"
                variant="xl"
                color="ebonyClay"
                letterSpacing={-3}
                as="span"
              >
                a{" "}
              </Typography>
              <Typography
                weight="700"
                variant="xl"
                color="hitPink"
                letterSpacing={-3}
                as="span"
              >
                New{" "}
              </Typography>
              <Typography
                weight="700"
                variant="xl"
                color="hitPink"
                letterSpacing={-3}
                as="span"
              >
                Level
              </Typography>
            </div>
          </Styled.Heading>
          <Styled.SmallText>
            We believe that the key to successful implementation is open and
            transparent communication with the client.
          </Styled.SmallText>
        </Styled.TextWrapper>
      </Styled.BottomWrapper>
    </Styled.ArtisticInnovationsMobile>
  );
};

export default ArtisticInnovationsMobile;
