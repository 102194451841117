import styled, { css } from "styled-components";

export const Container = styled.div<{
  $maxWidth?: string;
  $centerText?: boolean;
  $maxTitleWidth?: string;
}>`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 20px;
  z-index: 2;

  ${({ $maxWidth }) =>
    $maxWidth &&
    css`
      max-width: ${$maxWidth};
    `}

  ${({ $centerText }) =>
    $centerText &&
    css`
      text-align: center;
    `}

  ${({ $maxTitleWidth }) =>
    $maxTitleWidth &&
    css`
      justify-content: center;
      align-items: center;
    `}
`;

export const TitleWrapper = styled.div<{ $maxTitleWidth?: string }>`
  ${({ $maxTitleWidth }) =>
    $maxTitleWidth &&
    css`
      max-width: ${$maxTitleWidth};
    `}
`;
