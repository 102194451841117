import { SVGProps } from "react";

export const DribbleIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.7882 12.0847C15.5369 11.5038 15.275 10.9239 14.9957 10.3502C17.3809 9.31711 19.2979 7.91418 20.7261 6.1351C22.1193 7.78153 22.9941 9.87238 23.1229 12.1607C20.4637 11.7255 18.018 11.7013 15.7882 12.0847ZM18.597 21.2474C18.1448 18.8181 17.4811 16.4426 16.6146 14.1252C18.5515 13.8512 20.6839 13.9224 23.0159 14.3281C22.5647 17.2051 20.9226 19.6905 18.597 21.2474ZM6.63185 20.8039C8.66508 17.6142 11.2613 15.5379 14.4782 14.5793C15.4353 17.0792 16.1401 19.6498 16.5884 22.2834C13.2374 23.5783 9.47209 23.0221 6.63185 20.8039ZM2.69076 12.474C6.66477 12.4681 10.0932 12.0116 12.9659 11.1078C13.2065 11.5982 13.4369 12.091 13.6572 12.5872C10.1682 13.6774 7.29168 15.9135 5.04834 19.2795C3.43337 17.3411 2.62927 14.9903 2.69076 12.474ZM8.29571 3.6134C9.67105 5.41281 10.8886 7.2679 11.9483 9.1714C9.4382 9.91014 6.44305 10.2897 2.97928 10.3134C3.69479 7.38554 5.66606 4.95049 8.29571 3.6134ZM19.1556 4.64067C17.9115 6.24499 16.1939 7.51382 14.0052 8.4404C12.9567 6.51899 11.758 4.64454 10.404 2.82528C13.4693 2.04345 16.6402 2.69796 19.1556 4.64067ZM12.9141 0.344727C6.0698 0.344727 0.521484 5.89353 0.521484 12.7378C0.521484 19.5821 6.0698 25.1309 12.9141 25.1309C19.7593 25.1309 25.3076 19.5821 25.3076 12.7378C25.3076 5.89353 19.7588 0.344727 12.9141 0.344727Z"
        fill="#FFA775"
      />
    </svg>
  );
};

export default DribbleIcon;
