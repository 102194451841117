import styled from "styled-components";
import { border, media } from "styles/variables";

export const Wrapper = styled.div`
  ${media.greaterThan("md")`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top: ${border};
    border-bottom: ${border};
    position: relative; 
  `}
`;

export const DesktopWrapper = styled.div`
  display: none;

  ${media.greaterThan("md")`
    display: flex;
    width: 100%;
    max-width: 1920px;
    margin: 0 50px;
  `}
`;

export const MobileWrapper = styled.div`
  display: flex;
  flex-direction: column;

  ${media.greaterThan("md")`
    display: none;
  `}
`;

export const TitleWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 50px;
`;
