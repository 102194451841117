import { isMobileBrowser } from "constants/browser";
import { useEffect, useRef, useState } from "react";
import { debounce } from "utils/debounce";

export const useScreenDetector = () => {
  const [dimensions, setDimensions] = useState<[number, number]>([
    window.innerWidth,
    window.innerHeight,
  ]);

  const initialDimensions = useRef<[number, number]>([
    window.innerWidth,
    window.innerHeight,
  ]);

  const [width] = dimensions;

  const handleWindowSizeChange = () => {
    setDimensions([window.innerWidth, window.innerHeight]);
  };

  const isDesktop = width > 1100;

  useEffect(() => {
    if (window.innerWidth < 1100) {
      document.body.style.overflow = "auto";
      initialDimensions.current = [window.innerWidth, window.innerHeight];
    } else if (window.innerWidth >= 1100 && !isMobileBrowser) {
      document.body.style.overflow = "hidden";
    }

    const debouncedCb = debounce(handleWindowSizeChange, 200);

    window.addEventListener("resize", debouncedCb);

    return () => {
      window.removeEventListener("resize", debouncedCb);
    };
  }, [isDesktop]);

  const isSmallMobile = width <= 380;
  const isMediumMobile = width <= 480;
  const isMobile = width <= 768;
  const isTablet = width <= 1110;
  const isDesktopxmd = width > 1352 && width <= 1430;
  const isDesktoplg = width > 1430 && width <= 1920;
  const isDesktopxlInclusive = width >= 1920;
  const isDesktopxl = width > 1920;

  return {
    isSmallMobile,
    isMediumMobile,
    isMobile,
    isTablet,
    isDesktop,
    isDesktopxmd,
    isDesktopxl,
    isDesktopxlInclusive,
    isDesktoplg,
    dimensions,
    initialDimensions: initialDimensions.current,
  };
};
