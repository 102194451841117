import ServiceCard from "components/service-card/ServiceCard";
import * as Styled from "./Services.styles";
import { useScreenContext } from "context/ScreenContext";
import {
  ServicesCardsMd,
  ServicesCardsXmd,
  ServicesCardsLg,
  ServicesCardsXl,
} from "./Services.mock";
import { hashIds } from "constants/hashes";
import { useSectionRefs } from "context/SectionRefContext";

const Cards = () => {
  const { isDesktoplg, isDesktopxmd, isDesktopxlInclusive } =
    useScreenContext();
  const setToRender = isDesktopxlInclusive
    ? ServicesCardsXl
    : isDesktoplg
    ? ServicesCardsLg
    : isDesktopxmd
    ? ServicesCardsXmd
    : ServicesCardsMd;

  const numOfCards = setToRender.length;

  const cardsPerRow = isDesktopxlInclusive
    ? 5
    : isDesktoplg
    ? 4
    : isDesktopxmd
    ? 3
    : 2;

  const numberOfRows = Math.floor(numOfCards / cardsPerRow);

  const renderRows = () => {
    const rows = [];

    for (let i = 0; i < numberOfRows; i++) {
      const startIndex = i * cardsPerRow;
      const endIndex = startIndex + cardsPerRow;
      const rowCards = setToRender.slice(startIndex, endIndex);

      rows.push(
        <Styled.ContentRowWrapper
          $hasAccent={i === 1 || i === 2}
          $rowNumber={i}
          key={`row-wrapper-${i}`}
        >
          <Styled.ContentRow>
            {rowCards.map((item) => {
              return (
                <Styled.ContentItem key={item.id}>
                  {(!!item.bottomPlus || !!item.topPlus) && (
                    <Styled.Plus $isBottom={item.bottomPlus} />
                  )}
                  {(!!item.title ||
                    !!item.description ||
                    item.isLogo ||
                    item.isShape) && (
                    <ServiceCard
                      isLogo={item.isLogo}
                      isShape={item.isShape}
                      icon={item.icon}
                      front={{
                        title: item.title,
                        description: item.description,
                      }}
                    />
                  )}
                </Styled.ContentItem>
              );
            })}
          </Styled.ContentRow>
        </Styled.ContentRowWrapper>
      );
    }
    return rows;
  };

  return <Styled.Rows>{renderRows()}</Styled.Rows>;
};

export const ServicesDesktop = () => {
  const { services } = useSectionRefs();

  return (
    <Styled.ScrollPadding id={hashIds.services} ref={services}>
      <Styled.Wrapper>
        <Cards />
      </Styled.Wrapper>
    </Styled.ScrollPadding>
  );
};

export default ServicesDesktop;
