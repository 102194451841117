import styled from "styled-components";
import { colors } from "styles/variables";
import { rgba } from "polished";

export const Container = styled.ul`
  z-index: 10;
  display: flex;
  height: 50px;
  pointer-events: all;
`;

export const LinkIcon = styled.a`
  border-radius: 50%;
  background-color: ${rgba(colors.mineShaft, 0.55)};
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  width: 50px;
  transition: background-color 0.2s ease-in-out;

  &:hover {
    background-color: ${colors.hitPink};

    svg {
      path {
        fill: ${colors.black};
      }
    }
  }
`;

export const Item = styled.li`
  position: relative;
  height: 50px;
  width: 50px;
  margin-left: -4px;
  z-index: 1;

  &:hover {
    z-index: 2;
  }
`;
