import * as Styled from "./Logos.styles";

import { KnowdeIcon } from "assets/logos/knowde";
import { CouchbaseIcon } from "assets/logos/couchbase";
import { DBMIcon } from "assets/logos/dbm";

export const Logos = () => {
  return (
    <Styled.Wrapper>
      <KnowdeIcon />
      <DBMIcon />
      <CouchbaseIcon />
    </Styled.Wrapper>
  );
};

export default Logos;
