import { SVGProps } from "react";

export const InstagramIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="23"
      height="23"
      viewBox="0 0 23 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M16.8175 0.71582H5.95349C2.96615 0.71582 0.521484 3.16049 0.521484 6.14783V17.0124C0.521484 19.9989 2.96615 22.4444 5.95349 22.4444H16.8175C19.8048 22.4444 22.2495 19.9989 22.2495 17.0124V6.14783C22.2495 3.16049 19.8048 0.71582 16.8175 0.71582ZM20.4388 17.0124C20.4388 19.0086 18.8149 20.6336 16.8175 20.6336H5.95349C3.95696 20.6336 2.33224 19.0086 2.33224 17.0124V6.14783C2.33224 4.15103 3.95696 2.52658 5.95349 2.52658H16.8175C18.8149 2.52658 20.4388 4.15103 20.4388 6.14783V17.0124Z"
        fill="#FFA775"
      />
      <path
        d="M17.2711 7.05307C18.0211 7.05307 18.6291 6.44508 18.6291 5.69507C18.6291 4.94507 18.0211 4.33707 17.2711 4.33707C16.5211 4.33707 15.9131 4.94507 15.9131 5.69507C15.9131 6.44508 16.5211 7.05307 17.2711 7.05307Z"
        fill="#FFA775"
      />
      <path
        d="M11.3855 6.14783C8.38484 6.14783 5.95349 8.57945 5.95349 11.5798C5.95349 14.5791 8.38484 17.0124 11.3855 17.0124C14.3853 17.0124 16.8175 14.5791 16.8175 11.5798C16.8175 8.57945 14.3853 6.14783 11.3855 6.14783ZM11.3855 15.2016C9.38568 15.2016 7.76425 13.5802 7.76425 11.5798C7.76425 9.57949 9.38568 7.95858 11.3855 7.95858C13.3853 7.95858 15.0067 9.57949 15.0067 11.5798C15.0067 13.5802 13.3853 15.2016 11.3855 15.2016Z"
        fill="#FFA775"
      />
    </svg>
  );
};

export default InstagramIcon;
