import { ReactNode } from "react";
import * as Styled from "./Typography.styles";
import { Variants, Colors, Weight } from "./Typography.types";

export type TypographyProps = {
  children: ReactNode;
  variant?: Variants;
  color?: Colors;
  weight?: Weight;
  letterSpacing?: number;
  lineHeight?: string;
  isUppercase?: boolean;
  isUnderline?: boolean;
  as?: string;
  className?: string;
  fixIndent?: boolean;
  fixIndentValue?: number;
};

export const Typography = ({
  children,
  variant = "md",
  color = "white",
  weight = "400",
  letterSpacing = 0,
  lineHeight,
  isUppercase = false,
  isUnderline = false,
  as = "p",
  className,
  fixIndent,
  fixIndentValue,
}: TypographyProps) => {
  return (
    <Styled.Typography
      $variant={variant}
      $color={color}
      $weight={weight}
      $letterSpacing={letterSpacing}
      $fixIndent={fixIndent}
      $fixIndentValue={fixIndentValue || 0}
      $lineHeight={lineHeight}
      $isUppercase={isUppercase}
      $isUnderline={isUnderline}
      as={as}
      className={className}
    >
      {children}
    </Styled.Typography>
  );
};

export default Typography;
