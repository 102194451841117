import { useRef } from "react";

import Typography from "components/typography/Typography";

import { LinkedInIcon } from "assets/linkedIn";
import { ContactCardIcon } from "assets/contactCardIcon";
import { ContactCardPhoneIcon } from "assets/contactCardPhoneIcon";
import { useInView } from "framer-motion";

import * as Styled from "./PersonalTouch.styles";
import { useScreenContext } from "context/ScreenContext";
import { hashIds } from "constants/hashes";
import { MobileHashTarget } from "components/mobile-hash-target/MobileHashTarget";
import waterDropVideo from "assets/video/water-drop.mp4";

import KW from "assets/images/KW.png";

type PersonalTouchProps = {
  title: string;
  name: string;
  position: string;
  linkedInLink: string;
  email?: string;
  phone?: string;
};

const PersonalTouchMobile = ({
  title,
  name,
  position,
  linkedInLink,
}: PersonalTouchProps) => (
  <Styled.Wrapper>
    <MobileHashTarget top={-15} id={hashIds["personal-touch"]} />
    <Styled.TitleWrapper>
      <Typography
        variant="sm"
        color="hitPink"
        weight="500"
        isUppercase
        letterSpacing={3}
      >
        {title}
      </Typography>
    </Styled.TitleWrapper>
    <Styled.NameWrapper>
      <Typography variant="title" color="white" weight="800">
        {name}
      </Typography>
      <Typography variant="sm" color="white" weight="500" isUppercase>
        {position}
      </Typography>
    </Styled.NameWrapper>
    <Styled.ImageWrapper>
      <Styled.Cutout />
      <Styled.Image src={KW} alt="Technical partner" />
    </Styled.ImageWrapper>
    <Styled.LinkedInWrapper>
      <Styled.LinkedInBorder />
      <a href={linkedInLink} target="_blank" rel="noreferrer">
        <Styled.LinkedInCircle>
          <LinkedInIcon />
        </Styled.LinkedInCircle>
      </a>
    </Styled.LinkedInWrapper>
  </Styled.Wrapper>
);

export const PersonalTouchDesktop = ({
  title,
  name,
  position,
  email,
  phone,
}: PersonalTouchProps) => {
  const ref = useRef(null);
  const isInView = useInView(ref, {
    once: true,
    amount: 0.7,
  });

  return (
    <Styled.DesktopWrapper id={hashIds["personal-touch"]}>
      <Styled.SectionTitle>
        <Typography variant="title" weight="800">
          {title}
        </Typography>
      </Styled.SectionTitle>
      <Styled.Container>
        <Styled.DecorationLeft>
          <Styled.DecorationFillerLeft />
        </Styled.DecorationLeft>
        <Styled.Shape>
          <Styled.Content ref={ref}>
            <Styled.Info>
              <Styled.PositionWrapper
                style={{
                  opacity: isInView ? 1 : 0,
                  transform: `translateY(${isInView ? 0 : "-20px"})`,
                  transition: isInView
                    ? `opacity 0.5s ease 0.5s, transform 0.5s ease 0.5s`
                    : `opacity 0.5s ease, transform 0.5s ease`,
                }}
              >
                <Typography
                  variant="sm"
                  weight="500"
                  color="hitPink"
                  isUppercase
                  letterSpacing={3}
                >
                  {position}
                </Typography>
              </Styled.PositionWrapper>
              <Styled.NameWrapperDesktop
                style={{
                  opacity: isInView ? 1 : 0,
                  transform: `translateY(${isInView ? 0 : "-20px"})`,
                  transition: isInView
                    ? `opacity 0.5s ease 0.8s, transform 0.5s ease 0.8s`
                    : `opacity 0.5s ease, transform 0.5s ease`,
                }}
              >
                <Typography variant="title" color="white" weight="800">
                  {name}
                </Typography>
              </Styled.NameWrapperDesktop>
              <Styled.EmailWrapper
                $isPaddingBottom
                style={{
                  opacity: isInView ? 1 : 0,
                  transform: `translateY(${isInView ? 0 : "-20px"})`,
                  transition: isInView
                    ? `opacity 0.5s ease 1.1s, transform 0.5s ease 1.1s`
                    : `opacity 0.5s ease, transform 0.5s ease`,
                }}
              >
                <ContactCardIcon />
                <Styled.Email href={`mailto:${email}`}>
                  <Typography variant="sm" weight="500" color="white">
                    {email}
                  </Typography>
                </Styled.Email>
              </Styled.EmailWrapper>
              <Styled.EmailWrapper
                style={{
                  opacity: isInView ? 1 : 0,
                  transform: `translateY(${isInView ? 0 : "-20px"})`,
                  transition: isInView
                    ? `opacity 0.5s ease 1.4s, transform 0.5s ease 1.4s`
                    : `opacity 0.5s ease, transform 0.5s ease`,
                }}
              >
                <ContactCardPhoneIcon />
                <Styled.Email href={`tel:${phone}`}>
                  <Typography variant="sm" weight="500" color="white">
                    {phone}
                  </Typography>
                </Styled.Email>
              </Styled.EmailWrapper>
            </Styled.Info>
            <Styled.ImageWrapperDesktop
              style={{
                transform: `translateX(${isInView ? 0 : "-200px"})`,
                transition: "transform 1.2s cubic-bezier(0.17, 0.55, 0.55, 1)",
              }}
            >
              <Styled.Image src={KW} alt="Technical partner" />
            </Styled.ImageWrapperDesktop>
          </Styled.Content>
        </Styled.Shape>
        <Styled.DecorationRight>
          <Styled.DecorationFillerRight />
        </Styled.DecorationRight>
        <Styled.Video src={waterDropVideo} mobileSrc={waterDropVideo} />
      </Styled.Container>
    </Styled.DesktopWrapper>
  );
};

export const PersonalTouch = ({
  title,
  name,
  position,
  linkedInLink,
  email,
  phone,
}: PersonalTouchProps) => {
  const { isDesktop } = useScreenContext();

  return isDesktop ? (
    <PersonalTouchDesktop
      title={title}
      name={name}
      position={position}
      linkedInLink={linkedInLink}
      email={email}
      phone={phone}
    />
  ) : (
    <PersonalTouchMobile
      title={title}
      name={name}
      position={position}
      linkedInLink={linkedInLink}
    />
  );
};

export default PersonalTouch;
