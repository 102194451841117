import { motion } from "framer-motion";
import styled from "styled-components";
import { media } from "styles/variables";

export const Container = styled(motion.div)<{
  $isSticky: boolean;
  $isMobileButtonToggled?: boolean;
}>`
  position: fixed;
  bottom: 50px;
  text-align: center;
  width: 100%;
  left: 0;
  right: 0;
  z-index: 50;

  ${media.lessThan("md")`
    bottom: auto;
    top: 33px;
  `}

  ${({ $isSticky, $isMobileButtonToggled }) =>
    `
      ${!$isSticky && "position: absolute"};
      ${$isSticky && $isMobileButtonToggled && "pointer-events: none"};
    `}
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0 25px;

  ${media.greaterThan("md")`
    padding: 0 50px;
  `}
`;

export const LinksList = styled.ul`
  display: flex;
  gap: 55px;
  width: 100%;
  align-items: center;
  justify-content: center;
  padding-left: 100px;

  ${media.lessThan("md")`
    display: none;
  `}
`;

export const LinksListItem = styled.li`
  text-transform: uppercase;
`;

export const LinkAnchor = styled.a`
  text-decoration: none;
  cursor: pointer;
`;

export const MobileButtonWrapper = styled.div`
  ${media.greaterThan("md")`
    display: none;
  `}
`;

export const DesktopButtonWrapper = styled.div`
  ${media.lessThan("md")`
    display: none;
  `}
`;
