import Typography from "components/typography/Typography";
import ProjectArrow from "assets/projectArrow";

import * as Styled from "./project.styles";
import { forwardRef, RefObject, useImperativeHandle, useRef } from "react";
import { useInView } from "framer-motion";

type IndexPlusProjectProps = {
  title: string;
  tags: string[];
  href: string;
  id: string;
  ref: RefObject<{ isVisible: () => boolean }>;
};

export const IndexPlusProject = forwardRef<
  IndexPlusProjectProps["ref"]["current"],
  IndexPlusProjectProps
>(({ title, tags, href, id }, ref) => {
  const description = tags.join(", ");

  const titleRef = useRef<HTMLDivElement>(null);

  const isInView = useInView(titleRef);

  useImperativeHandle(
    ref,
    () => ({
      isVisible: () => {
        if (titleRef.current) {
          return isInView;
        }
        return false;
      },
    }),
    [isInView]
  );

  return (
    <Styled.StyledLink href={href} target="_blank" rel="noreferrer" id={id}>
      <Styled.Wrapper>
        <Styled.TitleWrapper>
          <Typography variant="caption" weight="500">
            {title}
          </Typography>
        </Styled.TitleWrapper>
        <Styled.Description ref={titleRef}>
          <Typography variant="xSm" weight="300">
            {description}
          </Typography>
        </Styled.Description>
        <Styled.ArrowWrapper>
          <ProjectArrow />
        </Styled.ArrowWrapper>
      </Styled.Wrapper>
    </Styled.StyledLink>
  );
});

export default IndexPlusProject;
