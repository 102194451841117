import { createContext, createRef, useContext } from "react";

export type SectionRefs = {
  footer: React.RefObject<HTMLDivElement>;
  representation: React.RefObject<HTMLDivElement>;
  aboutUs: React.RefObject<HTMLDivElement>;
  innovativeSolutions: React.RefObject<HTMLDivElement>;
  caseStudies: React.RefObject<HTMLDivElement>;
  highlightedProjects: React.RefObject<HTMLDivElement>;
  services: React.RefObject<HTMLDivElement>;
  transformingVisions: React.RefObject<HTMLDivElement>;
};

const SectionRefContext = createContext<SectionRefs>({} as SectionRefs);

export const SectionRefContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const refs = {
    footer: createRef<HTMLDivElement>(),
    representation: createRef<HTMLDivElement>(),
    aboutUs: createRef<HTMLDivElement>(),
    innovativeSolutions: createRef<HTMLDivElement>(),
    caseStudies: createRef<HTMLDivElement>(),
    highlightedProjects: createRef<HTMLDivElement>(),
    services: createRef<HTMLDivElement>(),
    transformingVisions: createRef<HTMLDivElement>(),
  };

  return (
    <SectionRefContext.Provider value={refs}>
      {children}
    </SectionRefContext.Provider>
  );
};

export const useSectionRefs = () => {
  return useContext(SectionRefContext);
};
