import Layout from "components/layout/Layout";
import * as Styled from "./HighlightedProjects.styles";
import Circle from "./components/Circle";
import { useCallback, useEffect, useRef, useState } from "react";
import {
  animate,
  AnimatePresence,
  motion,
  useInView,
  useMotionValue,
} from "framer-motion";
import { useScreenContext } from "context/ScreenContext";
import { highlightedProjects } from "./HighlightedProjects.mock";
import SwitchImageButtons from "./components/SwitchImageButons";
import { PauseIcon } from "assets/pauseIcon";
import { PlayIcon } from "assets/playIcon";
import { ButtonVertical } from "components/button-vertical/ButtonVertical";
import LinkButtonArrow from "assets/linkButtonArrow";
import { colors } from "styles/variables";
import Like from "./components/Like";
import MainHeading from "./components/MainHeading";
import ProjectDescription from "./components/ProjectDescription";
import HighlightedProjectsMobile from "./components/HighlightedProjectsMobile";
import { useSectionRefs } from "context/SectionRefContext";

const likesMotionVariants = {
  initial: {
    opacity: 0,
  },
  animate: (custom: number) => ({
    opacity: 1,
    transition: {
      duration: 0.5,
      delay: 0.2 + custom * 0.1,
    },
  }),
};

const HighlightedProjects = () => {
  const { isDesktop } = useScreenContext();
  const { highlightedProjects: highlightedProjectsRef } = useSectionRefs();

  const [slide, setSlide] = useState(1);
  const hasAlreadySwitchedSlide = useRef(false);
  const previousSlide = () => {
    animateCircleOnSlideChange(-1);
    setSlide((prev) => (prev === 1 ? highlightedProjects.length : prev - 1));
  };
  const nextSlide = () => {
    animateCircleOnSlideChange(1);
    setSlide((prev) => (prev === highlightedProjects.length ? 1 : prev + 1));
  };
  const currentProject = highlightedProjects[slide - 1];

  const circleRef = useRef<HTMLDivElement>(null);

  const [isAnimatingCircleSlideChange, setIsAnimatingCircleSlideChange] =
    useState(false);

  const isCircleInView = useInView(circleRef, {
    margin: "-20px",
  });

  const rotationHighlight = useMotionValue(0);
  const rotationBackground = useMotionValue(0);

  const animateCircleOnSlideChange = async (direction: -1 | 1) => {
    setIsAnimatingCircleSlideChange(true);
    animate(rotationBackground, rotationBackground.get() + 65 * direction, {
      duration: 1,
      ease: "easeIn",
    });
    animate(rotationHighlight, rotationHighlight.get() + 65 * direction, {
      type: "spring",
      delay: 0.1,
      bounce: 0.5,
      duration: 1,
      ease: "easeIn",
    }).then(() => {
      setIsAnimatingCircleSlideChange(false);
    });
  };

  const spinFullCircle = useCallback(() => {
    rotationBackground.set(rotationBackground.get() + 180);
    rotationHighlight.set(rotationHighlight.get() + 180);

    animate(rotationBackground, rotationBackground.get(), {
      duration: 0.8,
    });

    animate(rotationHighlight, rotationHighlight.get(), {
      type: "spring",
      delay: 0.1,
      bounce: 0,
      duration: 0.8,
    });
  }, [rotationHighlight, rotationBackground]);

  // Give the circle a spin when it comes into view on desktop.
  useEffect(() => {
    if (isDesktop && isCircleInView) {
      requestAnimationFrame(() => {
        spinFullCircle();
      });
    }
  }, [isDesktop, isCircleInView, spinFullCircle]);

  const [isTimerToggled, setIsTimerToggled] = useState(false);
  const handleClickToggleTimer = () => {
    if (isDesktop) {
      return;
    }
    setIsTimerToggled((prev) => !prev);
  };

  return (
    <Layout ref={highlightedProjectsRef}>
      {!isDesktop && (
        <HighlightedProjectsMobile
          isTimerToggled={isTimerToggled}
          setIsTimerToggled={setIsTimerToggled}
          isCircleInView={isCircleInView}
          isAnimatingCircleSlideChange={isAnimatingCircleSlideChange}
          rotationBackground={rotationBackground}
          rotationHighlight={rotationHighlight}
          nextSlide={nextSlide}
        />
      )}
      <Styled.Wrapper>
        <MainHeading />
        <AnimatePresence>
          <Styled.ImageWrapper onClick={handleClickToggleTimer}>
            <Circle
              ref={circleRef}
              rotationHighlight={rotationHighlight}
              rotationBackground={rotationBackground}
            />
            <Styled.Image
              src={currentProject.imageSrc}
              alt={currentProject.title}
              initial={{ opacity: 0, y: 40 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 1 }}
              key={slide}
            />
            <ProjectDescription
              hasAlreadySwitchedSlide={hasAlreadySwitchedSlide.current}
              project={currentProject}
            >
              {!isDesktop && (
                <motion.button
                  className="ProjectDescription-playPause"
                  key={`playPause_${slide}`}
                  aria-label="play or pause slide"
                >
                  {isTimerToggled ? <PauseIcon /> : <PlayIcon />}
                </motion.button>
              )}
              <motion.div
                initial={{ y: 50, opacity: 0 }}
                whileInView={{
                  y: 0,
                  opacity: 1,
                  transition: { delay: 0.15 },
                }}
              >
                <a href={currentProject.href} target="_blank" rel="noreferrer" >
                  <ButtonVertical
                    ariaLabel="go to project"
                    text="View full case study"
                    icon={<LinkButtonArrow color={colors.hitPink} />}
                    className="ProjectDescription-cta"
                  />
                </a>
              </motion.div>
            </ProjectDescription>
            {isDesktop && (
              <SwitchImageButtons
                handleNextClick={nextSlide}
                handlePrevClick={previousSlide}
              >
                <div className="HighlightedProjects-likes">
                  {currentProject.likes.map((like, i) => (
                    <Like
                      key={like.platform}
                      {...like}
                      custom={i}
                      variants={likesMotionVariants}
                      initial="initial"
                      whileInView="animate"
                    />
                  ))}
                </div>
                <motion.h2
                  className="HighlightedProjects-smallHeading"
                  initial={{
                    opacity: 0,
                  }}
                  whileInView={{ opacity: 1, transition: { delay: 0.2 } }}
                >
                  HIGHLIGHTED PROJECTS
                </motion.h2>
              </SwitchImageButtons>
            )}
          </Styled.ImageWrapper>
        </AnimatePresence>
      </Styled.Wrapper>
    </Layout>
  );
};
export default HighlightedProjects;
