import * as Styled from "./SocialBar.styles";

const SocialBar = () => {
  return (
    <Styled.Container>
      <Styled.Item>
        <Styled.LinkIcon
          href="https://www.facebook.com/kruko.io"
          target="_blank"
          aria-label="facebook"
        >
          <svg
            width="11"
            height="19"
            viewBox="0 0 11 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M6.96844 18.8638L6.95249 10.3197L9.81927 10.3143L10.2432 6.98271L6.94628 6.98886L6.94231 4.86263C6.94051 3.89859 7.20589 3.24111 8.5899 3.23852L10.3522 3.23451L10.3466 0.255322C10.0418 0.216286 8.99548 0.127436 7.77786 0.129708C5.2353 0.134453 3.4975 1.68966 3.50282 4.53917L3.5074 6.99528L0.631937 7.00065L0.638152 10.3315L3.51362 10.3261L3.52956 18.8703L6.96844 18.8638Z"
              fill="white"
            />
          </svg>
        </Styled.LinkIcon>
      </Styled.Item>

      <Styled.Item>
        <Styled.LinkIcon
          href="https://www.instagram.com/kruko.labs/"
          target="_blank"
          aria-label="instagram"
        >
          <svg
            width="17"
            height="17"
            viewBox="0 0 17 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12.4123 0.675293H4.58764C2.43604 0.675293 0.675293 2.43604 0.675293 4.58764V12.4127C0.675293 14.5637 2.43604 16.325 4.58764 16.325H12.4123C14.5639 16.325 16.3247 14.5637 16.3247 12.4127V4.58764C16.3247 2.43604 14.5639 0.675293 12.4123 0.675293ZM15.0205 12.4127C15.0205 13.8505 13.8509 15.0209 12.4123 15.0209H4.58764C3.14966 15.0209 1.97947 13.8505 1.97947 12.4127V4.58764C1.97947 3.14947 3.14966 1.97947 4.58764 1.97947H12.4123C13.8509 1.97947 15.0205 3.14947 15.0205 4.58764V12.4127Z"
              fill="white"
            />
            <path
              d="M12.739 5.23963C13.2792 5.23963 13.7171 4.80173 13.7171 4.26154C13.7171 3.72136 13.2792 3.28346 12.739 3.28346C12.1988 3.28346 11.7609 3.72136 11.7609 4.26154C11.7609 4.80173 12.1988 5.23963 12.739 5.23963Z"
              fill="white"
            />
            <path
              d="M8.49998 4.58764C6.33879 4.58764 4.58764 6.33898 4.58764 8.49998C4.58764 10.6602 6.33879 12.4127 8.49998 12.4127C10.6606 12.4127 12.4123 10.6602 12.4123 8.49998C12.4123 6.33898 10.6606 4.58764 8.49998 4.58764ZM8.49998 11.1085C7.05963 11.1085 5.89181 9.94071 5.89181 8.49998C5.89181 7.05925 7.05963 5.89181 8.49998 5.89181C9.94033 5.89181 11.1081 7.05925 11.1081 8.49998C11.1081 9.94071 9.94033 11.1085 8.49998 11.1085Z"
              fill="white"
            />
          </svg>
        </Styled.LinkIcon>
      </Styled.Item>

      <Styled.Item>
        <Styled.LinkIcon
          href="https://dribbble.com/krukoLabs"
          target="_blank"
          aria-label="dribbble"
        >
          <svg
            width="19"
            height="19"
            viewBox="0 0 19 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M11.4771 9.05064C11.3043 8.65095 11.1241 8.25192 10.9319 7.85722C12.573 7.14643 13.8919 6.18117 14.8745 4.9571C15.8331 6.08991 16.435 7.52847 16.5236 9.10294C14.694 8.8035 13.0113 8.78685 11.4771 9.05064ZM13.4097 15.3548C13.0986 13.6834 12.6419 12.049 12.0457 10.4546C13.3783 10.2661 14.8456 10.315 16.45 10.5941C16.1396 12.5736 15.0098 14.2837 13.4097 15.3548ZM5.17727 15.0497C6.5762 12.8551 8.3625 11.4265 10.5758 10.767C11.2343 12.487 11.7193 14.2557 12.0277 16.0676C9.72213 16.9586 7.13144 16.5759 5.17727 15.0497ZM2.46567 9.31844C5.19992 9.31444 7.55878 9.00035 9.53527 8.37849C9.70082 8.7159 9.85936 9.05497 10.0109 9.39638C7.61041 10.1465 5.63126 11.685 4.08777 14.0009C2.97662 12.6672 2.42337 11.0498 2.46567 9.31844ZM6.32206 3.22209C7.26834 4.46015 8.10603 5.73651 8.83514 7.04618C7.10813 7.55445 5.04737 7.81559 2.66419 7.83191C3.15648 5.81745 4.51278 4.14206 6.32206 3.22209ZM13.794 3.92889C12.938 5.03271 11.7562 5.90571 10.2504 6.54323C9.52895 5.22123 8.70424 3.93155 7.77262 2.67984C9.88168 2.14192 12.0633 2.59224 13.794 3.92889ZM9.49964 0.973145C4.79056 0.973145 0.973145 4.7909 0.973145 9.49997C0.973145 14.209 4.79056 18.0268 9.49964 18.0268C14.2094 18.0268 18.0268 14.209 18.0268 9.49997C18.0268 4.7909 14.209 0.973145 9.49964 0.973145Z"
              fill="white"
            />
          </svg>
        </Styled.LinkIcon>
      </Styled.Item>

      <Styled.Item>
        <Styled.LinkIcon
          href="https://www.behance.net/krukospzoo"
          target="_blank"
          aria-label="behance"
        >
          <svg
            width="17"
            height="11"
            viewBox="0 0 17 11"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M7.06018 5.01225C7.39739 4.84263 7.65232 4.65371 7.82702 4.44956C8.13782 4.08189 8.29119 3.59436 8.29119 2.99003C8.29119 2.40196 8.13782 1.8992 7.83108 1.47769C7.31918 0.791093 6.45281 0.439669 5.2279 0.421387H0.375V10.3273H4.89984C5.40971 10.3273 5.882 10.2836 6.31874 10.1942C6.75548 10.1038 7.13331 9.93828 7.45325 9.69655C7.73764 9.4863 7.97531 9.22527 8.16423 8.91752C8.46284 8.45234 8.61214 7.92521 8.61214 7.33814C8.61214 6.76937 8.48112 6.28489 8.2211 5.88674C7.95804 5.4886 7.57209 5.1971 7.06018 5.01225ZM2.3769 2.14194H4.56264C5.04305 2.14194 5.43916 2.19374 5.74996 2.29633C6.10951 2.44563 6.28929 2.74932 6.28929 3.21247C6.28929 3.62788 6.15217 3.91836 5.88098 4.08189C5.60777 4.24541 5.2533 4.32768 4.81859 4.32768H2.3769V2.14194ZM5.83325 8.43406C5.59152 8.55086 5.25126 8.60876 4.81554 8.60876H2.3769V5.96698H4.84906C5.2797 5.97003 5.61488 6.02691 5.85458 6.13355C6.28116 6.32653 6.49344 6.67999 6.49344 7.19697C6.49344 7.80637 6.27405 8.21671 5.83325 8.43406Z"
              fill="white"
            />
            <path
              d="M15.0627 0.88352H10.7593V2.11655H15.0627V0.88352Z"
              fill="white"
            />
            <path
              d="M16.5578 5.74963C16.4684 5.17577 16.2714 4.67098 15.9646 4.23525C15.6284 3.74163 15.2019 3.38005 14.6828 3.15153C14.1659 2.92198 13.5839 2.80721 12.9359 2.80823C11.8481 2.80823 10.9644 3.14848 10.2809 3.8239C9.59938 4.50136 9.25811 5.47539 9.25811 6.74499C9.25811 8.09889 9.63493 9.07698 10.3926 9.67725C11.1473 10.2785 12.0187 10.5782 13.008 10.5782C14.2055 10.5782 15.1368 10.2217 15.8021 9.51068C16.2277 9.06175 16.4684 8.61993 16.5212 8.18623H14.5386C14.4238 8.40054 14.2908 8.56813 14.1384 8.69001C13.8622 8.91346 13.5026 9.02518 13.0618 9.02518C12.6423 9.02518 12.2869 8.93276 11.9903 8.74892C11.5007 8.45437 11.2448 7.93841 11.2123 7.20408H16.6228C16.6309 6.57131 16.6106 6.0848 16.5578 5.74963ZM11.261 5.93956C11.3321 5.46321 11.5048 5.08537 11.779 4.80606C12.0533 4.52777 12.4412 4.3876 12.9379 4.38659C13.396 4.38659 13.7789 4.51761 14.0907 4.78067C14.3984 5.04576 14.5721 5.4307 14.6077 5.93956H11.261Z"
              fill="white"
            />
          </svg>
        </Styled.LinkIcon>
      </Styled.Item>

      <Styled.Item>
        <Styled.LinkIcon
          href="https://x.com/krukoio"
          target="_blank"
          aria-label="twitter"
        >
          <svg
            width="15"
            height="15"
            viewBox="0 0 15 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M8.8534 6.38705L14.1489 0.231445H12.894L8.29595 5.57627L4.62347 0.231445H0.387695L5.94121 8.31377L0.387695 14.7689H1.64263L6.49834 9.12455L10.3767 14.7689H14.6125L8.85309 6.38705H8.8534ZM7.13459 8.38497L2.09481 1.17614H4.02232L12.8946 13.8671H10.9671L7.13459 8.38528V8.38497Z"
              fill="white"
            />
          </svg>
        </Styled.LinkIcon>
      </Styled.Item>
    </Styled.Container>
  );
};

export default SocialBar;
