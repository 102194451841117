import { useCallback, useRef, useState } from "react";

import SocialBar from "components/social-bar/SocialBar";
import Header from "components/header/Header";
import Hero from "sections/hero/Hero";
import IndexPlus from "sections/index-plus/IndexPlus";

import * as Styled from "./home.page.styles";
import IndexPlusButton from "sections/index-plus/button/IndexPlusButton";
import TransformingVisions from "sections/transforming-visions/TransformingVisions";
import CaseStudies from "sections/case-studies/CaseStudies";
import Contact from "sections/contact/Contact";
import InnovativeSolutions from "sections/innovative-solutions/InnovativeSolutions";
import Services from "sections/services/Services";
import PersonalTouch from "sections/personal-touch/PersonalTouch";
import HighlightedProjects from "sections/highlighted-projects/HighlightedProjects";
import ImagineMore from "sections/imagine-more/ImagineMore";
import Discover from "sections/discover/Discover";
import Footer from "sections/footer/Footer";
import AboutUs from "sections/about-us/AboutUs";
import Representation from "sections/representation/Representation";
import MobileMenu from "components/mobile-menu/MobileMenu";
import { useScreenContext } from "context/ScreenContext";
import ArtisticInnovationsMobile from "sections/artistic-innovations/ArtisticInnovationsMobile";
import ArtisticInnovationsDesktop from "sections/artistic-innovations/ArtisticInnovationsDesktop";
import { useTeleportToHashOnLoad } from "hooks/useHashTeleportOnLoad";
import prismVideo from "assets/video/prism.mp4";
import purpleDiamondVideo from "assets/video/purple-diamond.mp4";

const HomePage = () => {
  const { isDesktop } = useScreenContext();

  const [isIndexPlusOpen, setIndexPlusOpen] = useState(false);
  const [isMobileMenuToggled, setIsMobileMenuToggled] = useState(false);

  const handleIndexPlusToggle = () => {
    const newIsIndexPlusOpen = !isIndexPlusOpen;
    setIndexPlusOpen(newIsIndexPlusOpen);
    if (!isDesktop) {
      document.body.style.overflow = newIsIndexPlusOpen ? "hidden" : "auto";
    }
  };

  const handleIndexPlusToggleOnMobile = () => {
    const handleScroll = () => {
      if (mobileMenuRef.current!.scrollTop === 0) {
        const newIsIndexPlusOpen = !isIndexPlusOpen;
        document.body.style.overflow = newIsIndexPlusOpen ? "hidden" : "auto";
        setIndexPlusOpen(newIsIndexPlusOpen);
        mobileMenuRef.current!.removeEventListener("scroll", handleScroll);
      }
    };
    if (mobileMenuRef.current!.scrollTop === 0) {
      const newIsIndexPlusOpen = !isIndexPlusOpen;
      document.body.style.overflow = newIsIndexPlusOpen ? "hidden" : "auto";
      setIndexPlusOpen(newIsIndexPlusOpen);
      mobileMenuRef.current!.removeEventListener("scroll", handleScroll);
    } else {
      mobileMenuRef.current!.addEventListener("scroll", handleScroll);
      mobileMenuRef.current!.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  };

  /**
   * Sticks to the top in deskstop/mobile screen
   */
  const headerStickyRef = useRef<HTMLDivElement>(null);

  /**
   * Stays at the top in mobile menu
   */
  const headerAbsoluteRef = useRef<HTMLDivElement>(null);

  const mobileMenuRef = useRef<HTMLDivElement>(null);

  const openMobileMenu = () => {
    if (!headerAbsoluteRef.current || !headerStickyRef.current) {
      return;
    }

    setIsMobileMenuToggled(true);

    headerAbsoluteRef.current.style.opacity = "0";
    headerStickyRef.current.style.opacity = "1";

    document.body.style.overflow = "hidden";
  };

  const closeMobileMenu = useCallback(() => {
    if (
      !headerAbsoluteRef.current ||
      !headerStickyRef.current ||
      !mobileMenuRef.current
    ) {
      return;
    }

    setIsMobileMenuToggled(false);

    headerAbsoluteRef.current.style.opacity = "0";
    headerStickyRef.current.style.opacity = "1";
    mobileMenuRef.current.scrollTop = 0;

    document.body.style.overflow = "auto";
  }, []);

  useTeleportToHashOnLoad();

  return (
    <>
      <Header
        isMobileButtonToggled={isMobileMenuToggled}
        closeIndexPlus={() => {
          if (isIndexPlusOpen) {
            handleIndexPlusToggle();
          }
        }}
        mobileButtonOnClick={
          isIndexPlusOpen ? handleIndexPlusToggle : openMobileMenu
        }
        ref={headerStickyRef}
      />

      <Styled.Wrapper>
        <SocialBar />
        <Hero />
        <TransformingVisions
          src={prismVideo}
          mobileSrc={prismVideo}
          title="Next-Generation Design"
          description="Our goal is not only to provide an exceptional product, but also to ensure complete customer satisfaction. We strive to exceed expectations and create long-term relationships based on trust and professionalism"
        />
        <Services />
        <HighlightedProjects />
        {!isDesktop && <ArtisticInnovationsMobile />}
        {!isDesktop && (
          <InnovativeSolutions
            src={purpleDiamondVideo}
            mobileSrc={purpleDiamondVideo}
            buttonCTA="KRUKO.IO"
            buttonLink="https://www.kruko.io/"
          />
        )}
        <CaseStudies />
        {isDesktop && (
          <InnovativeSolutions
            src={purpleDiamondVideo}
            mobileSrc={purpleDiamondVideo}
            buttonCTA="KRUKO.IO"
            buttonLink="https://www.kruko.io/"
          />
        )}
        {isDesktop && <ArtisticInnovationsDesktop />}
        <AboutUs />
        <Representation />
        <Contact />
        <PersonalTouch
          title="Prefer personal touch?"
          name="Krzysztof Wyrzykowski"
          position="Technical Partner"
          linkedInLink="https://www.linkedin.com/in/krzysztof-wyrzykowski-12257169/"
          email="krzysztof.wyrzykowski@krukolabs.studio"
          phone="+48 512 396 945"
        />
        <ImagineMore />
        <Footer />
        <Discover />
      </Styled.Wrapper>

      <Styled.IndexPlusButtonWrapper $isOpen={isIndexPlusOpen}>
        <IndexPlusButton
          onClick={handleIndexPlusToggle}
          isOpen={isIndexPlusOpen}
        />
      </Styled.IndexPlusButtonWrapper>

      {isDesktop && (
        <Styled.IndexPlusWrapper $isOpen={isIndexPlusOpen} data-lenis-prevent>
          <IndexPlus />
        </Styled.IndexPlusWrapper>
      )}

      {!isDesktop && (
        <MobileMenu
          ref={mobileMenuRef}
          isIndexPlusOpen={isIndexPlusOpen}
          isToggled={isMobileMenuToggled}
          handleOpenMenu={openMobileMenu}
          handleClickIndexPlus={handleIndexPlusToggleOnMobile}
          handleCloseMenu={closeMobileMenu}
          onOpenComplete={() => {
            if (!headerStickyRef.current || !headerAbsoluteRef.current) {
              return;
            }

            headerStickyRef.current!.style.opacity = "0";
            headerAbsoluteRef.current!.style.opacity = "1";
          }}
        >
          <Header
            isMobileButtonToggled={true}
            mobileButtonOnClick={
              isIndexPlusOpen ? handleIndexPlusToggle : closeMobileMenu
            }
            ref={headerAbsoluteRef}
            isSticky={false}
          />
          <Styled.IndexPlusWrapper $isOpen={isIndexPlusOpen} data-lenis-prevent>
            <IndexPlus />
          </Styled.IndexPlusWrapper>
        </MobileMenu>
      )}
    </>
  );
};

export default HomePage;
