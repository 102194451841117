import { TypographyProps } from "components/typography/Typography";
import UnstyledTypography from "components/typography/Typography";
import styled from "styled-components";
import { VideoTextRow } from "./InnovativeSolutions.styles";

export const Typography = ({
  children,
  color,
}: {
  children: React.ReactNode;
  color: TypographyProps["color"];
}) => {
  return (
    <UnstyledTypography
      as="span"
      weight="800"
      variant="xl"
      lineHeight="59px"
      color={color}
    >
      {children}
    </UnstyledTypography>
  );
};

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export { VideoTextRow };
