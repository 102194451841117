import styled from "styled-components";
import { border, colors, media } from "styles/variables";

import {
  CaptionWrapper,
  TitleWrapper,
  Email,
  EmailWrapper,
} from "components/contact-card/ContactCard.styles";

export const Wrapper = styled.div`
  z-index: 2;
  width: 100%;
  height: 100%;
  position: relative;
  border-top: ${border};
  border-bottom: ${border};
  margin-bottom: 90px;
  background: black;
  pointer-events: all;

  mix-blend-mode: exclusion;

  ${media.greaterThan("xmd")`
    display: flex;
    align: center;
    justify-content: center;
  `}
`;

export const MobileWrapper = styled.div`
  ${media.greaterThan("xmd")`
    display: none;
  `}

  ${media.greaterThan("sm")`
    & .swiper-slide:not(.swiper-slide-next) {
      border-inline: ${border};
    }
    & .swiper-slide-active {
      border-left: 0 !important; // needed due to the :not specificity above 
    }
  `}
`;

export const Content = styled.div`
  max-width: 1920px;
  margin: 0 50px;
  display: flex;
  width: 100%;
`;

export const ItemWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: 53px 10px;
  transition: background 0.3s ease;

  ${media.greaterThan("xmd")`
    border-right: ${border};
    justify-content: flex-start;
    padding-block: 88px 53px;
    padding-inline: 20px;

    &:last-of-type {
      border-right: none;
    }

    &:hover {
      background: ${colors.hitPink};

      ${TitleWrapper} > * {
        color: black;
      };

      ${CaptionWrapper} > * {
        color: black;
      };

      ${Email} > * {
        color: black;
      };

      ${EmailWrapper} > svg > path {
        fill: black;
      }

      ${EmailWrapper} > svg circle {
        fill: black;
      }
    }
  `}
`;

export const PaginationWrapper = styled.div`
  height: 55px;
  border-top: ${border};
`;

export const DesktopContainer = styled.div`
  display: none;

  ${media.greaterThan("xmd")`
    display: grid;
    border-left: ${border};
    border-right: ${border};
    width: 100%;
    max-width: 1920px;
    margin: 0 50px;
    grid-template-columns: repeat(4, 1fr);
  `}
`;

export const DesktopContent = styled.div``;
