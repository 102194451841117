import styled from "styled-components";
import { media } from "styles/variables";

export const LayoutWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  font-optical-sizing: auto;
  font-style: normal;
  font-weight: 400;
`;

export const LayoutContent = styled.div`
  position: relative;
  width: 100%;
  max-width: 1920px;
  padding: 50px;

  ${media.lessThan("md")`
    padding: 25px;
    padding-bottom: 50px;
  `}
`;
