import Info from "components/info/Info";
import * as Styled from "./Discover.styles";
import Button from "components/button/Button";
import Typography from "components/typography/Typography";
import { useScreenContext } from "context/ScreenContext";
import discoverVideo from "assets/video/discover-video.mp4";

export const Discover = () => {
  const { isDesktop } = useScreenContext();

  return (
    <Styled.Wrapper>
      <Styled.Container>
        <Styled.InfoWrapper>
          <Info
            title="Design on the Cutting Edge of Innovation"
            description="We constantly strive to discover new opportunities and set trends in the world of design and technology."
            centerText={!isDesktop}
            maxWidth={isDesktop ? "475px" : "350px"}
            maxTitleWidth={isDesktop ? "100%" : "300px"}
          />
          <Styled.ButtonWrapper>
            <Button
              aria-label="instagram"
              variant="cta"
              cta="Discover"
              href="https://www.instagram.com/kruko.labs/"
            />
          </Styled.ButtonWrapper>
        </Styled.InfoWrapper>
        <Styled.VideoSection>
          <Styled.Video
            playsInline
            autoPlay
            muted
            loop
            src={discoverVideo}
            mobileSrc={discoverVideo}
          />
        </Styled.VideoSection>
        <Styled.Legals>
          <Typography variant={isDesktop ? "caption" : "xxSm"} color="gray">
            Copyright © 2024 Kruko | All Rights Reserved
          </Typography>
          <Styled.TermsAndPrivacy>
            {/* <Styled.LegalsLink
              href="https://www.google.com/"
              target="_blank"
              rel="noreferrer"
            >
              <Typography variant="caption" color="gray">
                Terms and Conditions
              </Typography>
            </Styled.LegalsLink> */}
            <Styled.LegalsLink
              href="https://res.cloudinary.com/df1qzxwuj/image/upload/v1718642943/kruko_lp_production/Privacy_Policy_ENG_04609b3fce.pdf"
              target="_blank"
              rel="noreferrer"
            >
              <Typography variant="caption" color="gray">
                Privacy Policy
              </Typography>
            </Styled.LegalsLink>
          </Styled.TermsAndPrivacy>
        </Styled.Legals>
      </Styled.Container>
    </Styled.Wrapper>
  );
};

export default Discover;
