import { MotionValue, useAnimationFrame } from "framer-motion";
import { useInterval } from "hooks/useInterval";
import { useRunOnce } from "hooks/useRunOnce";

type HighlightedProjectsMobileProps = {
  isTimerToggled: boolean;
  setIsTimerToggled: (value: boolean) => void;
  isCircleInView: boolean;
  isAnimatingCircleSlideChange: boolean;
  nextSlide: () => void;
  rotationBackground: MotionValue<number>;
  rotationHighlight: MotionValue<number>;
};

const HighlightedProjectsMobile = ({
  isTimerToggled,
  setIsTimerToggled,
  isCircleInView,
  isAnimatingCircleSlideChange,
  nextSlide,
  rotationBackground,
  rotationHighlight,
}: HighlightedProjectsMobileProps) => {
  useAnimationFrame(() => {
    if (isTimerToggled && isCircleInView && !isAnimatingCircleSlideChange) {
      rotationBackground.set(rotationBackground.get() + 0.5);
      rotationHighlight.set(rotationHighlight.get() + 0.5);
    }
  });

  useRunOnce(() => {
    setIsTimerToggled(true);
  });

  const autoSwitchSlide = () => {
    if (isTimerToggled && isCircleInView) {
      nextSlide();
    }
  };

  useInterval(autoSwitchSlide, isTimerToggled ? 4000 : null);

  return null;
};
export default HighlightedProjectsMobile;
