import CaseStudiesMobile from "./CaseStudiesMobile";
import CaseStudiesDesktop from "./CaseStudiesDesktop";

import * as Styled from "./CaseStudies.styles";
import { useSectionRefs } from "context/SectionRefContext";

export const CaseStudies = () => {
  const { caseStudies } = useSectionRefs();

  return (
    <Styled.Wrapper ref={caseStudies}>
      <CaseStudiesMobile />
      <CaseStudiesDesktop />
    </Styled.Wrapper>
  );
};

export default CaseStudies;
