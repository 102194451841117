import styled from "styled-components";
import { colors, media } from "styles/variables";

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 0 10px;

  ${media.greaterThan("xmd")`
    align-items: flex-start;
    justify-content: flex-start;
  `}
`;

export const CaptionWrapper = styled.div`
  text-align: center;

  & > * {
    color: ${colors.hitPink};
  }

  ${media.greaterThan("xmd")`
    padding-bottom: 128px;
  `};
`;

export const TitleWrapper = styled.div`
  margin-top: 15px;
  text-align: center;
  min-height: 90px;

  ${media.greaterThan("xmd")`
    text-align: left;
    margin-top: 0;
    margin-bottom: 12px;
    min-height: 60px;
    display: flex;
    align-items: flex-end;
  `}
`;

export const EmailWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  ${media.greaterThan("xmd")`
    flex-direction: row;
  `}
`;

export const Email = styled.span`
  margin-top: 16px;

  ${media.greaterThan("xmd")`
    margin-top: 0;
    margin-left: 18px;
  `}
`;
