import { SVGProps } from "react";

export const CaseStudyIconLines = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="102"
      height="25"
      viewBox="0 0 102 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M2.03125 9.62348L8.69437 2.95982C9.25699 2.39716 10.0201 2.08105 10.8158 2.08105H55.8815C56.6771 2.08105 57.4402 2.39713 58.0028 2.95973L78.4398 23.3968"
        stroke="white"
        strokeWidth="3"
        strokeLinecap="round"
      />
      <path
        d="M23.5596 15.8541L30.2227 22.5177C30.7853 23.0804 31.5484 23.3965 32.3441 23.3965H77.4098C78.2054 23.3965 78.9685 23.0804 79.5311 22.5178L99.9681 2.08077"
        stroke="white"
        strokeWidth="3"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default CaseStudyIconLines;
