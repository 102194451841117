import { motion } from "framer-motion";
import styled from "styled-components";
import { colors, media } from "styles/variables";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  margin-top: 76px;
  margin-bottom: 140px;

  ${media.greaterThan("sm")`
    margin-bottom: 196px;
  `}

  .HighlightedProjects-smallHeading {
    position: absolute;
    transform: translateX(19%);
    top: -685%;
    right: 0%;
    color: ${colors.hitPink};
    letter-spacing: 3px;
  }

  .HighlightedProjects-likes {
    position: absolute;
    display: flex;
    flex-direction: column;
    gap: 35px;
    top: -685%;
    left: 0%;
    transform: translateX(-19%);
    pointer-events: all;
  }

  ${media.greaterThan("xmd")`
    .HighlightedProjects-smallHeading {
      display: block;
    }
  `}
`;

export const ImageWrapper = styled.div<{ $css?: string }>`
  position: relative;
  width: 135%;

  ${media.greaterThan("xSm")`
    max-width: min(640px, 77%);
  `}

  ${media.greaterThan("sm")`
    max-width: min(640px, 85%);
    margin-top: 112px;
  `}

  ${media.greaterThan("lg")`
    max-width: min(640px, 45%);
  `}
    pointer-events: all;
`;

export const Image = styled(motion.img)<{ $css?: string }>`
  z-index: 1;
  width: 100%;
  aspect-ratio: 1 / 1;
  object-fit: cover;
  user-select: none;
  border-radius: 50%;
  mask-image: linear-gradient(
    to top,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0) 8%,
    rgba(255, 255, 255, 1) 50%,
    rgba(255, 255, 255, 1) 100%
  );
`;
