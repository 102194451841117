import Typography from "components/typography/Typography";
import * as Styled from "./ImagineMore.styles";
import { useScreenContext } from "context/ScreenContext";
import Info from "components/info/Info";
import { Socials } from "./socials/Socials";
import KrukoLogo from "assets/krukoLogo";

export const ImagineMore = () => {
  const {
    isSmallMobile,
    isMobile,
    isTablet,
    isDesktop,
    isDesktoplg,
    isDesktopxl,
  } = useScreenContext();

  return (
    <Styled.Wrapper>
      <Styled.Container>
        <Styled.HeaderWrapper>
          <Styled.TitleWrapper>
            <Typography
              weight="800"
              color="white"
              variant={
                isSmallMobile
                  ? "xxl"
                  : isMobile
                  ? "leadMobile"
                  : isTablet
                  ? "leadSm"
                  : isDesktoplg || isDesktopxl
                  ? "lead"
                  : "leadSmallDesktop"
              }
              lineHeight={
                isSmallMobile
                  ? "50px"
                  : isMobile
                  ? "60px"
                  : isTablet
                  ? "100px"
                  : "160px"
              }
              isUppercase
              letterSpacing={isDesktoplg || isDesktopxl ? -14 : -8}
            >
              Imagine more
            </Typography>
          </Styled.TitleWrapper>
        </Styled.HeaderWrapper>
        <Styled.InfoWrapper>
          <Styled.InfoDesktopWrapper>
            <Info
              title="Innovation in every detail"
              description="We help you push the boundaries of your imagination by creating unique designs tailored to your needs."
              centerText={!isDesktop}
              maxWidth={isDesktop ? "450px" : "400px"}
            />
          </Styled.InfoDesktopWrapper>

          <Styled.LogoWrapper>
            <KrukoLogo />
          </Styled.LogoWrapper>
          <Styled.SocialsWrapper>
            <Socials />
          </Styled.SocialsWrapper>
        </Styled.InfoWrapper>
      </Styled.Container>
    </Styled.Wrapper>
  );
};

export default ImagineMore;
