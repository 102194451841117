import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { Reset } from "styled-reset";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { routes } from "routing/routes";
import ScreenContextProvider from "context/ScreenContext";
import { SectionRefContextProvider } from "context/SectionRefContext";
import { LoadingContextProvider } from "context/LoadingContext";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const router = createBrowserRouter(routes);

root.render(
  <React.StrictMode>
    <Reset />
    <ScreenContextProvider>
      <LoadingContextProvider>
        <SectionRefContextProvider>
          <RouterProvider router={router} />
        </SectionRefContextProvider>
      </LoadingContextProvider>
    </ScreenContextProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
