import styled, { css } from "styled-components";

import { IconCircleProps } from "./IconCircle";
import { colors, media } from "styles/variables";

export const IconWrapper = styled.div<{
  $variant?: IconCircleProps["variant"];
}>`
  background: ${colors.black};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  ${media.lessThan("md")`
    background: ${colors.mineShaft}
  `}

  ${({ $variant }) =>
    $variant === "sm"
      ? css`
          width: 47px;
          height: 47px;
        `
      : css`
          width: 55px;
          height: 55px;
        `}
`;
