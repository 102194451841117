import styled from "styled-components";
import { media } from "styles/variables";

export const ProjectDescription = styled.div`
  position: absolute;
  transform: translateY(calc(-100% + 101px));
  left: 0%;
  right: 0%;

  .ProjectDescription-wrapper {
    bottom: 0;

    width: 65%;
    max-width: 740px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .ProjectDescription-icon {
    width: 45px;
    height: 45px;
    margin-bottom: 30px;
  }

  .ProjectDescription-heading {
    color: white;
    font-weight: 600;
    font-size: 20px;
    margin-bottom: 20px;
    text-wrap: balance;
  }

  .ProjectDescription-description {
    font-size: 16px;
    white-space: nowrap;
    overflow: hidden;
    display: none;
    text-overflow: ellipsis;
    width: 100%;
  }

  .ProjectDescription-playPause {
    cursor: pointer;
    margin-bottom: 30px;
  }

  .ProjectDescription-cta {
    height: 155px;
  }

  ${media.greaterThan("md")`

    transform: translateY(calc(-100% + 130px));

    .ProjectDescription-icon {
        margin-bottom: 40px;
      }
      
    .ProjectDescription-description {
      margin-top: 0px;
      display: block;
      white-space: normal;
    }
    
    .ProjectDescription-heading {
      font-size: 22px;
    }

    .ProjectDescription-cta {
      margin-top: 50px;
      height: auto;
    }
  `}
`;
