import { motion } from "framer-motion";
import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

export const Video = styled(motion.video)`
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
`;
