import Typography from "components/typography/Typography";
import { rgba } from "polished";
import styled from "styled-components";
import { colors } from "styles/variables";

export const HighlightedProject = styled.div`
  padding-block: 32px 42px;
  padding-inline: 16px;
  display: flex;
  flex-direction: column;
  gap: 25px;
  background: ${rgba(colors.black, 0.77)};
  border-radius: 15px;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
`;

export const HeaderHeading = styled(Typography)`
  padding-left: 16px;
`;

export const Image = styled.img`
  border-radius: 15px;
`;

export const DescriptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-inline: 16px;
`;

export const DescriptionSmallText = styled(Typography)`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;
