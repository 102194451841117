import * as THREE from "three";

import { useEffect, useRef, useState } from "react";
import { useFrame, useThree } from "@react-three/fiber";
import { useScreenContext } from "context/ScreenContext";
import { useSectionRefs } from "context/SectionRefContext";
import { calcLightIntensity, useCalcPosition } from "./Background.utils";
import { krukoLogoBackground } from "./krukoLogoBackground";
import gsap from "gsap";
import { colors } from "styles/variables";

const Background = ({
  shapes,
  setShapes,
  meshRef,
  setIsMeshLoaded,
}: {
  shapes?: THREE.Group;
  setShapes: (shapes: THREE.Group) => void;
  meshRef: React.MutableRefObject<THREE.Mesh | null>;
  setIsMeshLoaded: (isMeshLoaded: boolean) => void;
}) => {
  const {
    isDesktop,
    isMobile,
    isMediumMobile,
    isDesktopxl,
    dimensions: [screenWidth],
    initialDimensions: [initialHeight],
  } = useScreenContext();

  const { viewport, camera } = useThree(
    (state) => ({
      viewport: state.viewport,
      camera: state.camera,
    }),
    (prev, next) => {
      return (
        prev.camera === next.camera &&
        prev.viewport.width === next.viewport.width
      );
    }
  );

  useFrame(
    !isDesktop
      ? () => {
          meshRef.current!.position.y = -window.scrollY;
          // Gives us a nice bit of parallax effect
          gsap.to(camera.position, {
            y: -window.scrollY,
            duration: 0.1,
            ease: "sine.in",
          });
        }
      : () => {
          if (meshRef.current) {
            camera.position.y = -window.scrollY;
            meshRef.current.position.y = -window.scrollY;
          }
        }
  );

  useEffect(() => {
    if (meshRef.current && window.scrollY > 0) {
      camera.position.y = -window.scrollY;
      meshRef.current.position.y = -window.scrollY;
      setInitialPosition(new THREE.Vector3(0, -window.scrollY, -150));
    }

    if (!isDesktop) {
      return;
    }

    krukoLogoBackground.then((result) => {
      if (!isDesktop) return;

      const mesh = result.children[0] as THREE.Mesh;

      const scale = isDesktopxl ? 1.5 : isDesktop ? 1.08 : 0.6;

      mesh.scale.set(scale, scale, scale);
      mesh.position.set(0, 0, 1);
      mesh.geometry.center();

      setShapes(result as any);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDesktop, isDesktopxl]);

  const lightGroupRef = useRef<THREE.Group<THREE.Object3DEventMap>>(null);

  const [initialPosition, setInitialPosition] = useState<THREE.Vector3>(
    new THREE.Vector3(0, 0, -150)
  );

  const {
    footer,
    representation,
    aboutUs,
    innovativeSolutions,
    caseStudies,
    highlightedProjects,
    services,
    transformingVisions,
  } = useSectionRefs();

  const { calcX, calcY } = useCalcPosition();

  const calcLightIntensityDesktop = (
    intensityAtMinimum: number,
    customMultiplier?: number
  ) => {
    return calcLightIntensity({
      intensityAtMinimum,
      minWidth: 1100,
      screenWidth,
      customMultiplier,
    });
  };

  const calcLightIntensityMobile = (
    intensityAtMinimum: number,
    customMultiplier?: number
  ) => {
    return calcLightIntensity({
      intensityAtMinimum,
      minWidth: 320,
      screenWidth,
      customMultiplier,
    });
  };

  // These are always defined here, but let this act as a safeguard.
  if (
    !footer.current ||
    !representation.current ||
    !aboutUs.current ||
    !innovativeSolutions.current ||
    !caseStudies.current ||
    !highlightedProjects.current ||
    !services.current ||
    !transformingVisions.current
  ) {
    return null;
  }

  // TODO: Loading screen in the loading screen PR, after optimizing videos.
  if (!shapes && isDesktop) {
    return null;
  }

  return (
    <>
      <mesh
        position={initialPosition}
        ref={(node) => {
          if (node) {
            meshRef.current = node;
            setIsMeshLoaded(true);
          }
        }}
        onPointerMove={
          isDesktop
            ? (e) => {
                lightGroupRef.current!.position.x =
                  (e.x -
                    viewport.width / 2 -
                    lightGroupRef.current!.position.x) *
                  0.08;
                lightGroupRef.current!.position.y =
                  -(
                    e.y -
                    viewport.height / 2 -
                    lightGroupRef.current!.position.y
                  ) * 0.08;
              }
            : undefined
        }
      >
        <planeGeometry
          args={[
            viewport.width,
            // the * 12 times is to account for very fast swiping
            isDesktop ? viewport.height * 12 : initialHeight * 12,
          ]}
        />
        <meshLambertMaterial dithering color={colors.shaderBg.split("#")[1]} />
      </mesh>
      {isDesktop && (
        <mesh
          rotation={[
            THREE.MathUtils.degToRad(0),
            THREE.MathUtils.degToRad(180),
            THREE.MathUtils.degToRad(180),
          ]}
        >
          <primitive object={shapes!} />
        </mesh>
      )}
      <group ref={lightGroupRef}>
        {/* Imagine more - top */}
        <pointLight
          position={[
            calcX(isMediumMobile ? 150 : 15),
            calcY(isDesktop ? -10 : -50),
            isDesktop ? 150 : 100,
          ]}
          intensity={
            isDesktop ? calcLightIntensityDesktop(1050000, 0.9) : 1500000
          }
          color={colors.shaderBg}
          decay={isDesktop ? 2.2 : 2.2}
          distance={
            isDesktop
              ? Math.max(1440, viewport.width) * 0.68
              : Math.max(440, viewport.width) * 1.68
          }
        />
        {/* Transforming visions */}
        <pointLight
          position={[
            calcX(isDesktop ? -60 : -450),
            calcY(transformingVisions.current?.offsetTop),
            isDesktop ? 600 : 450,
          ]}
          intensity={isDesktop ? 225000 : calcLightIntensityMobile(50000)}
          color={colors.shaderBg}
          decay={isDesktop ? 2.1 : 1.8}
        />
        {/* Desktop - Services & Mobile - Highlighted projects */}
        <pointLight
          position={[
            calcX(isDesktop ? 100 : 200),
            calcY(
              isDesktop
                ? services.current?.offsetTop + 100
                : highlightedProjects.current?.offsetTop + 250
            ),
            isDesktop ? 800 : 300,
          ]}
          intensity={isDesktop ? 250000 : calcLightIntensityMobile(80000)}
          color={colors.shaderBg}
          decay={isDesktop ? 2 : 1.95}
        />
        {/* Desktop - Services second light */}
        {isDesktop && (
          <pointLight
            position={[
              calcX(50),
              calcY(services.current?.offsetTop + 700),
              600,
            ]}
            intensity={1500000}
            color={colors.shaderBg}
            decay={2.5}
          />
        )}
        {/* Desktop - Highlighted Projects */}
        {isDesktop && (
          <pointLight
            position={[
              calcX(-40),
              calcY(highlightedProjects.current?.offsetTop + 200),
              500,
            ]}
            intensity={125000}
            color={colors.shaderBg}
            decay={2}
          />
        )}
        {/* Desktop - Case studies */}
        {isDesktop && (
          <pointLight
            position={[
              calcX(-135),
              calcY(caseStudies.current?.offsetTop + (isDesktopxl ? 200 : 250)),
              400,
            ]}
            intensity={calcLightIntensityDesktop(1250000, 0.8)}
            color={colors.shaderBg}
            decay={2.5}
          />
        )}
        {/* Desktop - innovative solutions */}
        {isDesktop && (
          <pointLight
            position={[
              calcX(200),
              calcY(innovativeSolutions.current?.offsetTop + 600),
              1000,
            ]}
            intensity={calcLightIntensityDesktop(400000, 0.8)}
            color={colors.shaderBg}
            decay={2}
          />
        )}
        {/* TODO: Add back in once corrected video is sent by Piotr Olech */}
        {/* Desktop & Mobile - about us */}
        {/* <pointLight
          position={[
            calcX(isDesktop ? -25 : -150),
            calcY(aboutUs.current?.offsetTop),
            300,
          ]}
          intensity={135000}
          color={colors.shaderBg}
          decay={2.1}
        /> */}

        {/* Mobile - Representation, Contact, Personal touch */}
        {!isDesktop && (
          <pointLight
            position={[
              calcX(isMobile ? 550 : 350),
              calcY(representation.current?.offsetTop + 400),
              300,
            ]}
            intensity={calcLightIntensityMobile(isMobile ? 275000 : 125000, 2)}
            color={colors.shaderBg}
          />
        )}
        {/* Desktop - Representation */}
        {isDesktop && (
          <pointLight
            position={[
              calcX(-175),
              calcY(representation.current.offsetTop + 200),
              700,
            ]}
            intensity={calcLightIntensityDesktop(100000, 0.8)}
            color={colors.shaderBg}
            decay={1.92}
          />
        )}
        {/* Desktop - Representation #2 & Personal touch */}
        {isDesktop && (
          <pointLight
            position={[
              calcX(150),
              calcY(representation.current?.offsetTop + 700),
              700,
            ]}
            intensity={calcLightIntensityDesktop(100000, 0.8)}
            color={colors.shaderBg}
            decay={1.92}
          />
        )}
        {/* Desktop & Mobile - Imagine more */}
        <pointLight
          position={[
            calcX(isDesktop ? -20 : -300),
            calcY(footer.current?.offsetTop),
            200,
          ]}
          intensity={isDesktop ? 350000 : calcLightIntensityMobile(250000)}
          color={colors.shaderBg}
          decay={isDesktop ? 2.1 : 2}
        />
      </group>
    </>
  );
};
export default Background;
