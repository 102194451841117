import * as Styled from "./TransformingVisions.styles";
import Info from "components/info/Info";
import { useMotionValueEvent, useScroll, useVelocity } from "framer-motion";
import { useScreenContext } from "context/ScreenContext";
import { RefObject, useRef } from "react";
import { useSectionRefs } from "context/SectionRefContext";

type TransformingVisionsProps = {
  src: string;
  mobileSrc: string;
  title: string;
  description: string;
};

export const TransformingVisions = ({
  src,
  mobileSrc,
  title,
  description,
}: TransformingVisionsProps) => {
  const { isDesktop } = useScreenContext();

  const { transformingVisions } = useSectionRefs();

  const videoRef = useRef<HTMLVideoElement>(null);

  const { scrollYProgress } = useScroll({
    target: transformingVisions as RefObject<HTMLElement>,
    offset: ["start end", "end start"],
  });

  const scrollVelocity = useVelocity(scrollYProgress);

  const handleScroll = () => {
    requestAnimationFrame(() => {
      if (videoRef.current) {
        videoRef.current.playbackRate = Math.min(
          3,
          Math.max(1, scrollVelocity.get() * 10)
        );
      }
    });
  };

  useMotionValueEvent(
    scrollYProgress,
    "change",
    isDesktop && !("safari" in window) ? handleScroll : () => { }
  );

  return (
    <Styled.Wrapper ref={transformingVisions}>
      <Styled.HeadingWrapper>
        <Styled.Heading color="ebonyClay">Transforming</Styled.Heading>
        <Styled.VisionsInto>
          <Styled.Heading color="ebonyClay">Vision</Styled.Heading>
          <Styled.Heading color="white">into</Styled.Heading>
        </Styled.VisionsInto>
        <Styled.Reality>
          <Styled.Heading color="white">Reality</Styled.Heading>
          <Styled.RealityVideo
            src={src}
            mobileSrc={mobileSrc}
            autoPlay
            ref={videoRef}
          />
        </Styled.Reality>
      </Styled.HeadingWrapper>

      <Styled.Description>
        <Info
          title={title}
          description={description}
          maxWidth="550px"
          centerText={!isDesktop}
        />
      </Styled.Description>
    </Styled.Wrapper>
  );
};

export default TransformingVisions;
