import { calcProportionalValue } from "utils/calcProportionalValue";

export const calcHeadingWrapperOutMax = (innerWidth: number) => {
  return calcProportionalValue({
    minWidth: 1101,
    maxWidth: 2560,
    valueAtMinWidth: -20.83,
    valueAtMaxWidth: -19.7,
    currentWidth: innerWidth,
  });
};

export const calcVidXOutMax = (innerWidth: number) => {
  return calcProportionalValue({
    minWidth: 1101,
    maxWidth: 2560,
    valueAtMaxWidth: -0.5,
    valueAtMinWidth: 0.81,
    currentWidth: innerWidth,
  });
};

export const calcHeadingTopXOutMax = (innerWidth: number) => {
  return calcProportionalValue({
    minWidth: 1101,
    maxWidth: 2160,
    valueAtMaxWidth: -26.3,
    valueAtMinWidth: -24.86,
    currentWidth: innerWidth,
  });
};

export const calcDescriptionXOutMax = (innerWidth: number) => {
  return calcProportionalValue({
    minWidth: 1101,
    maxWidth: 2160,
    valueAtMinWidth: -48.5,
    valueAtMaxWidth: -22.2,
    currentWidth: innerWidth,
  });
};

export const calcVidYOutMax = (innerWidth: number) => {
  return calcProportionalValue({
    minWidth: 1101,
    maxWidth: 2560,
    valueAtMaxWidth: -2.01,
    valueAtMinWidth: -1.01,
    currentWidth: innerWidth,
  });
};

export const calcHeadingsXOutMax = (innerWidth: number) => {
  return calcProportionalValue({
    minWidth: 1101,
    maxWidth: 2560,
    valueAtMinWidth: -60,
    valueAtMaxWidth: -47.9,
    currentWidth: innerWidth,
  });
};
