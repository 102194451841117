import { Typography } from "components/typography/Typography";
import { motion } from "framer-motion";
import * as Styled from "./MainHeading.styles";
import highlightedProjectsCrossVideo from "assets/video/highlighted-projects-cross.mp4";
import Video from "components/video/Video";

const MainHeading = () => {
  return (
    <Styled.MainHeading>
      <motion.div
        initial={{ opacity: 0, x: -100 }}
        whileInView={{ opacity: 1, x: 0 }}
        transition={{ duration: 0.5 }}
        className="MainHeading-motion_wrapper"
      >
        <Typography
          weight="700"
          variant="leadSm"
          color="ebonyClay"
          letterSpacing={-5}
          className="MainHeading-highlighted"
        >
          Highlighted
        </Typography>
      </motion.div>
      <div style={{ position: "relative" }}>
        <motion.div
          initial={{ opacity: 0, x: 100 }}
          whileInView={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.55 }}
          className="MainHeading-motion_wrapper"
        >
          <Typography
            letterSpacing={-5}
            weight="700"
            variant="leadSm"
            color="white"
            className="MainHeading-projects"
            as="div"
          >
            projects
          </Typography>
        </motion.div>
        <Video
          hasContainer
          src={highlightedProjectsCrossVideo}
          mobileSrc={highlightedProjectsCrossVideo}
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6 }}
          className="MainHeading-video"
        />
      </div>
    </Styled.MainHeading>
  );
};
export default MainHeading;
