import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  column-gap: 45px;
  row-gap: 22px;
  opacity: 0.55;
  align-items: center;
`;
