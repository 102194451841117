import Typography, { TypographyProps } from "components/typography/Typography";
import * as Styled from "./AboutUs.styles";
import { useScreenContext } from "context/ScreenContext";
import { useHashes } from "hooks/useHashes";
import { MobileHashTarget } from "components/mobile-hash-target/MobileHashTarget";
import aboutUsVideo from "assets/video/about-us.mp4";
import { useSectionRefs } from "context/SectionRefContext";

const baseTitleProps: Partial<TypographyProps> = {
  weight: "800",
  as: "span",
  letterSpacing: -1.6,
};

export const AboutUs = () => {
  const { isMobile, isDesktop, isDesktopxl } = useScreenContext();
  const hashes = useHashes();

  const { aboutUs } = useSectionRefs();

  return (
    <Styled.Wrapper
      id={isDesktop ? hashes.about.hash : undefined}
      ref={aboutUs}
    >
      <Styled.Container>
        <Styled.CaptionWrapper>
          <Typography
            variant="sm"
            color="hitPink"
            weight="500"
            isUppercase
            letterSpacing={3}
          >
            ABOUT US
          </Typography>
          <MobileHashTarget top={-125} id={hashes.about.hash} />
        </Styled.CaptionWrapper>
        <Styled.TitleWrapper>
          <div>
            <Typography
              {...baseTitleProps}
              color="ebonyClay"
              variant={isMobile ? "xMd" : isDesktopxl ? "xxl" : "xl"}
            >
              We break{" "}
            </Typography>
            <Typography
              {...baseTitleProps}
              color="white"
              variant={isMobile ? "xMd" : isDesktopxl ? "xxl" : "xl"}
            >
              through
            </Typography>
          </div>
          <div>
            <Typography
              {...baseTitleProps}
              color="white"
              variant={isMobile ? "xMd" : isDesktopxl ? "xxl" : "xl"}
            >
              the boundaries
            </Typography>
          </div>
          <div>
            <Typography
              {...baseTitleProps}
              color="white"
              variant={isMobile ? "xMd" : isDesktopxl ? "xxl" : "xl"}
            >
              of{" "}
            </Typography>
            <Typography
              {...baseTitleProps}
              color="hitPink"
              variant={isMobile ? "xMd" : isDesktopxl ? "xxl" : "xl"}
            >
              imagination
            </Typography>
          </div>
        </Styled.TitleWrapper>
        <Styled.DescriptionWrapper>
          <Styled.Description>
            <Typography
              variant={isMobile ? "sm" : "captionXl"}
              color="silver"
              weight="300"
              lineHeight={isMobile ? "20px" : "25px"}
            >
              Kruko Labs is a rapidly growing branch of Software Development
              company 'Kruko' that combines creativity with advanced technology
              to deliver innovative design and visualization solutions. As an
              offshoot of a large corporation, Kruko Labs leverages its
              resources and experience while maintaining the flexibility
              and freedom of action characteristic of a startup.
            </Typography>
          </Styled.Description>
          <Styled.Description>
            <Typography
              variant={isMobile ? "sm" : "captionXl"}
              color="silver"
              weight="300"
              lineHeight={isMobile ? "20px" : "25px"}
            >
              Our mission is to transform vision into reality by constantly
              seeking new and inspiring ways to use technology in art and
              design.
            </Typography>
          </Styled.Description>
        </Styled.DescriptionWrapper>
      </Styled.Container>
      <Styled.ImageWrapper>
        <Styled.Video src={aboutUsVideo} mobileSrc={aboutUsVideo} />
      </Styled.ImageWrapper>
    </Styled.Wrapper>
  );
};

export default AboutUs;
