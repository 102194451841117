import { calcProportionalValue } from "utils/calcProportionalValue";

/**
 * Configurable parameter
 *
 * Determines the amount of vh space after the video goes into full screen.
 *
 * If you want no fullscreen padding, set it to 35.
 */
const fullScreenPadding = 60;

/**
 * Written in all caps are non-configurable parameters
 */

const WRAPPER_HEIGHT = 100 as const;
const FULL_SCREEN_HEIGHT = 100 as const;
const BASE_FULL_SCREEN_PADDING = 40 as const;

const FULL_SCREEN_PADDING_DIFF_FROM_BASE =
  fullScreenPadding - BASE_FULL_SCREEN_PADDING;

const FULL_SCREEN_HEIGHT_TOTAL = fullScreenPadding + FULL_SCREEN_HEIGHT;
const TOTAL_HEIGHT = WRAPPER_HEIGHT + FULL_SCREEN_HEIGHT_TOTAL;

const PROGRESS_AT_MAX =
  (WRAPPER_HEIGHT + FULL_SCREEN_HEIGHT - FULL_SCREEN_PADDING_DIFF_FROM_BASE) /
  (TOTAL_HEIGHT - FULL_SCREEN_PADDING_DIFF_FROM_BASE);

/**
 * One scroll at BASE_FULL_SCREEN_PADDING should moves the content by 0.0615 of
 * scroll progress. At fullScreenPadding === 1000, one scroll moves the content
 * by 0.0328 of scroll progress. We have to calculate the step value that
 * corresponds to the current fullScreenPadding value.
 */
const step = calcProportionalValue({
  currentWidth: fullScreenPadding,
  maxWidth: 100,
  minWidth: BASE_FULL_SCREEN_PADDING,
  valueAtMinWidth: 0.0615,
  valueAtMaxWidth: 0.0328,
});

/**
 * At BASE_FULL_SCREEN_PADDING == 40, stepMulitplier == 1. This is arbitrary
 * based on testing. Now, at fullScreenPadding === 1000, each scroll step (1
 * scroll on the mouse) is 40% of a scroll step at BASE_FULL_SCREEN_PADDING.
 * This multiplier allows us to select different fullScreenPadding values and
 * keep the same feel of the scroll.
 */
const stepMultiplier = calcProportionalValue({
  currentWidth: fullScreenPadding,
  minWidth: BASE_FULL_SCREEN_PADDING,
  maxWidth: 1000,
  valueAtMinWidth: 1,
  valueAtMaxWidth: 0.4,
});

const calcStep = (count: number, smoothing = 1.618) =>
  count * step * smoothing * stepMultiplier;

/**
 * Scroll progress at top of the sticky wrapper.
 */
const progressAtStickyWrapper = WRAPPER_HEIGHT / TOTAL_HEIGHT;

// 1. Move everything a bit to the left
const headingWrapperXInMin = progressAtStickyWrapper;
const headingWrapperXInMax = progressAtStickyWrapper + calcStep(3);

// 1.2. Move the top heading to the left, while moving everything (1)
const headingTopXInMin = headingWrapperXInMin + calcStep(1.618);
const headingTopXInMax = headingWrapperXInMax + calcStep(1);

// 1.3  Move the description to the left, together with the top heading (1.2)
const descriptionXInMin = headingTopXInMin;
const descriptionXInMax = headingTopXInMax;

// 1.4 Move the headings off screen after (1.2) and (1.3) gain momentum
const headingsXInMin = headingTopXInMax - calcStep(1);
const headingsXInMax = headingTopXInMax + calcStep(1);

// 1.5 Move the video to the center of the screen
const vidXInMin = headingsXInMin;
const vidXInMax = headingsXInMax;

const vidScaleInMin = vidXInMin;
const vidScaleInMax = vidXInMax + calcStep(0.4);

const vidYInMin = headingTopXInMax;
const vidYInMax = vidScaleInMax;

export {
  WRAPPER_HEIGHT,
  FULL_SCREEN_HEIGHT,
  FULL_SCREEN_HEIGHT_TOTAL,
  TOTAL_HEIGHT,
  PROGRESS_AT_MAX,
  fullScreenPadding,
  progressAtStickyWrapper,
  step,
  headingWrapperXInMin,
  headingWrapperXInMax,
  headingTopXInMin,
  headingTopXInMax,
  descriptionXInMin,
  descriptionXInMax,
  headingsXInMin,
  headingsXInMax,
  vidXInMin,
  vidXInMax,
  vidYInMin,
  vidYInMax,
  vidScaleInMin,
  vidScaleInMax,
};
