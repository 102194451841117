import * as Styled from "./FlippableCard.styles";
import { ReactNode } from "react";

type FlippableCardProps = {
  front: ReactNode;
};

export const FlippableCard = ({ front }: FlippableCardProps) => {
  return (
    <Styled.Wrapper>
      <Styled.CardWrapper>
        <Styled.BorderLine />
        <Styled.CardContent>
          <Styled.CardFaceFront>{front}</Styled.CardFaceFront>
        </Styled.CardContent>
      </Styled.CardWrapper>
    </Styled.Wrapper>
  );
};

export default FlippableCard;
