import { SVGProps } from "react";

export const ArrowButtonIconLong = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="38"
      height="16"
      viewBox="0 0 38 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M1.26946 8.56551C0.878931 8.17498 0.878931 7.54182 1.26946 7.15129L7.63342 0.78733C8.02394 0.396806 8.65711 0.396806 9.04763 0.78733C9.43815 1.17785 9.43815 1.81102 9.04763 2.20154L3.39078 7.8584L9.04763 13.5153C9.43816 13.9058 9.43816 14.5389 9.04763 14.9295C8.65711 15.32 8.02394 15.32 7.63342 14.9295L1.26946 8.56551ZM36.9766 6.8584C37.5288 6.8584 37.9766 7.30611 37.9766 7.8584C37.9766 8.41068 37.5288 8.8584 36.9766 8.8584L36.9766 6.8584ZM1.97656 6.8584L36.9766 6.8584L36.9766 8.8584L1.97656 8.8584L1.97656 6.8584Z"
        fill="white"
      />
    </svg>
  );
};

export default ArrowButtonIconLong;
