import { createGlobalStyle } from "styled-components";

import { colors, border } from "./variables";
import { isMobileBrowser } from "../constants/browser";

const GlobalStyle = createGlobalStyle`
  body {
    font-family: "Montserrat", sans-serif;
    box-sizing: border-box;
  }

  html, body, #root {
    height: 100%;
    width: 100%;
  }

  body {
  
    // needed for mobile, where while scrolling, the BackgroundExperience
    // canvas might not be quick enough to scrool, and we need a solid background.
    background: #0f101a;
    // hide the scroll so that there is no jump when loading screen disappears
    // - see LoadingScreen.tsx for details. Also, site looks better, and we
    // have a button navigation so it's not a big deal.
    
    ${!isMobileBrowser && `overflow: hidden;`}
    
  }
 
  a {
    text-decoration: none;
  }

  button {
    border: none;
    background: none;
  }

  .swiper {
    margin: 0 25px;
    border-left: ${border};
    border-right: ${border};
  }

  .swiper-pagination {
    position: absolute;
    bottom: 0 !important;
    left: 0;
    right: 0;
    width: 100% !important;
    height: 55px;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .swiper-pagination-bullet {
    background: white;
    transform: scale(0.6);
    margin: 0 6px !important;
  }

  .swiper-pagination-bullet-active {
    background: ${colors.hitPink};
    transform: scale(1.2);
  }

  /* Lenis */
  html.lenis, html.lenis body {
    height: auto;
  }

  .lenis.lenis-smooth {
    scroll-behavior: auto !important;
  }

  .lenis.lenis-smooth [data-lenis-prevent] {
    overscroll-behavior: contain;
  }

  .lenis.lenis-stopped {
    overflow: hidden;
  }

  .lenis.lenis-smooth iframe {
    pointer-events: none;
  }

  // https://x.com/wesbos/status/1841186089886777705
  .backlight {
    background-repeat: no-repeat;
    background-size: contain;
    position: relative;
    background-position: center;
  }

  .backlight:after {
    content: "";
    width: 100%;
    height: 100%;
    top: 0;
    position: absolute;
    background: inherit;
    filter: blur(2px) blur(2px);
    z-index: -1;
  }

  .background-experience {
    opacity: 0;
    transition: opacity 1s ease-in-out;
    will-change: opacity;
  }
`;

export default GlobalStyle;
